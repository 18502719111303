import React from "react";
import { IProfile } from "../interfaces";

const CardInfo = ({ profile }: { profile: IProfile }) => {
  return (
    <div className="flex gap-x-20 flex-wrap">
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">Card Number:</p>
          <p>63536363784746</p>
        </div>
        <div>
          <p className="text-brandBlack-200">CVV:</p>
          <p>***</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">Card Type:</p>
          <p>Visa</p>
        </div>
        <div>
          <p className="text-brandBlack-200">Holder's Name:</p>
          <p>Alan j. Smith</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">Expiry Date:</p>
          <p>May 25, 2027</p>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
