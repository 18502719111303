import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import EditProfile from "../components/EditProfile";
import ProfileInfo from "../components/ProfileInfo";
import EditCardInfo from "../components/EditCardInfo";
import CardInfo from "../components/CardInfo";
import { IProfile, IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "..";
import ResetPasswordModal from "../components/ResetPasswordModal";
import { Toaster } from "sonner";
import { Country, State } from "country-state-city";
import { apiFetch } from "../service/useApi";

const Profile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [editCardInfo, setEditCardInfo] = useState(false);
  const [profile, setProfile] = useState<IProfile>();
  const [countryName, setCountryName] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const navigate = useNavigate();
  const getProfile = async () => {
    const { isLoaded, data } = await apiFetch(`/api/Customer/Get`, "GET");
    if (isLoaded && data.statusCode == 200) {
      const country = Country.getCountryByCode(data.data.profile.countryId);
      const state = State.getStateByCodeAndCountry(
        data.data.profile.stateId,
        data.data.profile.countryId
      );
      setCountryName(country?.name as string);
      setStateName(state?.name as string);
      console.log({ country });
      setProfile(data.data);
    }

    // const userId = localStorage.getItem("userId") as unknown as string;
    // const accessToken = localStorage.getItem(
    //   "accessToken"
    // ) as unknown as string;
    // if (!userId || !accessToken) {
    //   navigate("/");
    // }
    // console.log(userId);
    // const url = new URL(API_BASE_URL + `/api/Customer/Get?userId=${userId}`);
    // try {
    //   const res = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const profile: IResponseObject = await res.json();
    //   if (profile.statusCode == 200) {
    //     const country = Country.getCountryByCode(
    //       profile.data.profile.countryId
    //     );
    //     const state = State.getStateByCodeAndCountry(
    //       profile.data.profile.stateId,
    //       profile.data.profile.countryId
    //     );
    //     setCountryName(country?.name as string);
    //     setStateName(state?.name as string);
    //     console.log({ country });
    //     setProfile(profile.data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    getProfile();
  }, [editProfile]);

  if (!profile) {
    return <Loading />;
  }
  return (
    <Template>
      <Toaster richColors position="top-right" />
      <ResetPasswordModal
        isOpen={openPasswordModal}
        setIsOpen={setOpenPasswordModal}
        title="Change Password"
      />
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"profile"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white pb-10">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">My Account Overview</p>
          </div>
          <div className="px-6">
            <div className="h-12 w-full flex items-center border-b mb-4 justify-between">
              <p className="font-medium">My Details</p>
              {editProfile ? (
                <div
                  className="underline underline-offset-8 cursor-pointer text-brandRed-100"
                  onClick={() => setEditProfile(!editProfile)}
                >
                  Cancel
                </div>
              ) : (
                <div
                  className="underline underline-offset-8 cursor-pointer text-brandOrange-200"
                  onClick={() => setEditProfile(!editProfile)}
                >
                  Edit
                </div>
              )}
            </div>
            <div className="flex flex-col gap-10">
              {editProfile ? (
                <EditProfile
                  profile={profile as unknown as IProfile}
                  setEdit={setEditProfile}
                />
              ) : (
                <ProfileInfo
                  profile={profile as unknown as IProfile}
                  country={countryName}
                  state={stateName}
                />
              )}
              <div className="border-b h-10 flex justify-between">
                <div className="font-medium">Card Details</div>
                {editCardInfo ? (
                  <div
                    className="underline underline-offset-8 cursor-pointer text-brandRed-100"
                    onClick={() => setEditCardInfo(!editCardInfo)}
                  >
                    Cancel
                  </div>
                ) : (
                  <div
                    className="underline underline-offset-8 cursor-pointer text-brandOrange-200"
                    onClick={() => setEditCardInfo(!editCardInfo)}
                  >
                    Edit
                  </div>
                )}
              </div>
              {editCardInfo ? (
                <EditCardInfo profile={profile as unknown as IProfile} />
              ) : (
                <CardInfo profile={profile as unknown as IProfile} />
              )}
              <div className="w-full mt-10 flex justify-center">
                <button
                  className="h-10 px-4 py-2 rounded-md border border-brandOrange-200 text-brandOrange-200 bg-white"
                  onClick={() => setOpenPasswordModal(true)}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Profile;
