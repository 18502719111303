import React, { ReactNode, useEffect, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import Footer from "./Footer";
import SearchContext from "../SearchContext";
import { Toaster } from "sonner";

const Template = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState();
  const [socialList, setSocialList] = useState();
  const logo = localStorage.getItem("logo") as unknown as string;
  const storeName = localStorage.getItem("storeTitle") as unknown as string;
  useEffect(() => {
    setCategories(
      JSON.parse(localStorage.getItem("categoriesList") as unknown as string)
    );
    setSocialList(
      JSON.parse(localStorage.getItem("socials") as unknown as any)
    );
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = logo;

    document.title = storeName;
  }, []);

  return (
    <div>
      <Toaster richColors position="top-right" />
      <HeaderComponent categories={categories} logo={logo} />
      {children}
      <Footer categories={categories} logo={logo} socials={socialList} />
    </div>
  );
};

export default Template;
