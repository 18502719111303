import React, { useEffect, useState } from "react";

import { FaChevronUp } from "react-icons/fa";
import SingleOrderCard from "./SingleOrderCard";
import OrderCardDetails from "./OrderCardDetails";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { apiFetch, apiPost } from "../service/useApi";

const OrderCard = ({ singleOrder }: { singleOrder: any }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [addressDetails, setAddressDetails] = useState([]);
  const getDetails = async () => {
    // const userId = localStorage.getItem("userId") as unknown as string;
    // const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    // const accessToken = localStorage.getItem(
    //   "accessToken"
    // ) as unknown as string;

    // console.log(singleOrder);

    const { isLoaded, data } = await apiFetch(
      `/api/OrderDeliveryAddress/Get?orderId=${singleOrder.orderId}`,
      "GET"
    );

    if (isLoaded && data.statusCode == 200) {
      setLoading(false);
      setAddressDetails(data.data);
    }
    // const url = new URL(
    //   API_BASE_URL +
    //     `/api/OrderDeliveryAddress/Get?orderId=${singleOrder.orderId}&storefrontId=${storeId}`
    // );
    // try {
    //   const res = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const notifications: IResponseObject = await res.json();
    //   if (notifications.statusCode == 200) {
    //     setLoading(false);
    //     setAddressDetails(notifications.data);
    //     console.log({ notifications });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      {showDetails ? (
        <OrderCardDetails
          showDetails={showDetails}
          details={addressDetails}
          orderDetails={singleOrder}
        />
      ) : (
        <SingleOrderCard
          setShowDetails={setShowDetails}
          details={singleOrder}
        />
      )}
    </>
  );
};

export default OrderCard;
