import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { FaArrowLeft, FaPaperclip } from "react-icons/fa6";
import { GoSmiley } from "react-icons/go";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { FiMessageSquare, FiX } from "react-icons/fi";

export default function MessangerModal() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const defaultMessages = [
    "Order not received",
    "Payment issues",
    "Account access problem",
    "Talk to support",
    "Wrong item delivered",
  ];

  return (
    <div className="fixed right-10 bottom-10">
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="rounded bg-brandOrange-200 w-[56px] h-[56px] flex justify-center items-center text-sm font-medium text-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          {isOpen ? (
            <FiX color="#ffffff" size={24} />
          ) : (
            <FiMessageSquare color="#ffffff" size={24} />
          )}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 shadow-lg"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-white  text-left align-middle shadow-xl transition-all fixed right-10 bottom-28">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6  bg-brandOrange-200 h-12 flex justify-between items-center text-white px-6"
                  >
                    <div>
                      <FaArrowLeft color="white" />
                    </div>{" "}
                    <p>Support</p>
                    <span></span>
                  </Dialog.Title>
                  <div className="p-6">
                    <div className="flex justify-start gap-x-3">
                      <span className="h-10 w-10 rounded-full bg-brandOrange-800 flex justify-center items-center text-xl">
                        F
                      </span>
                      <div>
                        <p className="font-medium">Fotologue Bot</p>
                        <p className="text-sm text-brandBlack-200">
                          Customer Support
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="rounded-md max-w-[226px] p-3 text-sm text-brandBlack-300 bg-[#F2F2F2]">
                        <p>Hi there! Welcome to Fotologue chat support.</p>
                        <p>How can we help you today?</p>
                      </div>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-2">
                      {defaultMessages.map((msg) => (
                        <span className="border border-brandOrange-200 text-brandOrange-200 rounded-l-full rounded-r-full px-2 py-1 text-sm">
                          {msg}
                        </span>
                      ))}
                    </div>
                    <div className="mt-4 flex justify-end">
                      <div className="rounded-md max-w-[226px] p-3 text-sm text-white bg-brandOrange-200">
                        <p>Account access problems</p>
                      </div>
                    </div>
                    <div className="mt-4 flex gap-x-2">
                      <div className="flex-1 border h-10 rounded px-2 border-brandBlack-500 flex items-center">
                        <GoSmiley size={20} color="#9D9D9D" />
                        <input
                          type="text"
                          className=" w-full ml-2 outline-0"
                          placeholder="Enter message..."
                        />
                        <FaPaperclip size={20} color="#9D9D9D" />
                      </div>
                      <div className="flex justify-center items-center h-10 w-10 bg-[#f2f2f2] rounded">
                        <IoPaperPlaneOutline color="#9D9D9D" size={20} />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
