import React, { useContext, useState } from "react";
import { FiX } from "react-icons/fi";
import CustomizationContext from "../../CustomizationContext";
import { FiImage } from "react-icons/fi";
import { RxText } from "react-icons/rx";

const LayoutTab = ({ hideView }: { hideView: () => void }) => {
  const [selected, setSelected] = useState("");

  const { applyTemplate } = useContext(CustomizationContext);
  return (
    <div className=" h-full w-[400px] bg-white border-r p-3">
      <div className="w-full flex justify-between items-center">
        <p className="text-xl font-medium">Layout</p>
        <span onClick={hideView} className="cursor-pointer">
          <FiX size={22} />
        </span>
      </div>
      <div className="flex flex-wrap w-full mt-4 justify-between gap-y-3">
        <div
          className="border border-brandBlack-200 h-[156px] w-[140px] p-2"
          onClick={() => applyTemplate("textCenter")}
        >
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <RxText className="text-brandBlack-200" />
          </div>
        </div>
        <div
          className="border border-brandBlack-200 h-[156px] w-[140px] flex flex-col gap-y-2 p-2"
          onClick={() => applyTemplate("textTopImageBottom")}
        >
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <RxText className="text-brandBlack-200" />
          </div>
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <FiImage className="text-brandBlack-200" />
          </div>
        </div>
        <div
          onClick={() => applyTemplate("imageTopTextBottom")}
          className="border border-brandBlack-200 h-[156px] w-[140px] flex flex-col gap-y-2 p-2"
        >
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <FiImage className="text-brandBlack-200" />
          </div>
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <RxText className="text-brandBlack-200" />
          </div>
        </div>
        <div
          onClick={() => applyTemplate("textTopTwoImagesBottom")}
          className="border border-brandBlack-200 h-[156px] w-[140px] p-2 flex flex-col gap-y-1"
        >
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <RxText className="text-brandBlack-200" />
          </div>
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <FiImage className="text-brandBlack-200" />
          </div>
          <div className="w-full h-full border border-dashed border-brandBlack-200 flex justify-center items-center">
            <FiImage className="text-brandBlack-200" />
          </div>
        </div>

        {/* <div className="border border-brandBlack-200 h-[156px] w-[140px]"></div>
        <div className="border border-brandBlack-200 h-[156px] w-[140px]"></div>
        <div className="border border-brandBlack-200 h-[156px] w-[140px]"></div>
        <div className="border border-brandBlack-200 h-[156px] w-[140px]"></div> */}
      </div>
    </div>
  );
};

export default LayoutTab;
