import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useContext, useState } from "react";
import {
  FiX,
  FiAlignLeft,
  FiAlignRight,
  FiAlignCenter,
  FiBold,
  FiUnderline,
  FiMinus,
  FiPlus,
} from "react-icons/fi";
import { FaArrowRotateLeft, FaArrowRotateRight } from "react-icons/fa6";
import CustomizationContext from "../../CustomizationContext";

const TextTab = ({ hideView }: { hideView: () => void }) => {
  const fonts = [
    { name: "Poppings" },
    { name: "Arial" },
    { name: "Helvetica" },
    { name: "Sans serif" },
    { name: "Papyrus" },
  ];

  const fontSizes = [
    { size: "12" },
    { size: "14" },
    { size: "16" },
    { size: "18" },
    { size: "20" },
  ];
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [selectedFontSize, setSelectedFontSize] = useState(fontSizes[0]);

  const { fill, handleFillChange, text, handleTextChange } = useContext(CustomizationContext);

  return (
    <div className=" h-full w-[400px] bg-white border-r p-4">
      <div
        className="w-full flex justify-end my-10 items-center cursor-pointer"
        onClick={hideView}
      >
        <FiX size={22} />
      </div>
      <div className="flex w-full  flex-col justify-center gap-y-6">
        <p className="font-medium text-lg text-center">Text Edit</p>
        <textarea
          name=""
          id=""
          className="w-full h-[116px] border rounded border-brandBlack-200 resize-none"
          value={text}
          onChange={handleTextChange}
          placeholder="Enter text"
        ></textarea>
        <div className="w-full grid grid-cols-4 gap-x-4">
          <div className="col-span-3">
            <Listbox value={selectedFont} onChange={setSelectedFont}>
              <div className="relative h-full">
                <Listbox.Button className="relative w-full cursor-pointer rounded bg-transparent border border-brandBlack-200 h-full py-2 pl-3 pr-10 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">{selectedFont.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-20 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {fonts.map((font, fontIdx) => (
                      <Listbox.Option
                        key={fontIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={font}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {font.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="col-span-1">
            <Listbox value={selectedFontSize} onChange={setSelectedFontSize}>
              <div className="relative h-full">
                <Listbox.Button className="relative w-full cursor-pointer rounded bg-transparent border border-brandBlack-200 h-full pl-2 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">
                    {selectedFontSize.size}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md z-20 bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {fontSizes.map((size, sizeIdx) => (
                      <Listbox.Option
                        key={sizeIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-2  ${
                            active
                              ? "bg-brandOrange-600 text-brandOrange-200"
                              : "text-gray-900"
                          }`
                        }
                        value={size}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {size.size}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brandOrange-200">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
        <div className="w-full grid grid-cols-4 gap-x-4">
          <div className="col-span-3 flex justify-between h-full">
            <div className="rounded  w-[120px] border border-brandBlack-200 flex">
              <div className="h-full w-10 border-r border-brandBlack-200 flex justify-center items-center">
                <FiAlignLeft size={22} />
              </div>
              <div className="h-full w-10 flex justify-center items-center">
                <FiAlignCenter size={22} />
              </div>
              <div className="h-full w-10 border-brandBlack-200 border-l flex justify-center items-center">
                <FiAlignRight size={22} />
              </div>
            </div>
            <div className="rounded  w-10 border border-brandBlack-200 flex justify-center items-center">
              <FiBold size={22} />
            </div>
            <div className="rounded  w-10 border border-brandBlack-200 flex justify-center items-center">
              <FiUnderline size={22} />
            </div>
          </div>
          <div className="col-span-1">
            <input
              type="color"
              value={fill}
              onChange={handleFillChange}
              className="rounded border w-full border-brandBlack-200"
            />
            {/* <Listbox value={selectedFontSize} onChange={setSelectedFontSize}>
              <div className="relative h-full">
                <Listbox.Button className="relative w-full cursor-pointer rounded bg-transparent border border-brandBlack-200 h-full py-2 pl-2 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">
                    {selectedFontSize.size}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-20 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {fontSizes.map((size, sizeIdx) => (
                      <Listbox.Option
                        key={sizeIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-2  ${
                            active
                              ? "bg-brandOrange-600 text-brandOrange-200"
                              : "text-gray-900"
                          }`
                        }
                        value={size}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {size.size}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brandOrange-200">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox> */}
          </div>
        </div>
        <div className="flex gap-x-2 items-center">
          <p className="w-16">Rotate</p>
          <div className="h-8 w-8 rounded border border-brandBlack-200 flex justify-center items-center">
            {" "}
            <FaArrowRotateLeft size={22} />
          </div>
          <div className="h-8 w-8 rounded border border-brandBlack-200 flex justify-center items-center">
            {" "}
            <FaArrowRotateRight size={22} />
          </div>
          <div className="w-16 px-2 h-8 rounded border border-brandBlack-200 flex items-center"></div>
        </div>
        <div className="flex gap-x-2 items-center">
          <p className=" w-16">Scale</p>
          <div className="h-8 w-8 rounded border border-brandBlack-200 flex justify-center items-center">
            <FiMinus size={22} />
          </div>
          <div className="h-8 w-8 rounded border border-brandBlack-200 flex justify-center items-center">
            <FiPlus size={22} />
          </div>
        </div>
        <button className="w-full h-9 rounded border border-brandOrange-200 bg-transparent text-brandOrange-200 font-medium">
          Remove Text
        </button>
        <button className="w-full h-9 rounded bg-brandOrange-200 text-white font-medium">
          Done
        </button>
      </div>
    </div>
  );
};

export default TextTab;
