import { API_BASE_URL } from "..";

export const apiFetch = async (url: string, method: string, body?: any) => {
  const storeId = localStorage.getItem("storeFrontId");
  const accessToken = localStorage.getItem("accessToken") as unknown as string;
  const uri = API_BASE_URL + url;
  console.log({ body });
  try {
    const res = await fetch(uri, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        storeFrontId: storeId || "",
      },
    });

    const data = await res.json();
    console.log(data);
    if (data) {
      return {
        data,
        isLoaded: true,
      };
    } else {
      return {
        data: null,
        isLoaded: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      data: null,
      isLoaded: true,
    };
  }
};

export const apiPost = async (url: string, method: string, body: any) => {
  const storeId = localStorage.getItem("storeFrontId");
  const tenantId = localStorage.getItem("tenantId");
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "";
  const uri = API_BASE_URL + url;
  console.log({ body });
  try {
    const res = await fetch(uri, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        storeFrontId: storeId || "",
        tenantId: tenantId || "",
      },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    console.log(data);
    if (data) {
      return {
        data,
        isLoaded: true,
        error: false,
      };
    } else {
      return {
        data,
        isLoaded: true,
        error: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      data: null,
      isLoaded: true,
      error,
    };
  }
};
