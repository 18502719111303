import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IResponseObject } from "../interfaces";
import { Toaster, toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { API_BASE_URL } from "..";
import { IoStarSharp } from "react-icons/io5";
import { FiX } from "react-icons/fi";

export default function RatingModal({
  setShowRatingModal,
  showRatingModal,
}: {
  showRatingModal: boolean;
  setShowRatingModal: any;
}) {
  function closeModal() {
    setShowRatingModal(false);
  }

  const [name, setName] = useState("");
  const [occasion, setOccasion] = useState("");
  const [intervalType, setIntervalType] = useState("");
  const [date, setDate] = useState<any>();
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  //   const addReminder = async (e: any) => {
  //     setIsAdding(true);
  //     e.preventDefault();
  //     const userId = localStorage.getItem("userId") as unknown as string;
  //     const storeFrontId = localStorage.getItem(
  //       "storeFrontId"
  //     ) as unknown as string;
  //     const tenantId = localStorage.getItem("tenantId") as unknown as string;
  //     const accessToken = localStorage.getItem(
  //       "accessToken"
  //     ) as unknown as string;

  //     console.log(date);

  //     const bodyInput = {
  //       storeFrontId,
  //       tenantId,
  //       userId,
  //       type: 0,
  //       date: date,
  //       intervalType: +intervalType,
  //       name,
  //       description: occasion,
  //       status: 1,
  //     };

  //     console.log(bodyInput);
  //     const url = new URL(API_BASE_URL + `/api/Reminder/Create`);
  //     try {
  //       const res = await fetch(url, {
  //         method: "POST",
  //         body: JSON.stringify(bodyInput),
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });
  //       const reminder: IResponseObject = await res.json();
  //       if (reminder.statusCode == 200) {
  //         setName("");
  //         setOccasion("");
  //         setIntervalType("");
  //         setDate("");
  //         setIsAdding(false);
  //         console.log(reminder);
  //         toast.success(reminder.message);
  //         closeModal();
  //         // navigate(0);
  //       } else {
  //         setIsAdding(false);
  //         toast.error(reminder.message);
  //       }
  //     } catch (error) {
  //       setIsAdding(false);
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      <Toaster richColors position="top-right" />
      <Transition appear show={showRatingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[350px]  transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <span
                    className="absolute right-3 top-3 cursor-pointer"
                    onClick={closeModal}
                  >
                    <FiX color="" size={24} />
                  </span>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Rate Poduct
                  </Dialog.Title>
                  <p className="text-brandBlack-200">
                    Leave a review for us to serve you better
                  </p>
                  <div className="mt-4 flex justify-center gap-x-2">
                    <IoStarSharp size={32} color="#CDCDCD" />
                    <IoStarSharp size={32} color="#CDCDCD" />
                    <IoStarSharp size={32} color="#CDCDCD" />
                    <IoStarSharp size={32} color="#CDCDCD" />
                    <IoStarSharp size={32} color="#CDCDCD" />
                  </div>
                  <div className="mt-4">
                    <p>Feedback:</p>
                    <textarea
                      className="border w-full mt-4 rounded-md border-brandBlack-500 p-2 h-[107px]"
                      placeholder="Enter text"
                    ></textarea>
                    <div className="mt-4 w-full flex justify-between gap-x-2">
                      <button className="h-12 w-1/2 border rounded-md text-brandOrange-200 border-brandOrange-200 font-bold">
                        Cancel
                      </button>
                      <button className="h-12 w-1/2 border rounded-md text-white bg-brandOrange-200 border-brandOrange-200 font-bold" >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
