import React, { useState } from "react";
import { IoChevronDown } from "react-icons/io5";

const FilterAccordion = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="my-1 py-2 border-b">
      <div
        className="w-full justify-between items-center h-full flex cursor-pointer"
        onClick={() => setToggle(!toggle)}
      >
        <p className="font-medium">Who is it for?</p>
        <span className={`${toggle ? "rotate-180" : ""}`}>
          <IoChevronDown size={16} />
        </span>
      </div>
      {toggle ? (
        <div className="mt-2 py-4 ease-in transition-all ">
          <div className="mb-2 flex items-center relative">
            <input
              type="checkbox"
              id="some_id"
              className="appearance-none peer shrink-0 cursor-pointer w-4 h-4 border border-brandOrange-200 rounded-sm bg-white"
            />
            <label htmlFor="some_id" className="text-xs ml-2 font-medium">
              Boyfriend
            </label>
            <svg
              width="11"
              height="9"
              viewBox="0 0 11 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden peer-checked:block absolute w-2 ml-1"
            >
              <path
                d="M10.1667 1.5L3.75004 7.91667L0.833374 5"
                stroke="#F89521"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className=" flex items-center mb-2">
            <input type="checkbox" name="" id="" />
            <label htmlFor="" className="text-xs ml-2 font-medium">
              Girlfriend <span className="font-thin">(10)</span>
            </label>
          </div>
          <div className=" flex items-center mb-2">
            <input type="checkbox" name="" id="" />
            <label htmlFor="" className="text-xs ml-2 font-medium">
              Wife <span className="font-thin">(5)</span>
            </label>
          </div>
          <div className=" flex items-center mb-2">
            <input type="checkbox" name="" id="" />
            <label htmlFor="" className="text-xs ml-2 font-medium">
              Husband <span className="font-thin">(10)</span>
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterAccordion;
