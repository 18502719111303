import { FaCheck } from "react-icons/fa6";

export const CreateInformation = ({
  setShowForm,
  showForm,
}: {
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const benefitList = [
    "Fast checkout with your details already saved",
    "Track your orders and view order history",
    "Receive our latest news and offers.",
    "Remember all the special occasions with our event reminder",
  ];
  return (
    <div className="flex justify-center">
      <div className="drop-shadow-2xl bg-white mt-2 rounded-xl px-4 py-8 max-w-[568px]">
        <div className="w-full">
          <div className="mt-10">
            <h4 className="text-2xl font-medium">New to Fotologue?</h4>
            <p className="mt-2">
              Explore a world of creativity. Setting up an account with us is
              quick and easy.
            </p>
          </div>
          <div className="mt-5">
            {benefitList.map((benefit) => (
              <div key={benefit} className="flex items-center my-4">
                <FaCheck color="#39B54A" size={24} />
                <p className="ml-4">{benefit}</p>
              </div>
            ))}
          </div>
          <button
            className="mt-5 rounded-lg border border-brandOrange-200 text-brandOrange-200 w-full h-11"
            onClick={() => setShowForm(!showForm)}
          >
            Create a free account
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};
