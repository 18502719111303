import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCard from "./ReviewCard";
import NextArrow from "./NextArrowReviews";
import PrevArrow from "./PrevArrowReviews copy";

const ReviewsSlider = ({ slides }: { slides: any[] }) => {
  const [slideToShow, setSlideToShow] = useState(5);

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const setSlides = () => {
    if (window.innerWidth <= 1280 && window.innerWidth > 1000) {
      setSlideToShow(4);
    } else if (window.innerWidth <= 1000 && window.innerWidth > 650) {
      setSlideToShow(3);
    } else if (window.innerWidth <= 650) {
      setSlideToShow(2);
    }
  };

  useEffect(() => {
    setSlides();
  }, []);

  return (
    <div className="relative">
      <Slider {...settings}>
        {slides.map((el: any, i: number) => (
          <ReviewCard key={i} slide={el} />
        ))}
      </Slider>
    </div>
  );
};

export default ReviewsSlider;
