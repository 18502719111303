import React, { useRef } from "react";
import CustomizationTopBar from "./TopBar";
import CustomizationSideBar from "./SideBar";
import { LayoutView } from "../../pages/Customize";
import FabricCanvas from "../FabricCanvas";

const CustomizationLayout = ({
  children,
  view,
  setView,
  amount
}: {
  children: JSX.Element;
  view: LayoutView;
  setView: React.Dispatch<React.SetStateAction<LayoutView>>;
  amount: string;
}) => {
  
  return (
    <>
      <CustomizationTopBar amount={amount} />
      <div className="pt-20 h-screen pl-32">{children}</div>
      <CustomizationSideBar view={view} setView={setView} />
    </>
  );
};

export default CustomizationLayout;
