import React, { Fragment, useEffect, useState } from "react";
import Template from "../components/Template";
import {
  BiChevronRight,
  BiHeart,
  BiMinus,
  BiPlus,
  BiStar,
} from "react-icons/bi";
import Majesty from "../assets/majesty-lg.png";
import MajestySm from "../assets/majestycard.png";
import Accordion from "../components/Accordion";
import { FiPackage } from "react-icons/fi";
import { BsFillStarFill } from "react-icons/bs";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { TfiArrowLeft, TfiArrowRight } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Toaster, toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { RiHeartFill } from "react-icons/ri";
import { numberWithCommas } from "../utils";
import { apiFetch, apiPost } from "../service/useApi";

const ProductDetails = () => {
  const tenantId = localStorage.getItem("tenantId") as unknown as string;
  const userId = localStorage.getItem("userId") as unknown as string;
  const storeFrontId = localStorage.getItem(
    "storeFrontId"
  ) as unknown as string;
  let { id } = useParams();
  const [productdetails, setProductDetails] = useState<any>();
  const [reviews, setReviews] = useState<any>();
  const [productOption, setProductOption] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [addingToCart, setAddingToCart] = useState(false);
  const [selectedSize, setSelectedSize] = useState();
  const [selectedSizeId, setSelectedSizeId] = useState<any>();
  const [selectedEdge, setSelectedEdge] = useState();
  const [selectedEdgeId, setSelectedEdgeId] = useState<any>();
  const [selectedLamination, setSelectedLamination] = useState();
  const [selectedLaminationId, setSelectedLaminationId] = useState<any>();
  const [selectedPaper, setSelectedPaper] = useState();
  const [selectedPaperId, setSelectedPaperId] = useState<any>();
  const [qty, setQty] = useState(1);
  const [sizes, setSizes] = useState<any>();
  const [paper, setPaper] = useState<any>();
  const [edge, setEdge] = useState<any>();
  const [faqs, setFaq] = useState<any>();
  const [lamination, setLamination] = useState<any>();
  console.log(productdetails);

  useEffect(() => {
    const getReviews = async (id: any) => {
      const { isLoaded, data } = await apiFetch(
        `/api/ProductReview/GetProductReview?productId=${id}`,
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setLoading(false);
        console.log({ reviews });
        setReviews(reviews.data);
      }
      // const id = productdetails ? productdetails.id : 1;
      // console.log({ id });
      // const url = new URL(
      //   API_BASE_URL +
      //     `/api/ProductReview/GetProductReview?productId=${id}&storeFrontId=${storeFrontId}&customerId=${userId}`
      // );
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Methods": "POST, GET, PUT",
      //       "Access-Control-Allow-Headers": "Content-Type",
      //     },
      //   });
      //   const reviews = await res.json();
      //   console.log(reviews);
      //   if (reviews) {
      //     setLoading(false);
      //     console.log({ reviews });
      //     setReviews(reviews.data);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };
    const getProduct = async () => {
      const { isLoaded, data } = await apiFetch(
        `/api/Product/GetProductBySlug?Slug=${id}`,
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setLoading(false);
        setProductDetails(data.data);
        getReviews(data.data.id);
      }
      // const url = new URL(
      //   API_BASE_URL +
      //     `/api/Product/GetProductBySlug?Slug=${id}&StoreConfig.TenantId=${tenantId}&StoreConfig.StoreFrontId=${storeFrontId}&StoreConfig.UserId=${userId}`
      // );
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Methods": "POST, GET, PUT",
      //       "Access-Control-Allow-Headers": "Content-Type",
      //     },
      //   });
      //   const product = await res.json();
      //   console.log(product);
      //   if (product) {
      //     console.log(product.data);
      //     setProductDetails(product.data);
      //     getReviews(product.data.id);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };

    const getProductOption = async () => {
      const { isLoaded, data } = await apiFetch(
        `/api/ProductOption/Get`,
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setLoading(false);
        console.log("productOption =>", data.data);
        setProductOption(data);
        // productOption && productOption.data.map((p: any) => console.log(p.name));
        const sizes = data.data.filter((option: any) => option.name == "Size");

        const lamination = data.data.filter(
          (option: any) => option.name == "Lamination"
        );

        const paper = data.data.filter(
          (option: any) => option.name == "Paper Type"
        );

        const edge = data.data.filter(
          (option: any) => option.name == "Edge Finishing"
        );

        setSizes(sizes);
        setLamination(lamination);
        setPaper(paper);
        setEdge(edge);
      }

      // const url = new URL(API_BASE_URL + `/api/ProductOption/Get`);
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Methods": "POST, GET, PUT",
      //       "Access-Control-Allow-Headers": "Content-Type",
      //     },
      //   });
      //   const productOption = await res.json();
      //   console.log(productOption);
      //   if ((productOption.statusCode = 200)) {
      //     setLoading(false);
      //     console.log({ productOption });
      //     setProductOption(productOption.data);
      //     productOption.data.map((p: any) => console.log(p.name));
      //     const sizes =
      //       productOption &&
      //       productOption.data.filter((option: any) => option.name == "Size");

      //     const lamination =
      //       productOption &&
      //       productOption.data.filter(
      //         (option: any) => option.name == "Lamination"
      //       );

      //     const paper =
      //       productOption &&
      //       productOption.data.filter(
      //         (option: any) => option.name == "Paper Type"
      //       );

      //     const edge =
      //       productOption &&
      //       productOption.data.filter(
      //         (option: any) => option.name == "Edge Finishing"
      //       );

      //     setSizes(sizes);
      //     setLamination(lamination);
      //     setPaper(paper);
      //     setEdge(edge);

      //     console.log(setSizes);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };
    // const getReviews = async () => {
    //   const prodId = productdetails ? productdetails.id : "";
    //   console.log({ id });
    //   const url = new URL(
    //     API_BASE_URL +
    //       `/api/ProductReview/GetProductReview?storeFrontId=${storeFrontId}&customerId=${userId}`
    //   );
    //   try {
    //     const res = await fetch(url, {
    //       method: "GET",
    //       headers: {
    //         "content-type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": "POST, GET, PUT",
    //         "Access-Control-Allow-Headers": "Content-Type",
    //       },
    //     });
    //     const reviews = await res.json();
    //     console.log(reviews);
    //     if (reviews) {
    //       setLoading(false);
    //       console.log({ reviews });
    //       // setProductDetails(product.data);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const getFaq = async () => {
      const { isLoaded, data } = await apiFetch(
        `/api/FAQ/GetFAQs?slug=${id}&Type=CATFAQTYPE`,
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setFaq(data.data);
      }

      // const storeFrontId = localStorage.getItem(
      //   "storeFrontId"
      // ) as unknown as string;
      // const url = new URL(
      //   API_BASE_URL +
      //     `/api/FAQ/GetFAQs?slug=${id}&Type=CATFAQTYPE&StoreFrontId=${storeFrontId}`
      // );
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Methods": "POST, GET, PUT",
      //       "Access-Control-Allow-Headers": "Content-Type",
      //     },
      //   });
      //   const faq = await res.json();
      //   console.log(faq);
      //   if (faq) {
      //     console.log({ faq });
      //     setFaq(faq.data);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };

    getProduct();
    getProductOption();
    getFaq();
  }, []);

  // useEffect(() => {

  // }, [productdetails]);

  if (loading) {
    return <Loading />;
  }

  interface IOptions {
    name: string;
    items: [
      {
        id: string;
        name: string;
        description: string;
        price: number;
      }
    ];
  }

  const favoriteProduct = async () => {
    const bodyInput = {
      userId,
      storefrontId: storeFrontId,
      productId: productdetails.id,
    };

    const { data, isLoaded } = await apiPost(
      `/api/Customer/FavoriteProduct`,
      "POST",
      bodyInput
    );
    if (isLoaded && data.statusCode == 200) {
      toast.success(
        data.data.isFavourite
          ? "Product removed from favorites"
          : "Product added to favorites"
      );
      //  setProductsList(products.data);
      // localStorage.setItem(
      //   "categoriesList",
      //   JSON.stringify(products.data)
      // );
      console.log(data);
    }

    // console.log(bodyInput);
    // const url = new URL(API_BASE_URL + `/api/Customer/FavoriteProduct`);
    // try {
    //   const res = await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify(bodyInput),
    //   });
    //   const products: IResponseObject = await res.json();
    //   //  console.log("prod =>", products);
    //   if (products.statusCode == 200) {
    //     toast.success(
    //       products.data.isFavourite
    //         ? "Product removed from favorites"
    //         : "Product added to favorites"
    //     );
    //     //  setProductsList(products.data);
    //     // localStorage.setItem(
    //     //   "categoriesList",
    //     //   JSON.stringify(products.data)
    //     // );
    //     console.log(products);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const rateProduct = async (rating: number) => {
    const bodyInput = {
      userId,
      storefrontId: storeFrontId,
      rating,
      productId: productdetails.id,
    };

    console.log(bodyInput);
    const url = new URL(API_BASE_URL + `/api/Customer/RateProduct`);
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(bodyInput),
      });
      const products: IResponseObject = await res.json();
      if (products.statusCode == 200) {
        console.log(products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const increament = () => {
    setQty(qty + 1);
  };

  const decreament = () => {
    if (qty === 1) {
      setQty(qty);
    } else {
      setQty(qty - 1);
    }
  };

  const personalize = async () => {
    setAddingToCart(true);
    console.log(qty);
    console.log(selectedEdgeId);
    console.log(selectedPaperId);
    console.log(selectedLaminationId);
    console.log(selectedSizeId);

    const options = [];

    if (selectedEdgeId) {
      options.push({
        productOptionId: selectedEdgeId,
      });
    }
    if (selectedPaperId) {
      options.push({
        productOptionId: selectedPaperId,
      });
    }
    if (selectedLaminationId) {
      options.push({
        productOptionId: selectedLaminationId,
      });
    }
    if (selectedSizeId) {
      options.push({
        productOptionId: selectedSizeId,
      });
    }

    const bodyInput = {
      quantity: qty,
      options: options,
      productId: productdetails.id,
      customerId: userId,
      storefrontId: storeFrontId,
    };

    console.log(bodyInput);
    const url = new URL(API_BASE_URL + `/api/Cart/Add`);
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(bodyInput),
      });
      const products: IResponseObject = await res.json();
      //  console.log("prod =>", products);
      if (products.statusCode == 200) {
        toast.success(`Product successfully added to cart`);
        setAddingToCart(false);
        //  setProductsList(products.data);
        // localStorage.setItem(
        //   "categoriesList",
        //   JSON.stringify(products.data)
        // );
        console.log(products);
      }
    } catch (error) {
      console.log(error);
      setAddingToCart(false);
      toast.error(`Sorry, something happened, try again later`);
    }
  };

  return (
    <Template>
      <Toaster richColors position="top-right" />
      <div className="px-20">
        <span className="flex items-center gap-x-4 my-10">
          <p className="text-brandOrange-200 underline underline-offset-8">
            Home
          </p>
          <BiChevronRight size={20} />
          <p className="text-brandOrange-200 underline underline-offset-8">
            {productdetails && productdetails.categoryName}
          </p>

          <BiChevronRight size={20} />
          <p className="">{productdetails && productdetails.name}</p>
        </span>
        <div className="grid grid-cols-7 gap-x-10 h-[712px]">
          <div className="col-span-1 h-full gap-y-8 flex flex-col">
            <img
              src={productdetails && productdetails?.imageUrl}
              width={132}
              alt=""
            />
            <img
              src={productdetails && productdetails?.imageUrl}
              width={132}
              alt=""
              className="opacity-50"
            />
            <img
              src={productdetails && productdetails?.imageUrl}
              width={132}
              alt=""
              className="opacity-50"
            />
            {/* <img src={MajestySm} width={132} alt="" /> */}
          </div>
          <div className="col-span-3 h-full">
            <img
              src={productdetails && productdetails?.imageUrl}
              alt={productdetails && productdetails.name}
              className="max-h-[712px]"
            />
          </div>
          <div className="col-span-3 h-full">
            <p className="text-xl font-medium mb-2">
              {productdetails && productdetails.name}
            </p>
            <p>{productdetails && productdetails.description}</p>
            {/* <div className="flex gap-x-2 items-center my-2">
              <div className="h-8 w-8 rounded-full bg-gray-500"></div>
              <p className="text-sm">Designed by Mike Spence</p>
            </div> */}
            <div className="flex gap-x-1 items-center">
              <BsFillStarFill
                color={
                  productdetails && productdetails.rating >= 1
                    ? "#F15B2B"
                    : "#727272"
                }
              />
              <BsFillStarFill
                color={
                  productdetails && productdetails.rating >= 2
                    ? "#F15B2B"
                    : "#727272"
                }
              />
              <BsFillStarFill
                color={
                  productdetails && productdetails.rating >= 3
                    ? "#F15B2B"
                    : "#727272"
                }
              />
              <BsFillStarFill
                color={
                  productdetails && productdetails.rating >= 4
                    ? "#F15B2B"
                    : "#727272"
                }
              />
              <BsFillStarFill
                color={
                  productdetails && productdetails.rating == 5
                    ? "#F15B2B"
                    : "#727272"
                }
              />
              {/* <p className="text-sm">(11)</p> */}
            </div>
            <p className="text-2xl font-medium my-2">
              &#8358;{" "}
              {productdetails && numberWithCommas(productdetails.unitPrice)}
            </p>
            <div className="flex w-[457px] flex-col mb-4">
              <div className="">
                <Listbox value={selectedSize} onChange={setSelectedSize}>
                  <div className="relative mt-1">
                    <Listbox.Label className={"font-medium"}>
                      Size
                    </Listbox.Label>
                    <Listbox.Button className="relative mt-2 w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                      <span className="block truncate">
                        {selectedSize ? selectedSize : "Select Size"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-brandOrange-200"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {sizes &&
                          sizes[0].items.map((size: any, sizeIdx: number) => (
                            <Listbox.Option
                              key={sizeIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                    : "text-gray-900"
                                }`
                              }
                              value={`${size.name} - ${
                                size.description
                              }, ₦${numberWithCommas(size.price)}`}
                              onClick={() => setSelectedSizeId(size.id)}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {`${size.name} - ${
                                      size.description
                                    }, ₦${numberWithCommas(size.price)}`}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div className="flex w-[457px] flex-col mb-4">
              <div className="">
                <Listbox value={selectedEdge} onChange={setSelectedEdge}>
                  <div className="relative mt-1">
                    <Listbox.Label className={"font-medium"}>
                      Edge Finishing
                    </Listbox.Label>
                    <Listbox.Button className="relative mt-2 w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                      <span className="block truncate">
                        {selectedEdge ? selectedEdge : "Select Edge Finishing"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-brandOrange-200"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {edge &&
                          edge[0].items.map((size: any, sizeIdx: number) => (
                            <Listbox.Option
                              key={sizeIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                    : "text-gray-900"
                                }`
                              }
                              value={`${size.name} - ₦${numberWithCommas(
                                size.price
                              )}`}
                              onClick={() => setSelectedEdgeId(size.id)}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {`${size.name} - ₦${numberWithCommas(
                                      size.price
                                    )}`}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div className="flex w-[457px] flex-col mb-4">
              <div className="">
                <Listbox value={selectedPaper} onChange={setSelectedPaper}>
                  <div className="relative mt-1">
                    <Listbox.Label className={"font-medium"}>
                      Paper Type
                    </Listbox.Label>
                    <Listbox.Button className="relative mt-2 w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                      <span className="block truncate">
                        {selectedPaper ? selectedPaper : "Select Paper Type"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-brandOrange-200"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {paper &&
                          paper[0].items.map((size: any, sizeIdx: number) => (
                            <Listbox.Option
                              key={sizeIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                    : "text-gray-900"
                                }`
                              }
                              value={`${size.name} - ₦${numberWithCommas(
                                size.price
                              )}`}
                              onClick={() => setSelectedPaperId(size.id)}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {`${size.name} - ₦${numberWithCommas(
                                      size.price
                                    )}`}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div className="flex w-[457px] flex-col mb-4">
              <div className="">
                <Listbox
                  value={selectedLamination}
                  onChange={setSelectedLamination}
                >
                  <div className="relative mt-1">
                    <Listbox.Label className={"font-medium"}>
                      Lamination
                    </Listbox.Label>
                    <Listbox.Button className="relative mt-2 w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                      <span className="block truncate">
                        {selectedLamination
                          ? selectedLamination
                          : "Select Lamination"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-brandOrange-200"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {lamination &&
                          lamination[0].items.map(
                            (size: any, sizeIdx: number) => (
                              <Listbox.Option
                                key={sizeIdx}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                      : "text-gray-900"
                                  }`
                                }
                                value={`${size.name} - ₦${numberWithCommas(
                                  size.price
                                )}`}
                                onClick={() => setSelectedLaminationId(size.id)}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {`${size.name} - ₦${numberWithCommas(
                                        size.price
                                      )}`}
                                    </span>
                                  </>
                                )}
                              </Listbox.Option>
                            )
                          )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div className="flex w-32 flex-col mb-4">
              <label htmlFor="">Quantity</label>
              <div className="w-full h-9 border rounded-md flex mt-2">
                <div
                  className="h-full border-r w-8 cursor-pointer flex justify-center items-center active:scale-90"
                  onClick={decreament}
                >
                  <BiMinus color={`${qty == 1 ? "#727272" : "#F89521"}`} />
                </div>
                <div className="flex-1 flex justify-center items-center">
                  {qty}
                </div>
                <div
                  className="h-full border-l w-8 cursor-pointer flex justify-center items-center active:scale-90"
                  onClick={increament}
                >
                  <BiPlus color="#F89521" />
                </div>
              </div>
            </div>
            <div className="flex w-[457px] mb-4 gap-x-5">
              <button
                className="text-white bg-brandOrange-200 flex-1 h-11 rounded-md"
                onClick={personalize}
              >
                {addingToCart ? (
                  <ClipLoader
                    color={"white"}
                    loading={addingToCart}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Add to cart"
                )}
              </button>
              <button
                className="h-11 w-11 border border-brandOrange-200 rounded-md bg-white cursor-pointer flex items-center justify-center active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                onClick={() => favoriteProduct()}
              >
                {productdetails && productdetails.isFavoutite ? (
                  <RiHeartFill color="#F89521" size={22} />
                ) : (
                  <BiHeart color="#F89521" size={22} />
                )}
              </button>
            </div>
            <div className="flex gap-x-5 w-[457px]">
              <div>
                <FiPackage color="#F89521" size={22} />
              </div>
              <div className="flex flex-col">
                <p className="text-xs font-medium">Free & Fast Delivery</p>
                <p className="text-xs">
                  Orders placed before 11:30pm are shipped on the same day
                  (Monday - Friday). Enjoy free shipping on all orders above
                  ₦25,000
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t mt-20">
          {faqs?.map((faq: any) => (
            <Accordion title={faq && faq.question} text={faq && faq?.answer} />
          ))}
        </div>
        <div className="mb-20">
          <div className="flex justify-between">
            {reviews && reviews.aggregate.count !== 0 ? (
              <div></div>
            ) : (
              <div>
                <p className="font-medium text-3xl mb-5">Customer Reviews</p>
                <div className="flex gap-x-1 items-center mb-1">
                  <MdOutlineStarPurple500
                    color={
                      reviews && reviews.aggregate.count >= 1
                        ? "#F15B2B"
                        : "#DEDEDE"
                    }
                    size={22}
                  />
                  <MdOutlineStarPurple500
                    color={
                      reviews && reviews.aggregate.count >= 2
                        ? "#F15B2B"
                        : "#DEDEDE"
                    }
                    size={22}
                  />
                  <MdOutlineStarPurple500
                    color={
                      reviews && reviews.aggregate.count >= 3
                        ? "#F15B2B"
                        : "#DEDEDE"
                    }
                    size={22}
                  />
                  <MdOutlineStarPurple500
                    color={
                      reviews && reviews.aggregate.count >= 4
                        ? "#F15B2B"
                        : "#DEDEDE"
                    }
                    size={22}
                  />
                  <MdOutlineStarPurple500
                    color={
                      reviews && reviews.aggregate.count == 5
                        ? "#F15B2B"
                        : "#DEDEDE"
                    }
                    size={22}
                  />
                </div>
                <p>Based on {reviews && reviews.aggregate.count} reviews</p>
              </div>
            )}
            <div className="flex-col text-sm w-[298px]">
              <p className="font-medium">Review this product</p>
              <div className="my-4 flex gap-x-2">
                <div
                  onClick={() => rateProduct(1)}
                  className="flex justify-center items-center cursor-pointer border border-brandOrange-100 rounded-md h-12 w-12 active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                >
                  <BiStar size={22} color="#F15B2B" />
                </div>
                <div
                  onClick={() => rateProduct(2)}
                  className="flex justify-center items-center cursor-pointer border border-brandOrange-100 rounded-md h-12 w-12 active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                >
                  <BiStar size={22} color="#F15B2B" />
                </div>
                <div
                  onClick={() => rateProduct(3)}
                  className="flex justify-center items-center cursor-pointer border border-brandOrange-100 rounded-md h-12 w-12 active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                >
                  <BiStar size={22} color="#F15B2B" />
                </div>
                <div
                  onClick={() => rateProduct(4)}
                  className="flex justify-center items-center cursor-pointer border border-brandOrange-100 rounded-md h-12 w-12 active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                >
                  <BiStar size={22} color="#F15B2B" />
                </div>
                <div
                  onClick={() => rateProduct(5)}
                  className="flex justify-center items-center cursor-pointer border border-brandOrange-100 rounded-md h-12 w-12 active:scale-90 active:border-brandOrange-100 transition-all ease-in "
                >
                  <BiStar size={22} color="#F15B2B" />
                </div>
              </div>
              <p>Adding a review requires a valid email for verification</p>
            </div>
          </div>
        </div>
        <div className="border-t py-8">
          {reviews &&
            reviews.reviews.map((review: any) => (
              <>
                <div className="pb-8 border-b mb-8">
                  <div className="flex gap-x-1 items-center mb-4">
                    <MdOutlineStarPurple500 color="#F15B2B" size={22} />
                    <MdOutlineStarPurple500 color="#F15B2B" size={22} />
                    <MdOutlineStarPurple500 color="#F15B2B" size={22} />
                    <MdOutlineStarPurple500 color="#F15B2B" size={22} />
                    <MdOutlineStarPurple500 color="#727272" size={22} />
                  </div>
                  <p className="font-medium mb-3">
                    Favorite birthday card ever!
                  </p>
                  <p className="text-sm mb-6">Anonymous on Dec 21, 2023</p>
                  <p>
                    I recently used this birthday card design template, and I
                    couldn't be happier with the results. The design was not
                    only visually stunning but also easy to customize to suit
                    the recipient's personality. The quality of the print was
                    excellent, and the card received countless compliments. It's
                    a fantastic option for adding a touch of creativity to your
                    birthday greetings.
                  </p>
                </div>
                <div className="w-full h-20 flex justify-between items-center">
                  <div></div>
                  <div className="flex gap-x-4">
                    <div className="h-8 border rounded w-8 flex justify-center items-center">
                      <TfiArrowLeft color="#727272" />
                    </div>
                    <div className="bg-brandOrange-200 h-8 border rounded w-8 flex justify-center items-center">
                      <TfiArrowRight color="#fff" />
                    </div>
                  </div>
                  <div>
                    <p>1-3 of 9 reviews</p>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </Template>
  );
};

export default ProductDetails;
