import React, { useEffect, useState } from "react";

import abstract from "../assets/abstractpattern.png";
import squidGame from "../assets/squidgamecard.png";
import majesty from "../assets/majestycard.png";
import book1 from "../assets/book-image-1.png";
import book2 from "../assets/book-image2.png";
import book3 from "../assets/book-image-3.png";
import book4 from "../assets/book-image4.png";
import premium from "../assets/premium square.png";
import december from "../assets/december.png";
import funny from "../assets/funny.png";
import flipCard from "../assets/flip card.png";
import cute from "../assets/cute.png";
import tv from "../assets/tv-related.png";
import disney from "../assets/disney.png";
import multi from "../assets/multi.png";
import trending from "../assets/trending.png";
import flower from "../assets/flowerimg.png";

import FeatureCard from "../components/FeatureCard";
import { FaArrowRight } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";
import ReviewsSlider from "../components/ReviewsSlider";
import Accordion from "../components/Accordion";
import ProgressSlider from "../components/ProgressSlider";
import Template from "../components/Template";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import ProductsSlider from "../components/ProductsSlider";
import { useParams } from "react-router-dom";
import { apiFetch } from "../service/useApi";

const ProductCategories = () => {
  interface IProduct {
    description: string;
    name: string;
    products: {
      id: string;
      imageUrl: string;
      isFavourite: boolean;
      name: string;
      ratings?: number;
      slug: string;
    }[];
    slug: string;
    status: number;
  }

  const photoBooks = [
    {
      img: book1,
      rating: 3,
      title: "Friends piece",
    },
    {
      img: book2,
      rating: 1,
      title: "Family portrait",
    },
    {
      img: book3,
      rating: 5,
      title: "Pet Portrait",
    },
    {
      img: book4,
      rating: 2,
      title: "Graduation Photo",
    },
    {
      img: squidGame,
      rating: 4,
      title: "Squid game card",
    },
    {
      img: majesty,
      rating: 3,
      title: "Her majesty card",
    },
  ];

  const featureCards = [
    {
      img: premium,
      title: "Premium Square",
    },
    {
      img: december,
      title: "December",
    },
    {
      img: funny,
      title: "Funny",
    },
    {
      img: flipCard,
      title: "Flip card",
    },
  ];
  const popularCards = [
    {
      img: cute,
      title: "Cute",
    },
    {
      img: tv,
      title: "TV Related",
    },
    {
      img: disney,
      title: "Disney",
    },
    {
      img: trending,
      title: "Trending",
    },
    {
      img: multi,
      title: "Multi-Photo",
    },
  ];

  const [faqs, setFaq] = useState<any[]>([]);
  const [productsList, setProductsList] = useState<IProduct>();
  const [loading, setLoading] = useState(true);
  let { category } = useParams();

  const getFaq = async () => {
    const { isLoaded, data } = await apiFetch(
      `/api/FAQ/GetFAQs?slug=${category}&Type=CATFAQTYPE`,
      "GET"
    );
    if (isLoaded && data.statusCode == 200) {
      setFaq(data.data)
    }
    // const storeFrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const url = new URL(
    //   API_BASE_URL +
    //     `/api/FAQ/GetFAQs?slug=${category}&Type=CATFAQTYPE&StoreFrontId=${storeFrontId}`
    // );
    // try {
    //   const res = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "POST, GET, PUT",
    //       "Access-Control-Allow-Headers": "Content-Type",
    //     },
    //   });
    //   const faq = await res.json();
    //   console.log(faq);
    //   if (faq) {
    //     console.log({ faq });
    //     setFaq(faq.data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const getCategoryProducts = async () => {
    setLoading(true)
    const { isLoaded, data } = await apiFetch(
      `/api/Category/GetCategoryProducts?Slug=${category}`,
      "GET"
    );
    if (isLoaded && data.statusCode == 200) {
     setLoading(false);
     setProductsList(data.data);
    }

    // setLoading(true);
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    // const userId = localStorage.getItem("userId") as unknown as string;
    // const storeFrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const url = new URL(
    //   API_BASE_URL +
    //     `/api/Category/GetCategoryProducts?Slug=${category}&StoreConfig.TenantId=${tenantId}&StoreConfig.StoreFrontId=${storeFrontId}&StoreConfig.UserId=${userId}`
    // );
    // try {
    //   const res = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "POST, GET, PUT",
    //       "Access-Control-Allow-Headers": "Content-Type",
    //     },
    //   });
    //   const products: IResponseObject = await res.json();
    //   console.log("prod =>", products);
    //   if (products.statusCode == 200) {
    //     setLoading(false);
    //     setProductsList(products.data);
    //     console.log(products.data);
    //     // localStorage.setItem(
    //     //   "categoriesList",
    //     //   JSON.stringify(products.data)
    //     // );
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.log(error);
    // }
  };
  useEffect(() => {
    getFaq();
    getCategoryProducts();
  }, []);

  useEffect(() => {
    getFaq();
    getCategoryProducts();
  }, [category]);

  const reviews = [1, 2, 3, 4, 5, 6];

  if (loading) {
    return <Loading />;
  }

  return (
    <Template>
      <div>
        <div className="w-full h-20 flex items-center justify-center relative flex-col py-4 sm:h-24">
          <img
            src={abstract}
            alt="abstract img"
            className="w-full object-cover absolute sm:h-24 top-0 right-0"
          />

          <p className="relative font-medium text-lg text-white">
            {productsList?.name}
          </p>
          <p className="relative text-sm mt-2 text-white">
            Access and customize any card to your taste
          </p>
        </div>
        <div className="sm:px-5 lg:px-20 mt-8">
          {productsList && <ProductsSlider slides={productsList.products} />}
        </div>
        <div className="mt-8 sm:px-5 lg:px-20 ">
          <div className="rounded-md bg-[#FFEDE8] sm:p-4 lg:p-10">
            <div className="mb-8 flex justify-between items-center w-full">
              <p className="font-medium text-3xl">Special features</p>
              <div>
                <p>Get Notified</p>
              </div>
            </div>
            <div className="flex justify-around ">
              {featureCards.map((el, i) => (
                <FeatureCard feature={el} key={i} />
              ))}
            </div>
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-white">
          <div className="flex justify-between mb-10">
            <div>
              <p className="font-medium text-2xl">
                Top {productsList?.name} Picks
              </p>
              <p className="text-brandBlack-200">
                Find the perfect card to send birthday wishes with our top picks
                for unique and personalised birthday cards.
              </p>
            </div>
            <span className="flex items-center">
              <p className="mr-1">View all</p>
              <FaArrowRight />
            </span>
          </div>
          <div className="slider_section">
            <ProgressSlider slides={photoBooks} />
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-brandBlue-100">
          <div className="flex justify-between mb-10">
            <div>
              <p className="font-medium text-2xl">Popular Ranges</p>
              <p className="text-brandBlack-200">
                Say happy birthday with the perfect card! Choose your style and
                personalise with custom photos and text for a special birthday
                surprise!
              </p>
            </div>
          </div>
          <div className="slider_section">
            <ProgressSlider slides={popularCards} />
          </div>
        </div>
        <div className="templates"></div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-white">
          <div className="flex items-center h-20 gap-x-8 border-b text-2xl font medium">
            <div className="h-full flex items-center relative text-brandBlack-100">
              Types & Sizes{" "}
              <span className="w-full h-2 bg-brandBlack-100 rounded-lg absolute bottom-0 right-0"></span>
            </div>
            <div className="h-full flex items-center relative text-brandBlack-400">
              Delivery
            </div>
            <div className="h-full flex items-center relative text-brandBlack-400">
              Product Information
            </div>
          </div>
          <div className=" min-h-[405px] sm:px-5 lg:px-20 py-10 flex items-center justify-center w-3/4">
            <div></div>
            <div className=" w-full h-full">
              <img src={flower} alt="flower" className="w-[407px]" />
            </div>
            <div className="">
              <p className="mb-4">
                Our birthday card designs are available in a huge range of sizes
                and styles including:
              </p>
              <p className="font-medium mb-2">A6, A5, A4 or Giant (A3)</p>
              <p className="font-medium mb-2">
                Square, large square or giant square
              </p>
              <p className="mb-4 font-medium">
                Multi photo, single photo, text personalised or non-personalised
              </p>
              <p className="mb-4">
                All birthday cards can be personalised on the inside with a
                custom message or left blank and sent back to your address.
              </p>
              <p className="mb-4">
                Our online editor also allows you to choose your font, size,
                colour and lots more customisation options, when adding your
                message to the inside.
              </p>
              <p className="mb-4">
                You can also add pictures to the inside pages and back of your
                card too, as well as edit them with filters, special effects and
                more.
              </p>
            </div>
          </div>
        </div>
        {faqs?.length > 0 && (
          <div className="sm:py-5 lg:py-10 bg-white sm:px-5 lg:px-20">
            <h2 className="font-medium text-3xl mb-10">
              {productsList?.name} Cards FAQs
            </h2>
            <div className="">
              {faqs?.map((faq: any) => (
                <Accordion
                  title={faq && faq.question}
                  text={faq && faq?.answer}
                />
              ))}
            </div>
          </div>
        )}
        <div className="sm:py-5 lg:py-10 bg-white sm:px-5 lg:px-20">
          <h2 className="font-medium text-3xl">Reviews</h2>
          <div className="flex gap-x-20 mt-8">
            <div className="w-[193px] h-[121px] rounded-md bg-[#FFEEE9] flex flex-col p-2 justify-around">
              <div className="font-bold text-2xl">
                Great{" "}
                <span className="font-medium text-lg text-darkBlue-300">
                  4/5
                </span>
              </div>
              <div className="flex gap-x-2">
                <IoStarSharp color="#F15B2B" size={24} />
                <IoStarSharp color="#F15B2B" size={24} />
                <IoStarSharp color="#DEDEDE" size={24} />
                <IoStarSharp color="#DEDEDE" size={24} />
                <IoStarSharp color="#DEDEDE" size={24} />
              </div>
              <p className="text-sm">Based on 120,000 reviews</p>
            </div>
            <div className="slider_section flex-1">
              <ReviewsSlider slides={reviews} />
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ProductCategories;
