import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-lg.png";
import book1 from "../assets/book-image-1.png";
import { FiLock, FiBell } from "react-icons/fi";
import VisaIcon from "../assets/Visa logo.png";
import MasterCardIcon from "../assets/MasterCard logo.png";

const CheckoutPaymentDetails = () => {
  return (
    <div className="overflow-y-hidden h-screen">
      <div className="flex px-20 justify-between items-center bg-white shadow-lg h-16 relative z-30">
        <div className="">
          <Link to={"/"}>
            <span className="">
              <img src={logo} alt="logo-img" width={100} />
            </span>
          </Link>
        </div>

        <div className="flex">
          <p className="mr-4">Secure checkout</p>
          <FiLock size={20} />
        </div>
      </div>
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-3  bg-white h-10 flex justify-center">
          <div className="mt-10 w-[560px] ">
            <div className="w-full flex justify-between mb-3">
              <p className="font-medium text-2xl">Checkout</p>
              <div className="flex text-brandOrange-200 border px-2 py-1 text-sm rounded-md items-center ">
                Add reminder <FiBell className="ml-2" size={16} />
              </div>
            </div>
            <div className="flex gap-x-5 text-sm text-brandBlack-200 pb-4 border-b mb-3">
              <span className="text-brandOrange-200 underline underline-offset-4">
                Delivery Address
              </span>
              <span>&gt;</span>
              <span className="text-brandOrange-200 underline underline-offset-4">
                Delivery Details
              </span>
              <span>&gt;</span>
              <span className="text-brandOrange-200">
                Payment Details
              </span>
            </div>
            <div className="mb-3 text-lg">
              <p>Payment:</p>
              <p className="text-brandBlack-200 text-sm">
                All transactions are secure and encrypted
              </p>
            </div>
            <div className="w-full border rounded border-brandOrange-200 mb-10">
              <div className="w-full border-b border-brandOrange-200 h-14 px-4 flex items-center justify-between bg-brandOrange-800">
                <div className="flex">
                  <div className="border h-5 w-5 border-brandOrange-200 rounded-full bg-transparent flex justify-center items-center mr-2">
                    <span className="h-3 w-3 bg-brandOrange-200 rounded-full"></span>
                  </div>
                  <p className="text-sm">Credit Card/Debit Card</p>
                </div>
                <div className="flex gap-x-2">
                  <img src={MasterCardIcon} alt="" />
                  <img src={VisaIcon} alt="" />
                </div>
              </div>
              <div className="p-4 w-full text-brandBlack-500">
                <div className="w-full flex gap-x-2">
                  <input
                    type="text"
                    className="border w-full h-12 rounded px-3 border-brandBlack-500"
                    placeholder="First name"
                  />
                  <input
                    type="text"
                    className="border w-full h-12 rounded px-3 border-brandBlack-500"
                    placeholder="Last name"
                  />
                </div>
                <div className="my-4">
                  <input
                    type="text"
                    className="border w-full h-12 rounded px-3 border-brandBlack-500"
                    placeholder="Card number"
                  />
                </div>
                <div className="w-full flex gap-x-2">
                  <input
                    type="text"
                    className="border w-full h-12 rounded px-3 border-brandBlack-500"
                    placeholder="First name"
                  />
                  <input
                    type="text"
                    className="border w-full h-12 rounded px-3 border-brandBlack-500"
                    placeholder="Last name"
                  />
                </div>
              </div>
            </div>
            <div className="w-full h-14 rounded-md border flex px-4 py-2 items-center gap-x-1 border-brandBlack-500">
              <div className="border h-5 w-5 border-brandBlack-200 rounded-full bg-transparent flex justify-center items-center"></div>
              <div></div>
              <div className="flex">
                <p className="text-sm">Paypal</p>
              </div>
            </div>
            <div className="mb-3 mt-6 text-lg">
              <p>Billing Address:</p>
              <p className="mt-1 text-brandBlack-200 text-sm">
                Select the address that matches your card or payment method.
              </p>
            </div>
            <div className="w-full h-14 rounded-md border flex px-4 py-2 items-center justify-between gap-x-2 border-brandOrange-200 bg-brandOrange-800 mb-4">
              <div className="flex items-center gap-x-2 ">
                <div className="border h-5 w-5 border-brandOrange-200 rounded-full bg-transparent flex justify-center items-center">
                  <span className="h-3 w-3 bg-brandOrange-200 rounded-full"></span>
                </div>
                <div className="">
                  <p className="text-sm">Same as delivery address</p>
                </div>
              </div>
            </div>
            <div className="w-full h-14 rounded-md border flex px-4 py-2 items-center border-brandBlack-500 justify-between">
              <div className="flex gap-x-2 items-center">
                <div className="border h-5 w-5 border-brandBlack-200 rounded-full bg-transparent flex justify-center items-center"></div>
                <div className="">
                  <p className="text-sm">Use a different delivery address</p>
                </div>
              </div>
            </div>
            <button className="text-white bg-brandOrange-200 rounded h-10 w-full mt-6">
              Pay now
            </button>
          </div>
        </div>
        <div className="col-span-2 bg-brandBlue-100 pt-16 h-full flex justify-center">
          <div className="w-[410px]">
            <h3 className="text-xl font-medium mb-4">Order Summary</h3>
            <p className="pb-6 border-b border-brandBlack-500 mb-6">1 item</p>
            <div className="flex justify-between border-b pb-6 border-brandBlack-500">
              <div className="flex gap-x-5">
                <img src={book1} alt="" className="h-36" />
                <div className=" flex flex-col justify-between">
                  <div>
                    <p className="font-medium mb-1">Friends Piece</p>
                    <p className="text-brandBlack-200 mb-1">Birthday Card</p>
                    <p className="mb-1">Quantity: 1</p>
                    <p className="mb-1">Size: A4</p>
                  </div>
                  <p className="text-brandOrange-200 text-sm">Preview & Edit</p>
                </div>
              </div>
              <p className="font-medium">₦5,000</p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Subtotal</p>
              <p className="font-medium">₦5,000</p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Delivery cost</p>
              <p className=" text-brandBlack-200">Pending</p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>VAT (7.5%)</p>
              <p className="font-medium">₦375</p>
            </div>
            <div className="flex justify-between w-full mt-4 border-t border-b py-6 border-brandBlack-500">
              <p className="font-medium text-lg">Total</p>
              <p className="font-medium text-xl">₦7,375</p>
            </div>
            <p className="mt-2 text-lg font-medium">Titi's Delivery address</p>
            <p>Block K, 15 Namadi Sambo Street, Magodo, Lagos, NG</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentDetails;
