import React, { useEffect, useState } from "react";
import { IProfile } from "../interfaces";
import { PrimaryInput } from "./PrimaryInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "./Selector";
import { City, Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";

import { ICountry, IState, ICity } from "country-state-city";
import { apiPost } from "../service/useApi";

const EditProfile = ({
  profile,
  setEdit,
}: {
  profile: IProfile;
  setEdit: any;
}) => {
  const [countryData, setCountryData] = useState<any>([]);

  const [countryDataList, setCountryDataList] = useState<any>([]);

  const [stateData, setStateData] = useState<any | any[]>([]);

  const [singleState, setSingleState] = useState<any>();

  const [singleCity, setSingleCity] = useState<any>();

  const [cityData, setCityData] = useState<any | any[]>([]);

  const [loading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [selectedState, setSelectedState] = useState<IState>();
  const [selectedCity, setSelectedCity] = useState("");

  console.log({ profile });

  useEffect(() => {
    const states = State.getStatesOfCountry(selectedCountry?.isoCode);
    setStateData(states);
    console.log("countryData");
  }, [selectedCountry]);

  useEffect(() => {
    const getCountries = () => {
      const country = Country.getAllCountries();
      setCountryData(country);
    };

    getCountries();
  }, []);

  useEffect(() => {
    stateData && setSingleState(stateData[0]);
  }, [stateData]);

  useEffect(() => {
    stateData && setSingleState(stateData[0]);
  }, [selectedState]);

  useEffect(() => {
    const cities = City.getCitiesOfState(
      selectedCountry?.isoCode as string,
      selectedState?.isoCode as string
    );
    setCityData(cities);
    console.log({ cityData });
  }, [selectedState]);

  useEffect(() => {
    cityData && setSingleCity(cityData[0]);
    console.log(singleCity);
  }, [cityData]);

  const schema = yup.object().shape({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    title: yup.string().optional(),
    phoneNumber: yup.string().optional(),
    address: yup.string().optional(),
    cityId: yup.string().nullable(),
    stateId: yup.string().nullable(),
    countryId: yup.string().nullable(),
    emailAddress: yup.string().optional(),
  });

  const {
    register,
    formState: { errors },

    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema), // yup, joi and even your own.
    defaultValues: {
      firstName: profile.profile.firstName,
      lastName: profile.profile.lastName,
      title: profile.profile.title,
      phoneNumber: profile.profile.phoneNumber as unknown as string,
      cityId: profile.profile.cityId,
      stateId: profile.profile.state,
      countryId: profile.profile.countryId,
      emailAddress: profile.profile.emailAddress,
      address: "",
    },
  });
  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    setLoading(true);
    data.customerId = localStorage.getItem("userId") as unknown as string;
    data.countryId = selectedCountry?.isoCode;
    data.stateId = selectedState?.isoCode;
    // console.log(JSON.stringify(data));
    const { isLoaded, data: info } = await apiPost(
      "/api/Customer/Edit",
      "PUT",
      data
    );

    if (isLoaded && info.statusCode == 200) {
      setLoading(false);
      setEdit(false);
    }

    // const res = await fetch(
    //   "https://bluemoonapi-uat.bts.com.ng/api/Customer/Edit",
    //   {
    //     method: "PUT",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // );

    // if (res.ok) {
    //   setLoading(false);
    //   setEdit(false);
    //   const data = await res.json();
    //   //  toast.success(`${data.username} is logged in successfully!`);
    //   // navigate("/profile");
    //   // console.log(data);
    // } else {
    //   setLoading(false);
    //   const data = await res.json();
    //   console.log(res);
    //   //  toast.error(`Sorry, something went wrong, please try again!`);
    // }
  };

  useEffect(() => {
    // console.log(selectedCountry);
  }, [selectedCountry]);

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="flex gap-x-20 flex-wrap"
      autoComplete="off"
    >
      <div className="flex flex-col gap-y-5 text-sm w-72">
        <div>
          <p className="text-brandBlack-200">Title:</p>
          <select
            {...register("title")}
            name="title"
            id=""
            className="w-64 border rounded h-10 mt-1"
          >
            <option value="2">Miss</option>
            <option value="1">Mrs</option>
            <option value="0">Mr</option>
          </select>
        </div>
        <div>
          <p className="text-brandBlack-200">Email Address:</p>
          {/* <input
            type="email"
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
          /> */}
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.emailAddress}
            name="emailAddress"
            register={register}
            disabled={true}
            defaultValue={profile.profile.emailAddress}
            value={profile.profile.emailAddress}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">City:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.cityId}
            name="cityId"
            register={register}
            defaultValue={profile.profile.city}
            value={profile.profile.city}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">First Name:</p>
          {/* <input
            type="text"
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
          /> */}
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.firstName}
            name="firstName"
            register={register}
            defaultValue={profile.profile.firstName}
            value={profile.profile.firstName}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">Phone Number:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.phoneNumber}
            name="phoneNumber"
            register={register}
            defaultValue=""
            value={profile.profile.phoneNumber}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">State:</p>
          {stateData.length > 1 ? (
            <Selector
              data={stateData}
              selected={selectedState}
              setSelected={setSelectedState}
              setSelectedData={setSelectedState}
            />
          ) : (
            <input
              className="border w-64 h-10 border-brandBlack-200 rounded"
              placeholder="Enter state..."
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm w-72">
        <div>
          <p className="text-brandBlack-200">Last Name:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.lastName}
            name="lastName"
            register={register}
            defaultValue={profile.profile.lastName}
            value={profile.profile.lastName}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">Address:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.address}
            name="address"
            register={register}
            defaultValue={""}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">Country:</p>
          <Selector
            data={countryData}
            selected={selectedCountry}
            setSelected={setSelectedCountry}
            setSelectedData={setSelectedCountry}
          />
        </div>
      </div>
      <div className="w-full h-20 flex justify-end items-end">
        <button className="w-36 h-10 rounded bg-brandOrange-200 text-white">
          {loading ? "Updating..." : " Update details"}
        </button>
      </div>
    </form>
  );
};

export default EditProfile;
