import React, { useState } from "react";
import squidGame from "../assets/squidgamecard.png";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { useNavigate } from "react-router-dom";
import { convertDate, numberWithCommas } from "../utils";

const SingleOrderCard = ({
  setShowDetails,
  details,
}: {
  setShowDetails: any;
  details: any;
}) => {
  console.log("orderrr =>", details.orderItemDetails);

  const { orderItemDetails } = details;

  const dateFormat = new Date(details.dateOrdered);

  const formattedDate = convertDate(dateFormat);

  return (
    <div className="border rounded-md p-4 flex justify-between">
      <div className="flex gap-x-5">
        <div className=" relative">
          {orderItemDetails.length > 1 && (
            <>
              <div className="h-full w-full border-4 border-gray-200 absolute top-0 left-0 rotate-3 bg-white"></div>
              <div className="h-full w-full border-4 border-gray-200 absolute top-0 left-0 rotate-6 bg-white"></div>
            </>
          )}
          <img
            src={orderItemDetails[0].imageUrl}
            alt=""
            className="h-36 relative z-10"
          />
        </div>
        <div className="flex flex-col">
          <h4 className="font-medium">{orderItemDetails[0].productName}</h4>
          <p className="text-sm mt-1">
            Card type: {orderItemDetails[0].categoryName}
          </p>
          <p className="text-brandBlack-200 text-sm my-2">
            Order: {details.orderNo}
          </p>
          <div className="py-2 w-20 flex justify-center items-center rounded bg-[#F3FFD9] text-[#8ED100] text-xs">
            {details.statusName}
          </div>
          <p className="text-sm mt-1">On: {formattedDate}</p>
        </div>
      </div>
      <div className="flex flex-col justify-between items-end">
        <p>₦{numberWithCommas(details.totalAmount)}</p>
        <p
          className="text-sm text-brandOrange-200 cursor-pointer"
          onClick={() => {
            setShowDetails(true);
          }}
        >
          See Details
        </p>
      </div>
    </div>
  );
};

export default SingleOrderCard;
