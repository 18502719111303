import React, { useState } from "react";
import squidGame from "../assets/squidgamecard.png";
import { FaChevronUp } from "react-icons/fa";
import RatingModal from "./RatingModal";
import TrackingModal from "./TrackingModal";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { numberWithCommas } from "../utils";
import { useNavigate } from "react-router-dom";

const OrderCardDetails = ({
  showDetails,
  details,
  orderDetails,
}: {
  showDetails: boolean;
  details: any;
  orderDetails: any;
}) => {
  const [showTracking, setShowTracking] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log({ orderDetails });

  const navigate = useNavigate();

  const { orderItemDetails } = orderDetails;

  // const cancelOrder = async () => {
  //   const userId = localStorage.getItem("userId") as unknown as string;
  //   const accessToken = localStorage.getItem(
  //     "accessToken"
  //   ) as unknown as string;

  //   const bodyInput = {
  //     orderId: orderDetails.orderId,
  //     customerId: userId,
  //   };

  //   console.log(bodyInput);
  //   const url = new URL(API_BASE_URL + `/api/Order/CancelOrder`);
  //   try {
  //     const res = await fetch(url, {
  //       method: "POST",
  //       body: JSON.stringify(bodyInput),
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });
  //     const reminder: IResponseObject = await res.json();
  //     if (reminder.statusCode == 200) {
  //       console.log(reminder);
  //       // toast.success(reminder.message);
  //       // closeModal();
  //       navigate(0);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="">
      {showTracking ? (
        <TrackingModal
          setShowTracking={setShowTracking}
          showTracking={showTracking}
          orderId={orderDetails.orderId}
        />
      ) : (
        ""
      )}
      <RatingModal
        setShowRatingModal={setShowRatingModal}
        showRatingModal={showRatingModal}
      />
      <div className="flex gap-x-2 text-sm">
        <p className="text-brandOrange-200">Order history</p>
        <span className="text-brandBlack-200">{">"}</span>
        <p className="text-brandBlack-200">Order details</p>
      </div>
      <p className="text-lg mt-1">Order ID: {orderDetails.orderNo}</p>
      <div className="flex justify-between">
        <div className="flex gap-x-2 mt-1">
          <div className="py-2 w-36 flex justify-center items-center rounded bg-[#F3FFD9] text-[#8ED100] text-xs">
            {orderDetails.statusName}
          </div>
          <p className="text-sm mt-1 text-brandBlack-200">On Jan 31st, 2021</p>
        </div>
        <p
          className="text-sm underline-offset-2 underline text-brandOrange-200 cursor-pointer"
          onClick={() => {
            setShowTracking(true);
          }}
        >
          Track order
        </p>
      </div>

      <div className="border rounded border-brandBlack-500 mt-2 p-4 ">
        {orderItemDetails.map((details: any) => (
          <div className="flex justify-between mb-3">
            <div className="flex gap-x-5">
              <div>
                <img
                  src={details.imageUrl}
                  alt=""
                  className="h-36 w-32 object-cover"
                />
              </div>
              <div>
                <p className="font-medium">{details.productName}</p>
                <p className="text-sm text-brandBlack-200">
                  {details.productName}
                </p>
                <p className="mt-2 text-sm font-medium">
                  Quantity: {details.quantity}
                </p>
                <div className="flex flex-col">
                  {details.options.map((option: any) => (
                    <p className="mt-1 text-sm font-medium">
                      {option.parentProductOptionName}:
                      <span className="text-brandBlack-200 font-normal ml-1">
                        {option.productOptionName}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <p className="font-medium">₦{numberWithCommas(details.amount)}</p>
          </div>
        ))}
        <div>
          <div className="flex justify-between cursor-pointer border-b pb-3 px-1">
            <p className="text-sm">Delivery Details </p>
            <span>
              <FaChevronUp size={14} />
            </span>
          </div>
          {details && (
            <div className="mt-2">
              <div className="text-sm mb-2">
                <p className="text-brandBlack-200">Address</p>
                <p>{details.address}</p>
              </div>
              <div className="text-sm mb-2">
                <p className="text-brandBlack-200">Phone Number</p>
                <p>{details.phoneNumber}</p>
              </div>
              <div className="text-sm mb-2">
                <p className="text-brandBlack-200">Envelope Option</p>
                <p>Send directly to receipient</p>
              </div>
              <div className="text-sm mb-2">
                <p className="text-brandBlack-200">Delivery Option</p>
                <p>Local Delivery</p>
              </div>
              <div className="text-sm mb-2">
                <p className="text-brandBlack-200">Delivery Date</p>
                <p>Jan 16th - Jan 31st 2023</p>
              </div>
            </div>
          )}
        </div>
        <div className="mt-8">
          <div className="flex justify-between cursor-pointer border-b pb-3 px-1">
            <p className="text-sm">Payment Details </p>
            <span>
              <FaChevronUp size={14} />
            </span>
          </div>
          <div className="mt-2">
            <div className="text-sm mb-2">
              <p className="text-brandBlack-200">Payment method</p>
              <p>Credit/Debit Card</p>
            </div>
            <div className="text-sm mb-2">
              <p className="text-brandBlack-200">Payment Details</p>
              <div>
                <div className="flex justify-between mb-1">
                  <p>Item total:</p>
                  <p> ₦{numberWithCommas(orderDetails.totalAmount)}</p>
                </div>
                <div className="flex justify-between mb-1">
                  <p>Delivery cost:</p>
                  <p>₦{orderDetails.deliveryCost}</p>
                </div>
                <div className="flex justify-between mb-1">
                  <p>VAT(7.5%):</p>
                  <p>₦{orderDetails.vat}</p>
                </div>
                <div className="flex justify-between mb-1">
                  <p>Total cost:</p>
                  <p className="font-medium text-xl">
                    ₦{numberWithCommas(orderDetails.totalAmount)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-center gap-x-4">
              <button className="w-[160px] h-[36px] rounded border border-brandBlack-500 bg-transparent text-sm text-brandBlack-500 cursor-pointer">
                Cancel Order
              </button>
              <button
                className="w-[160px] h-[36px] rounded text-brandOrange-200 border  bg-transparent text-sm border-brandOrange-200 cursor-pointer"
                onClick={() => setShowRatingModal(true)}
              >
                Rate Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCardDetails;
