import React, { useEffect, useState } from "react";
import Card from "./Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductsCard from "./ProductsCard";
import TemplateCard from "./TemplateCard";

const TemplateSlider = ({ slides }: { slides: any[] }) => {
  const [progress, setProgress] = useState(0);
  const [slideToShow, setSlideToShow] = useState(5);

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        },
      },
      // {
      //   breakpoint: 550,
      //   settings: {
      //     slidesToShow: 1,
      //   },
      // },
    ],
    afterChange: (current: any) => {
      setProgress((100 / (slides.length - slideToShow + 1)) * (current + 1));
    },
  };

  const setSlides = () => {
    if (window.innerWidth <= 1280 && window.innerWidth > 1000) {
      setSlideToShow(4)
    }else if (window.innerWidth <= 1000 && window.innerWidth > 650)  {
      setSlideToShow(3)
    } else if (window.innerWidth <= 650) {
      setSlideToShow(2);
    }
  }

  useEffect(() => {
    setSlides()
  }, [])
  

  return (
    <div className="relative">
      <Slider {...settings}>
        {slides.map((el: any, i: number) => (
          <TemplateCard key={i} slide={el} />
        ))}
      </Slider>
      <div className="h-2 bg-gray-300 w-full relative mt-10 rounded-3xl">
        <div
          className="bg-brandOrange-200 absolute h-full transition-all rounded-3xl"
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default TemplateSlider;
