import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

const Accordion = ({title, text}: {title: string; text: string}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="my-3 py-3 border-b">
      <div
        className="w-full justify-between items-center h-full flex cursor-pointer"
        onClick={() => setToggle(!toggle)}
      >
        <p className="font-medium text-xl">{title}</p>
        <span>
          <BsChevronDown size={24} />
        </span>
      </div>
      {toggle ? (
        <div className="mt-2 py-4 ease-in transition-all text-brandBlack-200">
         {text}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Accordion;
