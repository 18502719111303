import "../App.css";
import brush from "../assets/brush-img.png";
import Designer from "../assets/designer-img.png";
import selectIcon from "../assets/select-icon.png";
import Discover1 from "../assets/discover-img1.png";
import Discover2 from "../assets/discover-img2.png";
import customizeIcon from "../assets/customize.png";
import paymentIcon from "../assets/payment-icon.png";
import deliveryIcon from "../assets/delivery-icon.png";
import { FaArrowRight } from "react-icons/fa6";
import ProgressSlider from "../components/ProgressSlider";
import { useEffect, useState } from "react";
import snowman from "../assets/snowmancard.png";
import december from "../assets/christmascard.png";
import squidGame from "../assets/squidgamecard.png";
import majesty from "../assets/majestycard.png";
import book1 from "../assets/book-image-1.png";
import book2 from "../assets/book-image2.png";
import book3 from "../assets/book-image-3.png";
import book4 from "../assets/book-image4.png";
import Template from "../components/Template";
import { IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import ProductsSlider from "../components/ProductsSlider";
import { API_BASE_URL } from "..";
import CategorySlider from "../components/CategorySlider";
import TemplateSlider from "../components/TemplateSlider";
import { Toaster } from "sonner";
import { getCookie, setCookie } from "typescript-cookie";
import { Link } from "react-router-dom";
import { apiFetch } from "../service/useApi";

export default function Home() {
  const [categoriesList, setCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState<any>();
  const [birthdayCardsList, setBirthdayCardsList] = useState<any>();
  const [anniversaryCards, setAnniversaryCards] = useState<any>();
  const [templateList, setTemplateList] = useState<any>([]);

  const photoBooks = [
    {
      img: book1,
      rating: 3,
      title: "Friends piece",
    },
    {
      img: book2,
      rating: 1,
      title: "Family portrait",
    },
    {
      img: book3,
      rating: 5,
      title: "Pet Portrait",
    },
    {
      img: book4,
      rating: 2,
      title: "Graduation Photo",
    },
    {
      img: squidGame,
      rating: 4,
      title: "Squid game card",
    },
    {
      img: majesty,
      rating: 3,
      title: "Her majesty card",
    },
  ];
  const birthdayCards = [
    {
      img: snowman,
      rating: 3,
      title: "Snow man",
    },
    {
      img: december,
      rating: 2,
      title: "Month Birthday",
    },
    {
      img: squidGame,
      rating: 3,
      title: "Squid game card",
    },
    {
      img: majesty,
      rating: 3,
      title: "Her majesty card",
    },
    {
      img: squidGame,
      rating: 3,
      title: "Squid game card",
    },
    {
      img: majesty,
      rating: 3,
      title: "Her majesty card",
    },
  ];

  const [brushWidth, setBrushWidth] = useState(133);

  const changeBrushWidth = () => {
    if (window.innerWidth > 1000) {
      setBrushWidth(333);
    } else if (window.innerWidth <= 1000 && window.innerWidth > 650) {
      setBrushWidth(233);
    } else if (window.innerWidth <= 650) {
      setBrushWidth(133);
    }
  };

  useEffect(() => {
    changeBrushWidth();
    const getTheme = async () => {
      const { data, isLoaded } = await apiFetch("/api/Storefront/Get", "GET");
      if (isLoaded && data.statusCode == 200) {
        localStorage.setItem("tenantId", data.data.tenantId);
        localStorage.setItem("storeFrontId", data.data.storeFrontId);
        localStorage.setItem("logo", data.data.logo);
        localStorage.setItem("storeTitle", data.data.storeFrontName);
        localStorage.setItem("socials", JSON.stringify(data.data.socials));
        await getCategories();
      }
    };
    const getCategories = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/Category/GetCategories",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setCategoriesList(data.data);
        localStorage.setItem("categoriesList", JSON.stringify(data.data));
      }
    };

    const getCategoryProducts = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/Category/GetCategoryProducts?Slug=Phoot_Books",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setProductsList(data.data);
      }
    };

    const getCategoryProducts2 = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/Category/GetCategoryProducts?Slug=Birthday_Cards",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setBirthdayCardsList(data.data);
      }
    };

    const getCategoryProducts3 = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/Category/GetCategoryProducts?Slug=Anniversary_Cards",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setAnniversaryCards(data.data);
      }
    };

    const getTemplate = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/DesignTemplate/Get",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setTemplateList(data.data);
      }
    };

    getTheme();
    getCategoryProducts();
    getCategoryProducts2();
    getCategoryProducts3();
    getTemplate();
  }, []);
  console.log(productsList);

  if (categoriesList.length < 1) {
    return <Loading />;
  }
  return (
    <Template>
      <Toaster richColors position="top-right" />
      <main className="w-full">
        <div></div>
        <div className="sm:h-[466px] w-full hero  flex flex-col text-left sm:items-start lg:items-end justify-center px-2 text-white relative sm:px-5 lg:px-20">
          <div className="overlay w-full h-full  absolute left-0"></div>
          <p className="text-2xl font-medium sm:text-white lg:text-black sm:w-[308px] lg:w-[589px] relative z-10 lg:text-[40px] leading-10">
            Discover a world of cards that express every sentiment, celebrate
            every occasion, and speak to every heart.
          </p>
          <p className="sm:text-white lg:text-brandBlack-300 sm:w-[308px] lg:w-[589px] sm:mt-3 lg:mt-5 mb-5 relative z-10 ">
            Whether you're searching for a birthday surprise, a heartfelt thank
            you, or a festive greeting, Fotologue offers an extensive collection
            to suit every need.
          </p>
          <button className="px-8 h-12 rounded-lg bg-brandOrange-200 text-white relative z-10">
            {" "}
            SHOP NOW
          </button>
        </div>
        <div className="mt-10 sm:px-5 lg:px-20">
          <p className="font-medium text-2xl mb-10">Our Products</p>
          <CategorySlider slides={categoriesList} />
        </div>
        <div className="bg-brandBlue-100 mt-10 relative sm:px-5 lg:px-20 py-10">
          <div className="absolute right-0 top-0">
            <img src={brush} alt="Brush img" width={brushWidth} />
          </div>
          <h2 className="sm:w-[300px] lg:w-[620px] sm:text-2xl lg:text-[40px] font-medium leading-10">
            Customize your digital artworks to your taste.
          </h2>
          <p className="sm:mt-2 lg:mt-8 lg:text-xl">
            Here are a few steps to help you get started.
          </p>
          <div className="sm:mt-6 lg:mt-12 lg:flex gap-x-8">
            <div className="mb-6">
              <span className="h-5 border-l-4 pl-2 border-l-brandOrange-100 mb-3">
                Step 1.
              </span>
              <div className="w-10 h-10 rounded-lg bg-brandOrange-300 items-center justify-center flex mt-3 mb-2">
                <img src={selectIcon} alt="choose template icon" />
              </div>
              <h5 className="my-2 text-lg font-medium">Choose a template</h5>
              <p className="text-sm">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo con
              </p>
            </div>
            <div className="mb-6">
              <span className="h-5 border-l-4 pl-2 border-l-brandOrange-100 mb-3">
                Step 2.
              </span>
              <div className="w-10 h-10 rounded-lg bg-brandOrange-300 items-center justify-center flex mt-3 mb-2">
                <img src={customizeIcon} alt="choose template icon" />
              </div>
              <h5 className="my-2 text-lg font-medium">Personalize template</h5>
              <p className="text-sm">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo con
              </p>
            </div>
            <div className="mb-6">
              <span className="h-5 border-l-4 pl-2 border-l-brandOrange-100 mb-3">
                Step 3.
              </span>
              <div className="w-10 h-10 rounded-lg bg-brandOrange-300 items-center justify-center flex mt-3 mb-2">
                <img src={paymentIcon} alt="choose template icon" />
              </div>
              <h5 className="my-2 text-lg font-medium">
                Make payment seamlessly
              </h5>
              <p className="text-sm">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo con
              </p>
            </div>
            <div className="mb-6">
              <span className="h-5 border-l-4 pl-2 border-l-brandOrange-100 mb-3">
                Step 4.
              </span>
              <div className="w-10 h-10 rounded-lg bg-brandOrange-300 items-center justify-center flex mt-3 mb-2">
                <img src={deliveryIcon} alt="choose template icon" />
              </div>
              <h5 className="my-2 text-lg font-medium">Choose a template</h5>
              <p className="text-sm">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo con
              </p>
            </div>
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-white">
          <div className="flex justify-between mb-10">
            <p className="font-medium text-2xl">Photo books for you</p>
            <Link to={`/products?query=Photo`}>
              <span className="flex items-center">
                <p className="mr-1">View all</p>
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="slider_section">
            {productsList && <ProductsSlider slides={productsList.products} />}
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-brandBlue-100">
          <div className="flex justify-between mb-10">
            <p className="font-medium text-2xl">Top reviewed templates</p>
            <span className="flex items-center">
              <p className="mr-1">View all</p>
              <FaArrowRight />
            </span>
          </div>
          <div className="slider_section">
            <TemplateSlider slides={templateList} />
          </div>
        </div>
        <div className="sm:hidden min-h-[405px] sm:px-5 lg:px-20 py-10 bg-white flex items-center justify-center gap-x-20">
          <div>
            <img src={Designer} alt="become a designer" />
          </div>
          <div>
            <p className=" font-medium text-[40px]">Become a designer</p>
            <p className="my-4 text-2xl">
              Register as a designer and sell your templates.
            </p>
            <button className="rounded-md h-12 bg-brandOrange-200 text-white px-4">
              Register as a designer
            </button>
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-brandBlue-100">
          <div className="flex justify-between mb-10">
            <p className="font-medium text-2xl">Birthday cards for you</p>
            <Link to={`/products?query=Birthday`}>
              <span className="flex items-center">
                <p className="mr-1">View all</p>
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="slider_section">
            {birthdayCardsList && (
              <ProductsSlider slides={birthdayCardsList.products} />
            )}
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-white">
          <div className="flex justify-between mb-10">
            <p className="font-medium text-2xl">Anniversary cards for you</p>
            <Link to={`/products?query=Anniversary`}>
              <span className="flex items-center">
                <p className="mr-1">View all</p>
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="slider_section">
            {anniversaryCards && (
              <ProductsSlider slides={anniversaryCards.products} />
            )}
          </div>
        </div>
        <div className="sm:min-h-[405px] sm:px-5 lg:px-20 py-10 bg-brandBlue-100 pb-40">
          <div className="mb-10">
            <p className="font-medium text-[40px] text-center">
              Discover More at Fotologue
            </p>
            <p className="text-xl mt-4 text-center">
              With loads of designs, sizes, and themes to choose from, we’ve
              spoiled you for choice here at Fotologue. Go on, get creative!
            </p>
          </div>
          <div className="lg:flex gap-x-4 justify-center sm:hidden">
            <div className="relative h-80 rounded-md  ">
              <img src={Discover1} alt="Discover1" />
              <div className="w-72 h-36 rounded-md shadow-md p-4 bg-white absolute left-4 -bottom-1/2 -translate-y-1/2">
                <p className="text-xl font-medium">Send Cards Instantly</p>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing eli
                </p>
                <p className="mt-4 text-brandOrange-200 flex items-center">
                  Shop eCards{" "}
                  <span>
                    <FaArrowRight />
                  </span>
                </p>
              </div>
            </div>
            <div className="relative h-80 rounded-md  ">
              <img src={Discover2} alt="Discover1" />
              <div className="w-72 h-36 rounded-md shadow-md p-4 bg-white absolute left-4 -bottom-1/2 -translate-y-1/2">
                <p className="text-xl font-medium">Send Cards Instantly</p>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing eli
                </p>
                <p className="mt-4 text-brandOrange-200 flex items-center">
                  Shop eCards{" "}
                  <span>
                    <FaArrowRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-20 flex flex-col items-center text-center">
          <p className="font-medium text-[40px]">Stay up to date with us</p>
          <p className="mt-2 text-xl">
            Get 15% off your order when you sign up to our newsletter
          </p>
          <div className="mt-8">
            <input
              type="text"
              className="h-12 rounded-lg border border-brandBlack-200 w-[433px] px-2"
              placeholder="Enter email address"
            />
            <button className="h-12 w-[135px] rounded-md ml-4 font-medium text-white bg-brandOrange-200">
              Subscribe
            </button>
          </div>
          <div className="text-xs mt-2">
            <p>
              By subscribing, you agree to receive marketing emails from
              Fotologue. We respect your privacy and take
            </p>
            <p>
              protecting it seriously. Read our{" "}
              <span className="underline mr-1">Privacy Policy</span>
              and
              <span className="underline ml-1">Terms of Service</span>
            </p>
          </div>
        </div>
      </main>
    </Template>
  );
}
