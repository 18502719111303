import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useContext, useState } from "react";
import {
  FiX,
  FiAlignLeft,
  FiAlignRight,
  FiAlignCenter,
  FiBold,
  FiUnderline,
  FiMinus,
  FiPlus,
} from "react-icons/fi";
import {
  FaArrowRight,
  FaArrowRotateLeft,
  FaArrowRotateRight,
} from "react-icons/fa6";
import { Button } from "flowbite-react";
import CustomizationContext from "../../CustomizationContext";

const PhotoTab = ({ hideView }: { hideView: () => void }) => {
  const fonts = [
    { name: "Poppings" },
    { name: "Arial" },
    { name: "Helvetica" },
    { name: "Sans serif" },
    { name: "Papyrus" },
  ];

  const fontSizes = [
    { size: "12" },
    { size: "14" },
    { size: "16" },
    { size: "18" },
    { size: "20" },
  ];
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [selectedFontSize, setSelectedFontSize] = useState(fontSizes[0]);
  const [loading, setLoading] = useState(true);

  const { handleResizeImage, handleRotateImage, handleEnhanceImage } =
    useContext(CustomizationContext);

        // {
        //   selectedObject && selectedObject.type === "image" && (
        //     <div>
        //       <label>Resize Image</label>
        //       <input
        //         type="range"
        //         min="0.1"
        //         max="2"
        //         step="0.1"
        //         defaultValue="1"
        //         onChange={(e) => handleResizeImage(parseFloat(e.target.value))}
        //       />
        //       <button onClick={() => handleRotateImage(-10)}>
        //         Rotate Left
        //       </button>
        //       <button onClick={() => handleRotateImage(10)}>
        //         Rotate Right
        //       </button>
        //       <div>
        //         <label>
        //           Brightness:
        //           <input
        //             type="range"
        //             min="0"
        //             max="2"
        //             step="0.01"
        //             defaultValue="1"
        //             onChange={(e) =>
        //               handleEnhanceImage(
        //                 "brightness",
        //                 parseFloat(e.target.value)
        //               )
        //             }
        //           />
        //         </label>
        //         <label>
        //           Contrast:
        //           <input
        //             type="range"
        //             min="0"
        //             max="2"
        //             step="0.01"
        //             defaultValue="1"
        //             onChange={(e) =>
        //               handleEnhanceImage("contrast", parseFloat(e.target.value))
        //             }
        //           />
        //         </label>
        //         <label>
        //           Saturation:
        //           <input
        //             type="range"
        //             min="0"
        //             max="2"
        //             step="0.01"
        //             defaultValue="1"
        //             onChange={(e) =>
        //               handleEnhanceImage(
        //                 "saturation",
        //                 parseFloat(e.target.value)
        //               )
        //             }
        //           />
        //         </label>
        //         <label>
        //           Sepia:
        //           <input
        //             type="range"
        //             min="0"
        //             max="1"
        //             step="0.01"
        //             defaultValue="0"
        //             onChange={(e) =>
        //               handleEnhanceImage("sepia", parseFloat(e.target.value))
        //             }
        //           />
        //         </label>
        //         <label>
        //           Hue:
        //           <input
        //             type="range"
        //             min="0"
        //             max="360"
        //             step="1"
        //             defaultValue="0"
        //             onChange={(e) =>
        //               handleEnhanceImage("hue", parseInt(e.target.value))
        //             }
        //           />
        //         </label>
        //       </div>
        //       <button onClick={handleEnhanceImage}>Enhance</button>
        //     </div>
        //   );
        // }

  return (
    <div className=" h-full w-[400px] bg-white border-r p-4">
      <div
        className="w-full flex justify-end my-10 items-center cursor-pointer"
        onClick={hideView}
      >
        <FiX size={22} />
      </div>
      <div className="flex w-full  flex-col justify-center gap-y-6">
        <p className="font-medium text-lg text-center">Your Photos</p>

        <div className="border-t border-brandBlack-500 py-4">
          <p className="text-xl font-medium">Edit Photo</p>

          <div className="mt-5">
            <label
              htmlFor="default-range"
              className="block font-medium text-black-300 "
            >
              Size
            </label>
            <input
              id="default-range"
              type="range"
              min="0.1"
              max="2"
              step="0.1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-slider-input"
              onChange={(e) => handleResizeImage(parseFloat(e.target.value))}
            />
          </div>
          <div className="mt-4">
            <p className="mb-1">Rotate</p>
            <div className="flex gap-x-3 flex-wrap">
              <button
                onClick={() => handleRotateImage(-10)}
                className="border-brandOrange-200 border bg-transparent text-brandOrange-200 h-8 items-center flex  gap-1 rounded w-5/12 justify-center py-2"
              >
                Rotate left
                <FaArrowRotateLeft />
              </button>
              <button
                onClick={() => handleRotateImage(10)}
                className="border-brandOrange-200 border bg-transparent text-brandOrange-200 h-8 items-center flex  gap-1 rounded w-5/12 justify-center py-2"
              >
                Rotate right
                <FaArrowRotateRight />
              </button>
            </div>
            <div className="">
              <p className="mt-4 mb-1">Effects</p>
              <button className="border-brandOrange-200 border bg-transparent text-brandOrange-200 h-8 items-center flex  gap-1 rounded w-5/12 justify-center py-2 ">
                Enhance
                <FaArrowRight />
              </button>
            </div>
            <div className="flex flex-col gap-y-4 mt-4">
               <label className="">
                   Brightness:
                  <input
                    type="range"
                    min="0"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                    onChange={(e) =>
                      handleEnhanceImage(
                        "brightness",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </label>
                <label>
                  Contrast:
                  <input
                    type="range"
                    min="0"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                    onChange={(e) =>
                      handleEnhanceImage("contrast", parseFloat(e.target.value))
                    }
                  />
                </label>
                <label>
                  Saturation:
                  <input
                    type="range"
                    min="0"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                    onChange={(e) =>
                      handleEnhanceImage(
                        "saturation",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </label>
                <label>
                  Sepia:
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    defaultValue="0"
                    onChange={(e) =>
                      handleEnhanceImage("sepia", parseFloat(e.target.value))
                    }
                  />
                </label>
                <label>
                  Hue:
                  <input
                    type="range"
                    min="0"
                    max="360"
                    step="1"
                    defaultValue="0"
                    onChange={(e) =>
                      handleEnhanceImage("hue", parseInt(e.target.value))
                    }
                  />
                </label>
            </div>
          </div>
        </div>
        <button className="w-full h-9 rounded bg-brandOrange-200 text-white font-medium">
          Done
        </button>
      </div>
    </div>
  );
};

export default PhotoTab;
