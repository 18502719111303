import React from "react";
import { IoStarSharp } from "react-icons/io5";

const ReviewCard = ({ slide }: { slide: any }) => {
  return (
    <div className="w-[310px] h-[121px] flex flex-col">
      <div className="flex mb-1">
        <p className="text-sm text-darkBlue-200 mr-1">Tobi</p>
        <IoStarSharp color="#F15B2B" />
        <IoStarSharp color="#F15B2B" />
        <IoStarSharp color="#DEDEDE" />
        <IoStarSharp color="#DEDEDE" />
        <IoStarSharp color="#DEDEDE" />
      </div>
      <div>
        <p className="font-medium truncate mb-1">
          Very easy to design a great card…
        </p>
        <p>
          Very easy to design a great card and deliver it on time very happy
          indeed.
        </p>
        <span className="text-xs text-brandBlack-200">3 hours ago</span>
      </div>
    </div>
  );
};

export default ReviewCard;
