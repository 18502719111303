import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";

interface ISlide {
  title: string;
  img: any;
  rating: number;
}

const Card = ({ slide }: { slide: ISlide }) => {
  return (
    <div className="sm:w-[145px] lg:w-[245px] rounded-lg lg:h-[400px] sm:h-[250px] flex flex-col">
      <div className="sm:h-[165px] lg:h-[336px] rounded-lg">
        <img src={slide.img} alt="template" className="w-full object-cover" />
      </div>
      <div className="flex items-center justify-between sm:h-9 lg:h-11">
        <div className="mt-10">
          <p className="mb-2">{slide.title}</p>
          {slide.rating ? (
            <div className="flex">
              <IoStarSharp color="#F15B2B" />
              <IoStarSharp color="#F15B2B" />
              <IoStarSharp color="#DEDEDE" />
              <IoStarSharp color="#DEDEDE" />
              <IoStarSharp color="#DEDEDE" />
            </div>
          ) : (
            ""
          )}
        </div>
        {slide.rating ? (
          <div>
            <FaRegHeart color="#F89521" size={22} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Card;
