import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/logo-lg.png";
import { FaRegHeart } from "react-icons/fa";
import { FiShoppingBag, FiX } from "react-icons/fi";
import { LuUser, LuSearch } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import MyContext from "../SearchContext";

const HeaderComponent = ({ categories, logo }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();

  const { query, setQuery } = useContext(MyContext);
  useEffect(() => {
    localStorage.getItem("accessToken")
      ? setIsLoggedIn(true)
      : setIsLoggedIn(false);
  }, []);

  // useEffect(() => {
  //   console.log("in effect", typeof(query));
  // }, [query]);

  console.log(categories);
  return (
    <div className="shadow-lg pb-2">
      <div className="w-full h-12 text-center text-xs bg-brandOrange-100 text-white flex flex-col justify-center items-center font-medium">
        <p>Order now & we&apos;ll post it today </p>
        <p>1st class, Express & Guaranteed Tracked options available.</p>
      </div>
      <div className="mt-4 flex gap-x-10 sm:px-5 lg:px-20 justify-between items-center ">
        <div className="flex items-center">
          <span className="lg:hidden">
            <RxHamburgerMenu size={"24px"} />
          </span>
          <span className="sm:hidden lg:block">
            <Link to={"/"}>
              <span className="ml-2">
                <img src={logo} alt="logo-img" width={100} />
              </span>
            </Link>
          </span>
        </div>
        <div className="h-12 border w-full border-brandBlack-200 rounded-md items-center px-2 sm:hidden lg:flex">
          <div className="mx-4">
            <LuSearch />
          </div>
          <input
            type="text"
            className=" flex-1 focus:outline-0 outline-0 rounded-lg border-0 focus:ring-0"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          {query && (
            <span className="cursor-pointer" onClick={() => setQuery("")}>
              <FiX size={22} />
            </span>
          )}
        </div>
        {isLoggedIn ? (
          <div className="flex">
            <span>
              <Link to={"/favorites"}>
                <FaRegHeart size={"24px"} />
              </Link>
            </span>
            <span className="sm:mx-4 lg:mx-8">
              <Link to={"/profile"}>
                <LuUser size={"24px"} />
              </Link>
            </span>
            <span>
              <Link to={"/cart"}>
                <FiShoppingBag size={"24px"} />
              </Link>
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="gap-x-8 sm:hidden lg:flex">
          <button className="border rounded-lg w-[132px] h-12 text-brandOrange-200 bg-brandOrange-700 border-brandOrange-200">
            Sell your design
          </button>
          {isLoggedIn ? (
            ""
          ) : (
            <Link to={"/login"}>
              <button className="border rounded-lg w-[80px] h-12 text-white bg-brandOrange-200 border-brandOrange-200">
                {" "}
                Sign in
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className="px-20 justify-between my-5 sm:hidden lg:flex">
        {categories ? (
          categories.map((category: any, i: number) => (
            <Link to={`/category/${category.slug}`}>
              <p key={i}>{category.name}</p>
            </Link>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="mt-4 px-5 mb-4 lg:hidden">
        <div className="h-12 border-2 w-full border-brandBlack-200 rounded-md flex items-center px-2">
          <div>
            <LuSearch />
          </div>
          <input
            type="text"
            className="ml-2 flex-1 focus:outline-0"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
