import React from "react";
import FacebookIcon from "../assets/facebookicon.png";
import VisaIcon from "../assets/Visa logo.png";
import MasterCardIcon from "../assets/MasterCard logo.png";
import PayPalIcon from "../assets/paypal.png";
import TwitchIcon from "../assets/twitch.png";
import TwitterIcon from "../assets/twitter.png";
import LinkedInIcon from "../assets/linkedin.png";
// import LogoIcon from "../assets/logo-lg.png";
import { Link } from "react-router-dom";

const Footer = ({ categories, logo, socials }: any) => {
  console.log(socials);
  const faceBook =
    socials && socials.filter((social: any) => social.typeName == "Facebook");
  const twitter =
    socials && socials.filter((social: any) => social.typeName == "Twitter");
  const instagram =
    socials && socials.filter((social: any) => social.typeName == "Instagram");
  return (
    <div className="bg-brandBlue-100 w-full  flex flex-col mt-20 border-t-brandBlack-400 border-t">
      <div className="py-20 flex justify-center items-center">
        <div className="w-full h-full sm:px-10 lg:px-20 grid grid-cols-12 ">
          <div className="col-span-3 xs:hidden lg:flex justify-center items-center ">
            <img src={logo} alt="Logo" className="w-96" />
          </div>
          <div className="col-span-9 flex justify-around flex-wrap">
            <div className="flex flex-col">
              <p className="font-medium mb-4">Product Catalog</p>
              {categories ? (
                categories.map((category: any, i: number) => (
                  <Link to={`/category/${category.slug}`}>
                    <p key={i} className="text-sm mb-4">
                      {category.name}
                    </p>
                  </Link>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="flex flex-col">
              <p className="font-medium mb-4">Company</p>
              <Link to={"/register"}>
                <p className="text-sm mb-4">Create account</p>
              </Link>
              <Link to={"/login"}>
                <p className="text-sm mb-4">Sign in</p>
              </Link>
            </div>
            <div className="flex flex-col"></div>
            <div>
              <p className="font-medium mb-4">Keep in touch</p>
              <div className="mb-8 flex gap-x-4">
                <span>
                  <a href={`${faceBook && faceBook[0]?.url}`} target="_blank">
                    <img src={FacebookIcon} alt="facebookIcon" width={13} />
                  </a>
                </span>
                <span>
                  <a href={`${twitter && twitter[0]?.url}`} target="_blank">
                    <img src={TwitchIcon} alt="TwitchIcon" width={22} />
                  </a>
                </span>
                <span>
                  <a href={`${instagram && instagram[0]?.url}`} target="_blank">
                    <img src={TwitterIcon} alt="TwitterIcon" width={22} />
                  </a>
                </span>
                <span>
                  <img src={LinkedInIcon} alt="LinkedInIcon" width={22} />
                </span>
              </div>
              <div>
                <p className="font-medium mb-4">Payment methods</p>
                <div className="flex items-center gap-x-4">
                  <span>
                    {" "}
                    <img src={MasterCardIcon} alt="MasterCardIcon" />
                  </span>
                  <span>
                    <img src={VisaIcon} alt="VisaIcon" />
                  </span>
                  <span>
                    <img src={PayPalIcon} alt="PayPalIcon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-brandBlack-400 h-20"></div>
    </div>
  );
};

export default Footer;
