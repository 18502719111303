import React from "react";
import { FieldError, UseFormRegister, Path } from "react-hook-form";

interface FormInputProps<TFormValues extends Record<string, unknown>> {
  name: Path<TFormValues>;
  placeholder?: string;
  label?: string;
  register: UseFormRegister<TFormValues>;
  error: FieldError | undefined;
  type?: string;
  required?: boolean;
  disableLabel?: boolean;
  disabled?: boolean;
  validate?: Record<string, unknown>;
  icon?: JSX.Element;
  variant?: string;
  borderColor?: string;
  borderRadius?: string;
  placeholderColor?: string;
  defaultValue: string | number | undefined;
  format?: string;
  value?: string | number | undefined;
  testId?: string;
  w?: string;
  padding?: string;
  className?: string;
}

export const PrimaryInput = <TFormValues extends Record<string, any>>({
  name,
  type = "text",
  label = "",
  register,
  error,
  placeholder = "",
  required = false,
  validate = undefined,
  className,
  testId,
  icon,
  disabled = false,
}: FormInputProps<TFormValues>): JSX.Element => {
  return (
    <>
      <label className="text-brandBlack-100 text-sm" htmlFor={name}>
        {label}
      </label>
      <div>
        <input
          autoComplete="off"
          id={name}
          placeholder={placeholder}
          {...register(name, { required, ...validate })}
          type={type}
          name={name}
          data-testid={testId}
          disabled={disabled}
          className={`${className} ${disabled ? "cursor-not-allowed" : ""}`}
          style={{ borderColor: `${error ? "red" : ""}` }}
        />
      </div>
      <p className="text-brandRed-100 text-sm mt-1">{error?.message}</p>
    </>
  );
};
