import React, { Fragment, useContext, useEffect, useState } from "react";
import Template from "../components/Template";
import majesty from "../assets/majestycard.png";
import Card from "../components/Card";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { LuSlidersHorizontal } from "react-icons/lu";
import FilterAccordion from "../components/FilterAccordion";
import { TfiArrowLeft, TfiArrowRight } from "react-icons/tfi";
import MessangerModal from "../components/Messanger";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import ProductsCard from "../components/ProductsCard";
import Loading from "../components/Loading";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import MyContext from "../SearchContext";
import { useLocation } from "react-router-dom";
import { apiFetch } from "../service/useApi";

const Products = () => {
  const { query, setQuery } = useContext(MyContext);

  const [products, setProducts] = useState<any | []>([]);
  const [loading, setLoading] = useState(false);
  const [sorted, setSorted] = useState<any | []>([]);
  const [showSorted, setShowSorted] = useState<boolean>(false);

  const url = useLocation();
  useEffect(() => {
    console.log(url.search.split("=")[1]);
    setQuery(url.search.split("=")[1]);
  }, []);

  const getCategoryProducts2 = async () => {
    const { data, isLoaded } = await apiFetch(
      `/api/Product/Search?query=${query}&sortOrder=des&sortBy=Name&page=1&pageSize=15`,
      "GET"
    );
    if (isLoaded && data.statusCode == 200) {
      setProducts(products);
      setSorted(true);
    }
  };

  // const getCategoryProducts2 = async () => {
  //   setLoading(true);
  //   const userId = localStorage.getItem("userId") as unknown as string;
  //   const storeFrontId = localStorage.getItem(
  //     "storeFrontId"
  //   ) as unknown as string;
  //   const url = new URL(
  //     API_BASE_URL +
  //       `/api/Product/Search?storefrontId=${storeFrontId}&query=${query}&sortOrder=des&sortBy=Name&page=1&pageSize=15`
  //   );
  //   try {
  //     const res = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "content-type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "POST, GET, PUT",
  //         "Access-Control-Allow-Headers": "Content-Type",
  //       },
  //     });
  //     const products = await res.json();
  //     console.log(products);
  //     if (products) {
  //       console.log({ products });
  //       setProducts(products);
  //       setSorted(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    setQuery(url.search.split("=")[1]);
    getCategoryProducts2();
    console.log({ query });
  }, []);

  useEffect(() => {
    getCategoryProducts2();
    console.log({ query });
  }, [query]);

  const links = ["Name", "Description"];

  const sortFn = async (field: string) => {
    const { data, isLoaded } = await apiFetch(
      `/api/Product/Search?query=${query}&sortBy=${field}`,
      "GET"
    );
    if (isLoaded && data.statusCode == 200) {
      setSorted(data);
    }

    // const api = `https://bluemoonapi-uat.bts.com.ng/api/Product/Search?query=${query}&sortBy=${field}`;
    // try {
    //   const res = await fetch(api, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "POST, GET, PUT",
    //       "Access-Control-Allow-Headers": "Content-Type",
    //     },
    //   });
    //   console.log(res);
    //   setSorted(res);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // if (products && products.length < 1) {
  //   return <Loading />;
  // }

  return (
    <Template>
      <span className="flex items-center gap-x-4 my-10 px-20">
        <p className="text-brandOrange-200 underline underline-offset-8">
          Home
        </p>
        <BiChevronRight size={20} />
        <p className="text-brandOrange-200 underline underline-offset-8">
          Products
        </p>
        {/* <BiChevronRight size={20} />
        <p className="text-brandOrange-200 underline underline-offset-8">
          All Birthday Cards
        </p> */}
      </span>
      <div className="w-full bg-[#1F293F] h-16 px-20 flex justify-between items-center text-white font-medium text-2xl my-10">
        <p>We are currently running a 24 hours 30% off promo on all cards</p>
        <p></p>
      </div>
      <div className="flex justify-center flex-col items-center text-center px-40 my-10">
        <p className="font-medium text-3xl">
          Express Your Emotions: Explore Our Card Collection
        </p>
        <p className="text-sm text-brandBlack-200">
          Discover a treasure trove of cards that beautifully express every
          sentiment and occasion. Whether you’re celebrating a birthday, sending
          warm wishes, or simply saying ‘thank you’, our curated collection
          ensures you find the perfect card to convey your heartfelt message.
          Browse through our diverse range of designs, from whimsical and
          heartfelt to elegant and timeless, and make every moment memorable
        </p>
      </div>
      <MessangerModal />

      <div className="w-full grid grid-cols-7 px-20 py-10 gap-x-5">
        <div className="col-span-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <LuSlidersHorizontal size={20} />
              <p className="relative ml-1 font-medium text-lg">
                Filters
                <span className="w-3 h-4 rounded-full bg-black text-white absolute -right-4 top-0 flex items-center justify-center text-sm">
                  1
                </span>
              </p>
            </div>
            <p className="underline text-brandOrange-200 underline-offset-8 text-xs">
              Clear
            </p>
          </div>
          <div className="mt-4">
            <FilterAccordion />
          </div>
        </div>
        <div className="col-span-5 bg-transparent relative">
          <div className="absolute">
            <Menu>
              <Menu.Button className="border px-3 py-1 rounded bg-white shadow-md">
                Sort
              </Menu.Button>
              <Menu.Items className="bg-white rounded-sm shadow-md w-[200px] mt-2">
                {links.map((link) => (
                  <Menu.Item
                    as="div"
                    key={link}
                    className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black p-2 hover:bg-brandOrange-600 hover:font-medium hover:text-brandOrange-200 cursor-pointer"
                    onClick={() => sortFn(link)}
                  >
                    {link}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>
          <div className="">
            <div className="flex flex-wrap gap-10 py-10 justify-start">
              {products.length > 0 ? (
                products.map((card: any) => <ProductsCard slide={card} />)
              ) : (
                <p className="ml-4 font-medium text-2xl">Sorry no cards...</p>
              )}
            </div>
          </div>
          {/* <div className="w-full h-20 flex justify-between items-center">
            <div></div>
            <div className="flex gap-x-4">
              <div className="h-8 border rounded w-8 flex justify-center items-center">
                <TfiArrowLeft color="#727272" />
              </div>
              <div className="bg-brandOrange-200 h-8 border rounded w-8 flex justify-center items-center">
                <TfiArrowRight color="#fff" />
              </div>
            </div>
            <div>
              <p> Page 1 of 9 </p>
            </div>
          </div> */}
        </div>
      </div>
    </Template>
  );
};

export default Products;
