import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/HomePage";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Favorites from "./pages/Favorites";
import Saved from "./pages/Saved";
import Profile from "./pages/Profile";
import ProductDetails from "./pages/ProductDetails";
import Notifications from "./pages/Notifications";
import Reminders from "./pages/Reminders";
import ProductCategories from "./pages/ProductCategories";
import Products from "./pages/Products";
import ResetPassword from "./pages/ResetPassword";
import History from "./pages/History";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import CheckoutDeliveryDetails from "./pages/CheckoutDeliveryDetails";
import CheckoutPaymentDetails from "./pages/CheckoutPaymentDetails";
import { SearchProvider } from "./SearchContext";
import Customize from "./pages/Customize";
import { CustomizationProvider } from "./CustomizationContext";

export const API_BASE_URL = "https://bluemoonapi-uat.bts.com.ng";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/category/:category",
    element: <ProductCategories />,
  },
  {
    path: "/product/:id",
    element: <ProductDetails />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/saved",
    element: <Saved />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/customize/:id",
    element: <Customize />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/checkout/:id/delivery-address",
    element: <Checkout />,
  },
  {
    path: "/checkout/delivery-details",
    element: <CheckoutDeliveryDetails />,
  },
  {
    path: "/checkout/payment-details",
    element: <CheckoutPaymentDetails />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  {
    path: "/reminders",
    element: <Reminders />,
  },
  {
    path: "/reset-password/",
    element: <ResetPassword />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SearchProvider>
      <CustomizationProvider>
        <RouterProvider router={router} />
      </CustomizationProvider>
    </SearchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
