import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { fabric } from "fabric";

interface IContext {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomizationContext = createContext<Record<string, any>>({});

export const CustomizationProvider = ({ children }: { children: any }) => {
  // const [query, setQuery] = useState("");
  const canvasRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [isAddingText, setIsAddingText] = useState(false);
  const [previewText, setPreviewText] = useState<fabric.Textbox | null>(null);
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(20);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fill, setFill] = useState("yellow");

  const [images, setImages] = useState<fabric.Image[]>([]);
  const [fileUploadControl, setFileUploadControl] =
    useState<fabric.Rect | null>(null);
  const [selectedObject, setSelectedObject] = useState<fabric.Object | null>(
    null
  );

  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);

  const saveToHistory = useCallback(() => {
    if (canvas) {
      const currentState = canvas.toJSON();
      const newHistory = [
        ...history.slice(0, historyIndex + 1),
        JSON.stringify(currentState),
      ];
      setHistory(newHistory);
      setHistoryIndex(newHistory.length - 1);
    }
  }, [canvas, history, historyIndex]);

  const handleObjectModified = useCallback(
    (e: fabric.IEvent) => {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        setSelectedObject(activeObject);
        saveToHistory();
      }
    },
    [canvas, saveToHistory]
  );

  const handleMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (isAddingText && event.pointer) {
        if (!previewText && canvas) {
          const textObj = new fabric.Textbox(text, {
            left: event.pointer.x,
            top: event.pointer.y,
            fill: fill,
            fontSize: fontSize,
            fontFamily: fontFamily,
          });
          canvas.add(textObj);
          setPreviewText(textObj);
        } else if (previewText) {
          previewText.set({
            left: event.pointer.x,
            top: event.pointer.y,
          });
          canvas?.renderAll();
        }
      }
    },
    [isAddingText, previewText, text, fill, fontSize, fontFamily, canvas]
  );

  const handleMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (isAddingText && event.pointer && previewText) {
        previewText.set({
          left: event.pointer.x,
          top: event.pointer.y,
          fill: fill,
          fontSize: fontSize,
          fontFamily: fontFamily,
        });
        setPreviewText(null);
        setIsAddingText(false);
        canvas?.renderAll();
      }
    },
    [isAddingText, previewText, fill, fontSize, fontFamily, canvas]
  );

  const handleObjectSelected = useCallback(
    (e: fabric.IEvent) => {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        //setIsAddingText(false);
        setSelectedObject(activeObject);
        if (activeObject.name === "File-Upload" && fileInputRef.current) {
          fileInputRef.current.click();
        }
      }
    },
    [canvas]
  );

  const handleObjectCleared = useCallback(() => {
    setSelectedObject(null);
  }, []);

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && canvas) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = e.target?.result as string;
          fabric.Image.fromURL(image, (img: any) => {
            const canvasAspect = canvas.width! / canvas.height!;
            const imgAspect = img.width! / img.height!;

            let scaleX = canvas.width! / img.width!;
            let scaleY = canvas.height! / img.height!;

            if (canvasAspect >= imgAspect) {
              scaleX = scaleY;
            } else {
              scaleY = scaleX;
            }

            if (selectedObject) {
              img.set({
                left: selectedObject.left,
                top: selectedObject.top,
                scaleX: selectedObject.width! / img.width!,
                scaleY: selectedObject.height! / img.height!,
                originX: "center",
                originY: "center",
              });
              canvas.remove(selectedObject);
              setSelectedObject(null);
              canvas.add(img);
              canvas.renderAll();
            } else {
              // Determine if the original image size is greater than the canvas size
              const isLargerThanCanvas =
                img.width! > canvas.width! || img.height! > canvas.height!;
              if (isLargerThanCanvas) {
                // Add image as a perfect square in the middle of the canvas
                const squareSize = Math.min(canvas.width!, canvas.height!);
                img.scaleToWidth(squareSize);
                img.scaleToHeight(squareSize);
                img.set({
                  left: (canvas.width ?? 800) / 2,
                  top: (canvas.height ?? 600) / 2,
                  originX: "center",
                  originY: "center",
                });
              } else {
                // Add image as is in the center
                img.set({
                  left: (canvas.width ?? 800) / 2,
                  top: (canvas.height ?? 600) / 2,
                  originX: "center",
                  originY: "center",
                });
              }
              canvas.add(img);
              setImages((prevImages: any) => [...prevImages, img]);
              canvas.renderAll();
            }
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleAddTextClick = () => {
    setIsAddingText(true);
  };

  const handleAddFileUploadControl = () => {
    if (canvas) {
      const rect = new fabric.Rect({
        left: canvas.width! / 2,
        top: canvas.height! / 2,
        fill: "rgba(0, 0, 0, 0.1)",
        width: 200,
        height: 200,
        originX: "center",
        originY: "center",
        hasControls: true,
        hasBorders: true,
        selectable: true,
        stroke: "black",
        strokeWidth: 1,
      });

      rect.on("selected", () => {
        setFileUploadControl(rect);
        fileInputRef.current?.click();
      });

      canvas.add(rect);
      canvas.setActiveObject(rect);
      canvas.renderAll();
    }
  };

  //template

  const applyTemplate = (templateType: string) => {
    if (!canvas) return;

    canvas.clear();
    setSelectedObject(null);

    if (templateType === "textCenter") {
      const textObj = new fabric.Textbox("Template Text", {
        left: canvas.width! / 2,
        top: canvas.height! / 2,
        fill: "black",
        fontSize: 30,
        fontFamily: "Arial",
        textAlign: "center",
        width: 600,
        originX: "center",
        originY: "center",
      });
      canvas.add(textObj);
    } else if (templateType === "textTopImageBottom") {
      // Text area occupies top 50% of the canvas
      const textObj = new fabric.Textbox("Template Text", {
        left: canvas.width! / 2,
        top: canvas.height! / 4, // Centered in the top half
        fill: "black",
        fontSize: 30,
        fontFamily: "Arial",
        textAlign: "center",
        width: canvas.width!,
        originX: "center",
        originY: "center",
        height: canvas.height! / 2,
        selectable: true,
        evented: true,
        lockMovementX: false,
        lockMovementY: false,
      });
      canvas.add(textObj);

      // File upload area occupies bottom 50% of the canvas
      addFileUploadArea(
        canvas.width! / 2,
        (3 * canvas.height!) / 4, // Centered in the bottom half
        canvas.width!,
        canvas.height! / 2
      );
    } else if (templateType === "imageTopTextBottom") {
      // File upload area occupies top 50% of the canvas
      addFileUploadArea(
        canvas.width! / 2,
        canvas.height! / 4, // Centered in the top half
        canvas.width!,
        canvas.height! / 2
      );

      // Text area occupies bottom 50% of the canvas
      const textObj = new fabric.Textbox("Template Text", {
        left: canvas.width! / 2,
        top: (3 * canvas.height!) / 4, // Centered in the bottom half
        fill: "black",
        fontSize: 30,
        fontFamily: "Arial",
        textAlign: "center",
        width: canvas.width!,
        originX: "center",
        originY: "center",
        height: canvas.height! / 2,
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
      });
      canvas.add(textObj);
    } else if (templateType === "textTopTwoImagesBottom") {
      // Text area occupies top 25% of the canvas
      const textObj = new fabric.Textbox("Template Text", {
        left: canvas.width! / 2,
        top: canvas.height! / 8, // Centered in the top portion
        fill: "black",
        fontSize: 30,
        fontFamily: "Arial",
        textAlign: "center",
        width: canvas.width!,
        originX: "center",
        originY: "center",
        height: canvas.height! / 4,
      });
      canvas.add(textObj);

      // First file upload area occupies middle 25% of the canvas
      addFileUploadArea(
        canvas.width! / 2,
        canvas.height! / 2, // Centered in the middle portion
        canvas.width!,
        canvas.height! / 4
      );

      // Second file upload area occupies bottom 25% of the canvas
      addFileUploadArea(
        canvas.width! / 2,
        (7 * canvas.height!) / 8, // Centered in the bottom portion
        canvas.width!,
        canvas.height! / 4
      );
    }

    canvas.renderAll();
    saveToHistory();
  };

  const addFileUploadArea = (
    left: number,
    top: number,
    width: number,
    height: number
  ) => {
    if (canvas) {
      const rect = new fabric.Rect({
        left,
        top,
        fill: "rgba(0, 0, 0, 0.1)",
        width,
        height,
        originX: "center",
        originY: "center",
        selectable: true,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        evented: true,
        name: "upload",
        stroke: "black",
        strokeWidth: 1,
      });

      rect.on("mousedown", () => {
        fileInputRef.current?.click();
      });

      canvas.add(rect);
      canvas.renderAll();
    }
  };

  const addTextInputArea = () => {
    if (canvas) {
      const textBox = new fabric.Textbox("Type your text here", {
        left: (3 * canvas.width!) / 4,
        top: canvas.height! / 2,
        width: canvas.width! / 2,
        height: canvas.height!,
        fill: "black",
        originX: "center",
        originY: "center",
        selectable: true,
      });

      canvas.add(textBox);
      canvas.renderAll();
    }
  };

  const addTextAndUploadTemplate = () => {
    if (canvas) {
      canvas.clear();
      addTextInputArea();
      addFileUploadArea(
        canvas.width! / 4,
        canvas.height! / 4,
        canvas.width! / 2,
        canvas.height! / 2
      );
    }
  };

  //second template

  const handleDelete = () => {
    if (selectedObject && canvas) {
      canvas.remove(selectedObject);
      setSelectedObject(null);
      saveToHistory();
    }
  };

  const handleBringToFront = () => {
    if (selectedObject && canvas) {
      selectedObject.bringToFront();
      canvas.renderAll();
      saveToHistory();
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setText(newText);
    if (selectedObject && selectedObject.type === "textbox") {
      console.log("handleTextChange:: textbox");
      (selectedObject as fabric.Textbox).set("text", newText);
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    setFontSize(newSize);
    if (selectedObject && selectedObject.type === "textbox") {
      (selectedObject as fabric.Textbox).set("fontSize", newSize);
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFont = e.target.value;
    setFontFamily(newFont);
    if (selectedObject && selectedObject.type === "textbox") {
      (selectedObject as fabric.Textbox).set("fontFamily", newFont);
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const handleFillChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFill = e.target.value;
    setFill(newFill);
    if (selectedObject && selectedObject.type === "textbox") {
      (selectedObject as fabric.Textbox).set("fill", newFill);
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const handleResizeImage = (scale: number) => {
    if (selectedObject && selectedObject.type === "image") {
      selectedObject.scaleX = scale;
      selectedObject.scaleY = scale;
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const handleRotateImage = (angle: number) => {
    if (selectedObject && selectedObject.type === "image") {
      selectedObject.angle = (selectedObject.angle! + angle) % 360;
      canvas?.renderAll();
      saveToHistory();
    }
  };

  const saveDesignToLocalStorage = () => {
    if (canvas) {
      const canvasJson = canvas.toJSON();
      localStorage.setItem("canvasDesign", JSON.stringify(canvasJson));
    }
  };

  const handleEnhanceImage = (filterType: string, value: number) => {
    if (selectedObject && selectedObject instanceof fabric.Image) {
      let filter = null;

      switch (filterType) {
        case "brightness":
          filter = new fabric.Image.filters.Brightness({ brightness: value });
          break;
        case "contrast":
          filter = new fabric.Image.filters.Contrast({ contrast: value });
          break;
        case "saturation":
          filter = new fabric.Image.filters.Saturation({ saturation: value });
          break;
        case "sepia":
          filter = new fabric.Image.filters.Sepia({ sepia: value });
          break;
        case "hue":
          filter = new fabric.Image.filters.HueRotation({ rotation: value });
          break;
        default:
          break;
      }

      if (filter) {
        selectedObject.filters!.push(filter);
        selectedObject.applyFilters();
        canvas?.renderAll();
        saveToHistory();
      }
    }
  };

  const handleUndo = () => {
    if (historyIndex > 0 && canvas) {
      const prevState = history[historyIndex - 1];
      canvas.loadFromJSON(prevState, () => {
        canvas.renderAll();
        setHistoryIndex(historyIndex - 1);
      });
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1 && canvas) {
      const nextState = history[historyIndex + 1];
      canvas.loadFromJSON(nextState, () => {
        canvas.renderAll();
        setHistoryIndex(historyIndex + 1);
      });
    }
  };

  return (
    <CustomizationContext.Provider
      value={{
        canvasRef,
        fileInputRef,
        canvas,
        setCanvas,
        backgroundImage,
        setBackgroundImage,
        isAddingText,
        setIsAddingText,
        previewText,
        setPreviewText,
        text,
        setText,
        fontSize,
        setFontSize,
        fontFamily,
        setFontFamily,
        fill,
        setFill,
        images,
        setImages,
        fileUploadControl,
        setFileUploadControl,
        selectedObject,
        setSelectedObject,
        history,
        setHistory,
        historyIndex,
        setHistoryIndex,
        handleDelete,
        saveDesignToLocalStorage,
        handleRotateImage,
        handleResizeImage,
        handleFillChange,
        handleFontFamilyChange,
        handleFontSizeChange,
        handleTextChange,
        handleBringToFront,
        addTextAndUploadTemplate,
        applyTemplate,
        handleAddFileUploadControl,
        handleAddTextClick,
        handleUploadImage,
        handleObjectCleared,
        handleObjectSelected,
        handleMouseDown,
        handleMouseMove,
        handleObjectModified,
        saveToHistory,
        handleEnhanceImage,
        handleUndo,
        handleRedo,
      }}
    >
      {children}
    </CustomizationContext.Provider>
  );
};

export default CustomizationContext;
