import { Dialog, Listbox, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IResponseObject } from "../interfaces";
import { Toaster, toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { API_BASE_URL } from "..";
import { format } from "date-fns";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { IReminder } from "./DeleteReminderModal";
import { apiPost } from "../service/useApi";

export default function EditReminderModal({
  isOpen,
  setIsOpen,
  reminders,
  reminder,
}: {
  isOpen: boolean;
  setIsOpen: any;
  reminders: any[];
  reminder: IReminder;
}) {
  function closeModal() {
    setIsOpen(false);
  }

  // const reminder = reminders.filter((rem) => rem.reminderId == reminderId);

  console.log(reminder);

  const dateFormat = new Date(reminder.date);
  const formattedDate = dateFormat.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const occasionArray = ["Birthday", "Anniversary", "Wedding"];

  const [name, setName] = useState(reminder.name);
  const [occasion, setOccasion] = useState("");
  const [intervalType, setIntervalType] = useState(reminder.intervalType);
  const [date, setDate] = useState<any>(formattedDate);
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const addReminder = async (e: any) => {
    setIsAdding(true);
    e.preventDefault();
    // const userId = localStorage.getItem("userId") as unknown as string;
    // const storeFrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    // const accessToken = localStorage.getItem(
    //   "accessToken"
    // ) as unknown as string;

    console.log(date);

    const bodyInput = {
      // storeFrontId,
      // tenantId,
      // userId,
      type: 0,
      date: date,
      intervalType: +intervalType,
      name,
      description: occasion,
      status: 1,
    };

     const { isLoaded, data: info } = await apiPost(
       `/api/Reminder/EDIT`,
       "PUT",
       bodyInput
     );

     if (isLoaded && info.statusCode == 200) {
      setName("");
      setOccasion("");
      setDate("");
      setIsAdding(false);
      console.log(reminder);
      toast.success(info.message);
      closeModal();
     }

    // console.log(bodyInput);
    // const url = new URL(API_BASE_URL + `/api/Reminder/EDIT`);
    // try {
    //   const res = await fetch(url, {
    //     method: "PUT",
    //     body: JSON.stringify(bodyInput),
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const reminder: IResponseObject = await res.json();
    //   if (reminder.statusCode == 200) {
    //     setName("");
    //     setOccasion("");
    //     setDate("");
    //     setIsAdding(false);
    //     console.log(reminder);
    //     toast.success(reminder.message);
    //     closeModal();
    //     // navigate(0);
    //   } else {
    //     setIsAdding(false);
    //     toast.error(reminder.message);
    //   }
    // } catch (error) {
    //   setIsAdding(false);
    //   console.log(error);
    // }
  };

  console.log(date);
  

  return (
    <>
      <Toaster richColors position="top-right" />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[380px] max-w-md transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <form autoComplete="off">
                    <span
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={closeModal}
                    >
                      x
                    </span>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Reminder
                    </Dialog.Title>
                    <div className="mt-4">
                      <label htmlFor="">Event Name</label>
                      <input
                        type="text"
                        className="w-full h-10 rounded border px-2"
                        defaultValue={reminder.name}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mt-4 flex flex-col">
                      <label htmlFor="">Occasion</label>

                      <Listbox value={occasion} onChange={setOccasion}>
                        <div className="relative mt-1">
                          <Listbox.Button className="relative w-full cursor-default rounded bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                            <span className="block truncate">{occasion}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                              {occasionArray.map((occ, occIdx) => (
                                <Listbox.Option
                                  key={occIdx}
                                  className={({ active }) =>
                                    `relative select-none py-2 pl-10 pr-4 cursor-pointer ${
                                      active
                                        ? "bg-brandOrange-600 text-brandOrange-200"
                                        : "text-gray-900"
                                    }`
                                  }
                                  value={occ}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {occ}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brandOrange-200">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <div className="w-full mt-4 flex gap-x-2 ">
                      <div className="mt-2 w-full">
                        <label htmlFor="">Date</label>
                        <input
                          type="date"
                          // value={date}
                          // defaultValue={}
                          className="w-full h-10 rounded border px-2"
                          // onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label htmlFor="">To be reminded</label>
                        <select
                          name=""
                          id=""
                          className="w-full border h-10 rounded"
                          required
                          defaultValue={reminder.intervalType}
                          value={intervalType}
                          onChange={(e) => setIntervalType(+e.target.value)}
                        >
                          <option value="7">Weekly</option>
                          <option value="14">Bi-Weekly</option>
                          <option value="30">Monthly</option>
                          <option value="14">Bi-monthly</option>
                          <option value="365">Yearly</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="w-full bg-brandOrange-200 h-10 font-medium text-white rounded mt-4"
                        onClick={addReminder}
                      >
                        {isAdding ? (
                          <ClipLoader
                            color={"white"}
                            loading={isAdding}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          "Update Reminder"
                        )}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
