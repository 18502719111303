import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import { HiOutlineMegaphone } from "react-icons/hi2";
import Loading from "../components/Loading";
import { IResponseObject } from "../interfaces";
import { API_BASE_URL } from "..";
import { useNavigate } from "react-router-dom";
import EmptyNotifications from "../assets/empty-notification.png";
import { convertDate } from "../utils";
import { apiFetch } from "../service/useApi";

const Notifications = () => {
  enum NotificationType {
    allType = "ALL",
    PromotionType = "PROMOTIONS",
    OrderType = "ORDERS",
  }

  const [notifications, setNotifications] = useState([]);
  const [promotions, setPromotions] = useState<[]>();
  const [orders, setOrders] = useState<[]>();
  const [notificationsToShow, setNotificationsToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  const [view, setView] = useState(NotificationType.allType);

  const navigate = useNavigate();
  useEffect(() => {
    const getNotifications = async () => {
      setLoading(true);

      const { data, isLoaded } = await apiFetch(
        "/api/Notification/GetNotifications",
        "GET"
      );
      if (isLoaded && data.statusCode == 200) {
        setLoading(false);
        setNotifications(data.data);
        setNotificationsToShow(data.data);
      }

      // const userId = localStorage.getItem("userId") as unknown as string;
      // const storeId = localStorage.getItem("storeFrontId") as unknown as string;
      // const tenantId = localStorage.getItem("tenantId") as unknown as string;
      // const accessToken = localStorage.getItem(
      //   "accessToken"
      // ) as unknown as string;
      // if (!userId || !accessToken) {
      //   navigate("/");
      // }
      // console.log(userId);
      // const url = new URL(
      //   API_BASE_URL +
      //     `/api/Notification/GetNotifications?TenantId=${tenantId}&userId=${userId}&storefrontId=${storeId}`
      // );
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   });
      //   const notifications: IResponseObject = await res.json();
      //   if (notifications.statusCode == 200) {
      //     setLoading(false);
      //     setNotifications(notifications.data);
      //     setNotificationsToShow(notifications.data);
      //     console.log({ notifications });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };

    getNotifications();
  }, []);

  useEffect(() => {
    const promotionsArray =
      notifications &&
      notifications?.filter(
        (notification: any) => notification.title == "Promotions"
      );
    const orders = notifications?.filter(
      (notification: any) => notification.title == "Orders"
    );

    console.log(promotionsArray);

    setPromotions(promotionsArray as []);
    setOrders(orders as []);
  }, [notifications]);

  useEffect(() => {
    switch (view) {
      case NotificationType.allType:
        setNotificationsToShow(notifications);
        break;
      case NotificationType.PromotionType:
        setNotificationsToShow(promotions as []);
        console.log("Promotions");
        break;
      case NotificationType.OrderType:
        setNotificationsToShow(orders as []);
        break;
      default:
      case NotificationType.allType:
        setNotificationsToShow(notifications);
    }
  }, [view]);

  if (loading) {
    return <Loading />;
  }

  console.log(notifications);
  return (
    <Template>
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"notifications"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">Notifications</p>
          </div>
          <div className="px-6">
            <div className="h-12 w-full flex items-center border-b mb-4 gap-x-5 font-medium text-brandBlack-400">
              <div
                className={`${
                  view == NotificationType.allType
                    ? "border-b-2 border-brandOrange-200 text-brandOrange-200"
                    : ""
                } h-full flex items-center px-2 cursor-pointer`}
                onClick={() => setView(NotificationType.allType)}
              >
                All{" "}
                {notifications.length > 0 && (
                  <span
                    className={`h-3 w-3 rounded-full border text-[8px] flex items-center justify-center ml-2 ${
                      view == NotificationType.allType
                        ? "text-brandOrange-200 border-brandOrange-200"
                        : "text-brandBlack-400 border-brandBlack-400"
                    }`}
                  >
                    {notifications.length}
                  </span>
                )}
              </div>
              <div
                className={`${
                  view == NotificationType.OrderType
                    ? "border-b-2 border-brandOrange-200 text-brandOrange-200"
                    : ""
                } h-full flex items-center px-2 cursor-pointer`}
                onClick={() => setView(NotificationType.OrderType)}
              >
                Orders & Updates{" "}
                {orders && orders?.length > 0 && (
                  <span
                    className={`h-3 w-3 rounded-full border text-[8px] flex items-center justify-center ml-2 ${
                      view == NotificationType.OrderType
                        ? "text-brandOrange-200 border-brandOrange-200"
                        : "text-brandBlack-400 border-brandBlack-400"
                    }`}
                  >
                    {orders && orders.length}
                  </span>
                )}
              </div>
              <div
                className={`${
                  view == NotificationType.PromotionType
                    ? "border-b-2 border-brandOrange-200 text-brandOrange-200"
                    : ""
                } h-full flex items-center px-2 cursor-pointer`}
                onClick={() => setView(NotificationType.PromotionType)}
              >
                Promotions{" "}
                {promotions && promotions.length && (
                  <span
                    className={`h-3 w-3 rounded-full border text-[8px] flex items-center justify-center ml-2 ${
                      view == NotificationType.PromotionType
                        ? "text-brandOrange-200 border-brandOrange-200"
                        : "text-brandBlack-400 border-brandBlack-400"
                    }`}
                  >
                    {promotions && promotions.length}
                  </span>
                )}
              </div>
            </div>
            {notificationsToShow && notificationsToShow?.length == 0 ? (
              <div className="w-full h-full flex flex-col justify-center items-center">
                <img src={EmptyNotifications} alt="" />
                <p className="font-medium text-2xl mb-1">
                  {" "}
                  You have no notifications
                </p>
                <p className="text-sm">We’ll let you know when you have one.</p>
              </div>
            ) : (
              notificationsToShow &&
              notificationsToShow.map((notification: any) =>
                notification.notifications.map((not: any) => (
                  <div className="flex flex-wrap gap-10 py-4 items-center">
                    <div className="border-b w-full flex items-start justify-between pb-8">
                      <div className="flex gap-x-2">
                        <div className="h-10 w-10 rounded-full bg-[#FFF6EB] flex justify-center items-center relative">
                          <HiOutlineMegaphone fontSize={22} color="#F89521" />

                          <span className="h-2 w-2 rounded-full absolute bg-brandRed-100 top-0 right-0"></span>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">{not.title}</p>
                          <p className="text-sm my-1">{not.content}</p>
                          <p className="text-brandBlack-200 text-sm">
                            {convertDate(new Date(not.createdOn))}
                          </p>
                        </div>
                      </div>
                      <div className="border-b border-brandOrange-200 text-brandOrange-200 text-sm cursor-pointer">
                        View
                      </div>
                    </div>
                  </div>
                ))
              )
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Notifications;
