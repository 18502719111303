import React from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import majesty from "../assets/majestycard.png";
import Card from "../components/Card";

const Saved = () => {
  const dummyArray = Array.from(Array(10).keys());

  const slidevalue = {
    title: "Disney",
    img: majesty,
    rating: 3,
  };

  return (
    <Template>
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"saved"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">Saved Designs</p>
          </div>
          <div className="px-6">
            <div className="h-12 w-full flex items-center border-b mb-4">
              1 Item
            </div>
            <div className="flex flex-wrap gap-10 py-10 items-center">
              <div className="sm:w-[145px] lg:w-[245px] rounded-lg flex flex-col">
                <div className="sm:h-[165px] lg:h-[336px] rounded-lg">
                  <img
                    src={majesty}
                    alt="template"
                    className="w-full object-cover"
                  />
                </div>
                <div className="w-full mt-4">
                  <p>Friend's Piece</p>
                  <p className="text-sm text-brandBlack-200">Last Modified: Jan 21, 2023 </p>
                  <button className="w-full border rounded-md border-brandOrange-200 text-brandOrange-200 h-9 my-2">Continue Editing</button>
                  <div className="flex justify-center items-center w-full">
                        <span className="underline underline-offset-8 text-brandBlack-200 text-sm">Delete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Saved;
