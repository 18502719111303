import React from "react";
import { FiLayout } from "react-icons/fi";
import { RxText } from "react-icons/rx";
import { FiImage } from "react-icons/fi";
import { BsEmojiNeutral } from "react-icons/bs";
import { FiFolderMinus } from "react-icons/fi";
import { LayoutView } from "../../pages/Customize";

const CustomizationSideBar = ({
  view,
  setView,
}: {
  view: LayoutView;
  setView: React.Dispatch<React.SetStateAction<LayoutView>>;
}) => {
  const routes = [
    {
      name: "Layout",
      icon: (
        <FiLayout
          size={22}
          color={view == LayoutView.layout ? "#F89521" : "#000"}
        />
      ),
      view: LayoutView.layout,
    },
    {
      name: "Text",
      icon: (
        <RxText
          size={22}
          color={view == LayoutView.text ? "#F89521" : "#000"}
        />
      ),
      view: LayoutView.text,
    },
    {
      name: "Photos",
      icon: (
        <FiImage
          size={22}
          color={view == LayoutView.photo ? "#F89521" : "#000"}
        />
      ),
      view: LayoutView.photo,
    },
    {
      name: "Stickers",
      icon: (
        <BsEmojiNeutral
          size={22}
          color={view == LayoutView.sticker ? "#F89521" : "#000"}
        />
      ),
      view: LayoutView.sticker,
    },
    {
      name: "Saved Designs",
      icon: (
        <FiFolderMinus
          size={22}
          color={view == LayoutView.savedDesigns ? "#F89521" : "#000"}
        />
      ),
      view: LayoutView.savedDesigns,
    },
  ];

  return (
    <div className="fixed h-screen border-r bg-white pt-24 w-32 left-0 top-0 flex flex-col gap-y-10 items-center px-5 ">
      {routes.map((route) => (
        <div
        key={route.name}
          className={` rounded flex flex-col items-center gap-y-2 text-center w-full py-3 cursor-pointer ${
            view == route.view ? "bg-brandOrange-600" : ""
          }`}
          onClick={() => setView(route.view)}
        >
          <span>{route.icon}</span>
          <span
            className={`${
              view === route.view ? "text-brandOrange-200" : "text-black"
            }`}
          >
            {route.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CustomizationSideBar;
