import React, { useState } from "react";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";
import { IoStarSharp } from "react-icons/io5";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "sonner";
import { apiPost } from "../service/useApi";

interface ISlide {
  id: string;
  imageUrl: string;
  isFavourite: boolean;
  name: string;
  slug: string;
  ratings?: any;
}
const ProductsCard = ({ slide }: { slide: ISlide }) => {
  const [prod, setProd] = useState<any>(slide);
  const [favoriting, setFavoriting] = useState(false);
  const [isRating, setisrating] = useState(false);

  const favoriteProduct = async () => {
    setFavoriting(true);
    const storefrontId = localStorage.getItem(
      "storeFrontId"
    ) as unknown as string;
    const userId = localStorage.getItem("userId") as unknown as string;

    const bodyInput = {
      // userId,
      // storefrontId,
      productId: slide.id,
    };

    console.log(bodyInput);
    const { isLoaded, data } = await apiPost(
      `/api/Customer/FavoriteProduct`,
      "POST",
      bodyInput
    );

    if (isLoaded && data.statusCode) {
      setProd(data.data);
      setFavoriting(false);
      toast.success(
        data.isFavourite
          ? "Product removed from favorites"
          : "Product added to favorites"
      );
    } else {
      setFavoriting(false);
      toast.error("Something went wrong, please try again later.");
    }

    // const url = new URL(API_BASE_URL + `/api/Customer/FavoriteProduct`);
    // try {
    //   const res = await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify(bodyInput),
    //   });
    //   const products: IResponseObject = await res.json();
    //   //  console.log("prod =>", products);
    //   if (products.statusCode == 200) {
    //     setProd(products.data);
    //     console.log(products);
    //     setFavoriting(false);
    //     toast.success(
    //       prod.isFavourite
    //         ? "Product removed from favorites"
    //         : "Product added to favorites"
    //     );
    //   } else {
    //     setFavoriting(false);
    //     toast.error("Something went wrong, please try again later.");
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setFavoriting(false);
    //   toast.error("Something went wrong, please try again later.");
    // }
  };

  const rateProduct = async (rating: number) => {
    setisrating(true);
    // const storefrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const userId = localStorage.getItem("userId") as unknown as string;

    const bodyInput = {
      // userId,
      // storefrontId,
      rating,
      productId: prod.id,
    };

    const { isLoaded, data } = await apiPost(
      `/api/Customer/RateProduct`,
      "POST",
      bodyInput
    );

    if (isLoaded && data.statusCode) {
      setisrating(false);
      setProd(data.data);
    }

    // console.log(bodyInput);
    // const url = new URL(API_BASE_URL + `/api/Customer/RateProduct`);
    // try {
    //   const res = await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify(bodyInput),
    //   });
    //   const products: IResponseObject = await res.json();
    //   //  console.log("prod =>", products);
    //   if (products.statusCode == 200) {
    //     setisrating(false);
    //     setProd(products.data);
    //     // localStorage.setItem(
    //     //   "categoriesList",
    //     //   JSON.stringify(products.data)
    //     // );
    //     console.log(products);
    //   }
    // } catch (error) {
    //   setisrating(false);
    //   console.log(error);
    // }
  };

  return (
    <div className="sm:w-[145px] lg:w-[245px] rounded-lg lg:h-[400px] sm:h-[250px] flex flex-col shadow-sm">
      <div className="sm:h-[165px] lg:h-[336px] rounded-lg">
        <img
          src={slide.imageUrl}
          alt={slide.name}
          className="w-full object-cover h-72"
        />
      </div>
      <div className="flex items-center justify-between sm:h-9 lg:h-11 mx-2">
        <div className="">
          <Link to={`/product/${prod.slug}`}>
            <p className="mb-2">{prod.name}</p>
          </Link>
          <div
            className={`flex items-center ${
              isRating ? "cursor-wait" : "cursor-default"
            }`}
          >
            <span onClick={() => rateProduct(1)} className="cursor-pointer">
              <IoStarSharp color={prod.ratings >= 1 ? "#F15B2B" : "#DEDEDE"} />
            </span>
            <span onClick={() => rateProduct(2)} className="cursor-pointer">
              <IoStarSharp color={prod.ratings >= 2 ? "#F15B2B" : "#DEDEDE"} />
            </span>
            <span onClick={() => rateProduct(3)} className="cursor-pointer">
              <IoStarSharp color={prod.ratings >= 3 ? "#F15B2B" : "#DEDEDE"} />
            </span>
            <span onClick={() => rateProduct(4)} className="cursor-pointer">
              <IoStarSharp color={prod.ratings >= 4 ? "#F15B2B" : "#DEDEDE"} />
            </span>
            <span onClick={() => rateProduct(5)} className="cursor-pointer">
              <IoStarSharp color={prod.ratings == 5 ? "#F15B2B" : "#DEDEDE"} />
            </span>
            {isRating && (
              <ClipLoader
                color={"#F89521"}
                loading={isRating}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="ml-1"
              />
            )}
          </div>
        </div>
        {favoriting ? (
          <div className="">
            <ClipLoader
              color={"#F15B2B"}
              loading={favoriting}
              size={16}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div
            className="cursor-pointer"
            onClick={async () => favoriteProduct()}
          >
            {prod.isFavourite ? (
              <RiHeartFill color="#F89521" size={22} />
            ) : (
              <RiHeartLine color="#F89521" size={22} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsCard;
