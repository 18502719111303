import React from "react";

const PrevArrow = ({ onClick }: { onClick?: any }) => {
  return (
    <div onClick={onClick}>
      <div className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer"></div>
    </div>
  );
};

export default PrevArrow;
