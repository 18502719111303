import React, { Fragment, useEffect, useState } from "react";
import Template from "../components/Template";
import book1 from "../assets/book-image-1.png";
import VisaIcon from "../assets/Visa logo.png";
import MasterCardIcon from "../assets/MasterCard logo.png";
import PayPalIcon from "../assets/paypal.png";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { Link, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FiTrash2, FiEdit } from "react-icons/fi";
import Loading from "../components/Loading";
import EmptyState from "../assets/Empty-order.png";
import { numberWithCommas } from "../utils";
import { ClipLoader } from "react-spinners";
import { toast } from "sonner";

const Cart = () => {
  const [loading, setLoading] = useState(true);
  const [checkingOut, setCheckingOut] = useState(false);
  const [discounting, setDiscounting] = useState(false);
  const [summary, setSummary] = useState<any>();
  const [selectedQty, setSelectedQty] = useState(1);
  const [isRemoving, setIsRemoving] = useState(false);
  const [code, setCode] = useState("");

  const numbers = Array.from(Array(100).keys());

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken") as unknown as string;
  const customerId = localStorage.getItem("userId") as unknown as string;
  const storeFrontId = localStorage.getItem(
    "storeFrontId"
  ) as unknown as string;
  const checkoutFn = async () => {
    setCheckingOut(true);
    const orderId = summary.orderId;
    const data = {
      orderId,
      customerId,
    };
    const url = new URL(API_BASE_URL + `/api/Cart/Checkout`);
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });
      const notifications: IResponseObject = await res.json();
      if (notifications.statusCode == 200) {
        toast.success("Order placed successfuly");
        setCheckingOut(false);
        console.log(notifications);
        navigate(`/checkout/${orderId}/delivery-address`);
      }
    } catch (error) {
      toast.error("Sorry, something happened");
      console.log(error);
    }
  };

  const removeFromCart = async (id: string) => {
    setIsRemoving(true);
    const userId = localStorage.getItem("userId") as unknown as string;

    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    const url = new URL(
      API_BASE_URL +
        `/api/Cart/Remove?orderItemId=${id}&customerId=${userId}&storefrontId=${storeFrontId}`
    );

    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const detailsResp: IResponseObject = await res.json();
      if (detailsResp.statusCode == 200) {
        setIsRemoving(false);
        console.log(detailsResp);
        setSummary(detailsResp.data);
      }
    } catch (error) {
      console.log(error);
      setIsRemoving(false);
    }
  };

  const getSumary = async () => {
    setLoading(true);
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    if (!userId || !accessToken) {
      navigate("/");
    }

    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/Cart/Get?customerId=${userId}&storefrontId=${storeId}`
    );
   
    
    try {
      const res =  await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const orderSummary: IResponseObject = await res.json();
      if (orderSummary.statusCode == 200) {
        setLoading(false);
        console.log(orderSummary.data);
        setSummary(orderSummary.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSumary();
  }, []);

  const applyVoucher = async () => {
    // /api/Order/ApplyDiscount
    setDiscounting(true);
    const data = {
      orderId: summary.orderId,
      customerId,
      discountCode: code,
      storeFrontId,
    };
    const url = new URL(API_BASE_URL + `/api/Order/ApplyDiscount`);
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });
      const discountResp: IResponseObject = await res.json();
      if (discountResp.statusCode == 200) {
        toast.success("Voucher applied successfuly");
        setCode("");
        setSummary(discountResp.data)
        setDiscounting(false);
        console.log(discountResp);
      }
    } catch (error) {
      setDiscounting(false);
      toast.error("Sorry, something happened");
      console.log(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Template>
      <div className="py-10 px-20">
        <Link to={"/"}>
          <p className="underline text-brandOrange-200 mb-3 underline-offset-8 cursor-pointer">
            Continue Shopping
          </p>
        </Link>
        <h4 className="font-medium text-3xl text-black">
          Your Basket{" "}
          <span className="text-brandBlack-200">
            ({summary?.orderItemDetails ? summary.orderItemDetails.length : 0}{" "}
            item)
          </span>
        </h4>
        {!loading && summary?.orderItemDetails.length < 1 ? (
          ""
        ) : (
          <div className="grid grid-cols-5 gap-x-10">
            <div className="col-span-3 p-8 bg-white rounded-md shadow-lg">
              {summary ? (
                summary.orderItemDetails.map((product: any) => (
                  <div
                    key={product.orderItemId}
                    className="flex justify-between rounded my-8 p-2 shadow-md"
                  >
                    <div>
                      <div className="flex gap-x-8">
                        <img
                          src={product.imageUrl}
                          alt=""
                          className="h-60 w-[160px] object-cover"
                        />
                        <div>
                          <h4 className="font-medium text-xl">
                            {product.productName}
                          </h4>
                          <p className="text-sm text-brandBlack-200 mb-3">
                            {product.categoryName}
                          </p>
                          <div className="flex flex-col">
                            {product.options.map((option: any) => (
                              <p className="text-black font-medium text-sm mb-1">
                                {option.parentProductOptionName}:{" "}
                                <span className="text-brandBlack-200 ml-1">
                                  {option.productOptionName}
                                </span>
                              </p>
                            ))}
                          </div>
                          <div className="w-20">
                            <Listbox
                              value={selectedQty}
                              onChange={setSelectedQty}
                            >
                              <div className="relative mt-1">
                                <Listbox.Label className={"font-medium"}>
                                  Quantity
                                </Listbox.Label>
                                <Listbox.Button className="relative mt-2 w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                                  <span className="block truncate">
                                    {product.quantity}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                      className="h-5 w-5 text-brandOrange-200"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    {numbers.map(
                                      (size: any, sizeIdx: number) => (
                                        <Listbox.Option
                                          key={sizeIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                                : "text-gray-900"
                                            }`
                                          }
                                          value={`${size.name} - ${size.description}`}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {size}
                                              </span>
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                          {/* <p className="text-sm ">Quantity</p>
                    <select
                      name="1"
                      id=""
                      className="border rounded-md w-20 p-2"
                    >
                      <option>1</option>
                    </select> */}
                        </div>
                      </div>
                      <div className="flex gap-x-5 text-sm mt-4">
                        <div
                          className="text-brandRed-200 flex gap-x-2 items-center cursor-pointer"
                          onClick={() => removeFromCart(product.orderItemId)}
                        >
                          {isRemoving ? (
                            <ClipLoader
                              color={"#F37373"}
                              loading={loading}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          ) : (
                            <>
                              <FiTrash2 size={18} /> Remove
                            </>
                          )}
                        </div>
                        <div className="text-brandOrange-200 flex gap-x-2 items-center cursor-pointer">
                          <FiEdit size={18} /> Preview & Edit
                        </div>
                      </div>
                    </div>
                    <div className="text-xl font-medium">
                      ₦{numberWithCommas(product.amount)}
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <img src={EmptyState} alt="" />
                  <h3 className="font-medium text-2xl mt-2">
                    You don't have any product in cart.
                  </h3>

                  <Link to={"/"}>
                    <button className="rounded text-white bg-brandOrange-200 h-12 w-[325px] my-3 cursor-pointer">
                      Start Shopping
                    </button>
                  </Link>
                </div>
              )}
              <div></div>
            </div>
            <div className="col-span-2 p-8 bg-brandBlue-100 rounded-md shadow-lg max-h-[500px]">
              <p className="text-xl font-bold mb-4">Basket Summary</p>
              <div className="flex justify-between mb-4">
                <p>Standard Card </p>
                <p>
                  ₦ {summary ? numberWithCommas(summary.totalAmount) : "---"}
                </p>
              </div>
              <div className="flex justify-between mb-4">
                <p>Delivery cost</p>
                <p>
                  ₦{summary ? numberWithCommas(summary.deliveryCost) : "---"}
                </p>
              </div>
              <div className="flex justify-between mb-4">
                <p>VAT</p>
                <p>₦{summary ? numberWithCommas(summary.vat) : "---"}</p>
              </div>
              <div className="flex justify-between mb-4 pb-4 border-b">
                <p>Discount</p>
                <p>
                  ₦{summary ? numberWithCommas(summary.discountAmount) : "---"}
                </p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="font-medium">Total</p>
                <p className="font-medium">
                  {" "}
                  <p>
                    ₦{summary ? numberWithCommas(summary.totalAmount) : "---"}
                  </p>
                </p>
              </div>
              <div className="w-full grid grid-cols-3 gap-x-3 mb-4">
                <input
                  type="text"
                  className="col-span-2 rounded-md h-10 border border-brandBlack-200 bg-white px-4"
                  placeholder="Enter code here"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <div
                  onClick={applyVoucher}
                  className="col-span-1 border border-brandOrange-200 text-brandOrange-200 flex justify-center items-center bg-white rounded-md font-medium cursor-pointer"
                >
                  {discounting ? (
                    "Please wait..."
                  ) : (
                    " Apply voucher"
                  )}
                </div>
              </div>
              <button
                className="rounded-md h-10 bg-brandOrange-200 text-white w-full cursor-pointer mb-4"
                onClick={checkoutFn}
              >
                {checkingOut ? (
                  <ClipLoader
                    color={"#fff"}
                    loading={loading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Proceed to checkout"
                )}
              </button>
              <div className="flex justify-center w-full flex-col items-center">
                <p className="text-sm text-brandBlack-200 mb-2">
                  Accepted payment methods
                </p>
                <div className="flex items-center gap-x-4">
                  <span>
                    {" "}
                    <img src={MasterCardIcon} alt="MasterCardIcon" />
                  </span>
                  <span>
                    <img src={PayPalIcon} alt="PayPalIcon" />
                  </span>
                  <span>
                    <img src={VisaIcon} alt="VisaIcon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Template>
  );
};

export default Cart;
