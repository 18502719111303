import React, { useState } from "react";
import { FiUser, FiFolder } from "react-icons/fi";
import { BiBox, BiHeart } from "react-icons/bi";
import { RxEnvelopeClosed } from "react-icons/rx";
import { LuCalendarCheck } from "react-icons/lu";
import { PiSignOutBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import Signout from "./SignoutModal";
const Navbar = ({ currentRoute }: { currentRoute: string }) => {

  const [openModal, setOpenModal] = useState<boolean>(false)
  return (
    <>
      <Signout isOpen={openModal} setIsOpen={setOpenModal} />
      <Link to={"/profile"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "profile"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <FiUser
            size={24}
            color={`${currentRoute == "profile" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "profile" ? "text-[#F89521]" : "text-[#000000]"
            }`}
          >
            My Account Details
          </p>
        </div>
      </Link>
      <Link to={"/history"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "history"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <BiBox
            size={24}
            color={`${currentRoute == "history" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "history" ? "text-[#F89521]" : "text-[#000000]"
            }`}
          >
            Order History
          </p>
        </div>
      </Link>
      <Link to={"/favorites"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "favorites"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <BiHeart
            size={24}
            color={`${currentRoute == "favorites" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "favorites" ? "text-[#F89521]" : "text-[#000000]"
            }`}
          >
            Favorites
          </p>
        </div>
      </Link>
      <Link to={"/saved"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "saved"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <FiFolder
            size={24}
            color={`${currentRoute == "saved" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "saved" ? "text-[#F89521]" : "text-[#000000]"
            }`}
          >
            Saved Designs
          </p>
        </div>
      </Link>
      <Link to={"/notifications"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "notifications"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <RxEnvelopeClosed
            size={24}
            color={`${currentRoute == "notifications" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "notifications"
                ? "text-[#F89521]"
                : "text-[#000000]"
            }`}
          >
            Notifications
          </p>
        </div>
      </Link>
      <Link to={"/reminders"}>
        <div
          className={`w-full border-b h-14 flex items-center pl-5 ${
            currentRoute == "reminders"
              ? "bg-[#FFF6EB] border border-[#F89521]"
              : ""
          }`}
        >
          <LuCalendarCheck
            size={24}
            color={`${currentRoute == "reminders" ? "#F89521" : "#000"}`}
          />
          <p
            className={`ml-4 ${
              currentRoute == "reminders" ? "text-[#F89521]" : "text-[#000000]"
            }`}
          >
            Reminders
          </p>
        </div>
      </Link>
      <div
        className="w-full h-14 flex items-center pl-5 cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <span className="rotate-180">
          <PiSignOutBold size={24} />
        </span>
        <p className="ml-4">Sign Out</p>
      </div>
    </>
  );
};

export default Navbar;
