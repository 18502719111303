import React, { useState } from "react";
import Template from "../components/Template";
import { ClipLoader } from "react-spinners";
import { PrimaryInput } from "../components/PrimaryInput";
import { Link, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Toaster, toast } from "sonner";
import { useSearchParams } from "react-router-dom";
import { apiPost } from "../service/useApi";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchParams] = useSearchParams();
  type Inputs = {
    email: string;
    password: string;
    confirmPassword: string;
  };

  console.log(searchParams);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramField = query.get("token");

  const schema = yup
    .object()
    .shape({
      email: yup.string().email().required("Please input Email"),
      password: yup.string().required("Please input Password"),
      confirmPassword: yup.string().required("Please input confirm password"),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    resolver: yupResolver(schema), // yup, joi and even your own.
  });

  const submitForm = async (data: any) => {
    setLoading(true);

    const { data: info, isLoaded } = await apiPost(
      `/api/Customer/ChangePassword`,
      "POST",
      data
    );

    console.log(info, isLoaded);

    if (isLoaded && info.statusCode == 200) {
      setLoading(false);
      console.log({ info });
       toast.success("Password changed successfully");
      // navigate("/");
    } else {
      setLoading(false);
      //  const data = await res.json();
      console.log({ info });
      console.log("something went wrong");
      toast.error("Sorry something went wrong, please try again later.");
    }

    // try {
    //   data.storeFrontId = storeFrontId;
    //   data.tenantId = tenantId;
    //   data.token = paramField;
    //   console.log(JSON.stringify(data));
    //   const res = await fetch(
    //     "https://bluemoonapi-uat.bts.com.ng/api/Customer/ChangePassword",
    //     {
    //       method: "POST",
    //       headers: {
    //         "content-type": "application/json",
    //       },
    //       body: JSON.stringify(data),
    //     }
    //   );

    //   if (res.ok) {
    //     setLoading(false);
    //     console.log(await res.json());
    //     toast.success("Password changed successfully");
    //   }
    // } catch (error) {
    //   console.log("something went wrong");
    //   toast.error("Sorry something went wrong, please try again later.");
    // }
  };
  return (
    <Template>
      <Toaster />
      <div>
        <div className="mt-8">
          <div className="flex sm:flex-col lg:flex-row w-full sm:items-center lg:items-start lg:justify-center lg:px-5 lg:gap-x-10">
            <div className="drop-shadow-2xl bg-white mt-2 rounded-xl px-4 py-8 w-[568px]">
              <h4 className="font-medium text-2xl ">Reset password</h4>
              <form onSubmit={handleSubmit(submitForm)} className="mt-5 w-full" autoComplete="off">
                <div className="my-4">
                  <PrimaryInput
                    defaultValue={""}
                    name="email"
                    register={register}
                    error={errors.email}
                    placeholder="Email Address"
                    className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1"
                    label="Email Address"
                    type="email"
                  />
                </div>
                <>
                  <label className="text-brandBlack-100" htmlFor="password">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id={"password"}
                      placeholder="Password"
                      {...register("password")}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      disabled={false}
                      className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1 relative"
                      style={{
                        borderColor: `${errors.password?.message ? "red" : ""}`,
                      }}
                    />
                    <span
                      className="absolute right-5 -translate-y-1/2 top-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <FiEyeOff size={24} color="#727272" />
                      ) : (
                        <FiEye size={24} color="#727272" />
                      )}
                    </span>
                  </div>
                    <p className="text-brandRed-100 text-sm mt-1 mb-4">
                      {/* {errors.password?.type} */}
                      {errors?.password?.message as unknown as string}
                    </p>
                </>
                <>
                  <label
                    className="text-brandBlack-100"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id={"confirmPassword"}
                      placeholder="Confirm Password"
                      {...register("confirmPassword")}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      disabled={false}
                      className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1 relative"
                      style={{
                        borderColor: `${
                          errors.confirmPassword?.message ? "red" : ""
                        }`,
                      }}
                    />
                    <span
                      className="absolute right-5 -translate-y-1/2 top-1/2 cursor-pointer"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <FiEyeOff size={24} color="#727272" />
                      ) : (
                        <FiEye size={24} color="#727272" />
                      )}
                    </span>
                  </div>
                    <p className="text-brandRed-100 text-sm mt-1">
                      {/* {errors.password?.type} */}
                      {errors?.confirmPassword?.message as unknown as string}
                    </p>
                </>
                <div>
                  <button
                    type="submit"
                    className="my-6 rounded-lg border bg-brandOrange-200 text-white w-full h-11"
                  >
                    {loading ? (
                      <ClipLoader
                        color={"white"}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Reset password"
                    )}
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="mt-10 p-4 max-w-[511px]">
              <div className="w-full">
                <div className="mt-10">
                  <h4 className="text-2xl font-medium">New to Fotologue?</h4>
                  <p className="mt-2">
                    Explore a world of creativity. Setting up an account with us
                    is quick and easy.
                  </p>
                </div>
                <div className="mt-5">
                  {benefitList.map((benefit) => (
                    <div key={benefit} className="flex items-center my-4">
                      <FaCheck color="#39B54A" size={24} />
                      <p className="ml-4">{benefit}</p>
                    </div>
                  ))}
                </div>
                <Link to={"/register"}>
                  <button className="mt-5 rounded-lg border border-brandOrange-200 text-brandOrange-200 w-full h-11">
                    Create a free account
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ResetPassword;
