import { Dialog, Listbox, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IResponseObject } from "../interfaces";
import { Toaster, toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { API_BASE_URL } from "..";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { apiPost } from "../service/useApi";

export default function ReminderrModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: any;
}) {
  function closeModal() {
    setIsOpen(false);
  }

  const [name, setName] = useState("");
  const [occasion, setOccasion] = useState("");
  const [occasionText, setOccasionText] = useState("");
  const [intervalType, setIntervalType] = useState("");
  const [intervalText, setIntervalText] = useState("");
  const [date, setDate] = useState<any>();
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const addReminder = async (e: any) => {
    setIsAdding(true);
    e.preventDefault();
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeFrontId = localStorage.getItem(
      "storeFrontId"
    ) as unknown as string;
    const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;

    console.log(date);

    const bodyInput = {
      // storeFrontId,
      // tenantId,
      // userId,
      type: 0,
      date: date,
      intervalType: +intervalType,
      name,
      description: occasion,
      status: 1,
    };

    const { isLoaded, data } = await apiPost(
      `/api/Reminder/Create`,
      "POST",
      bodyInput
    );

    if (isLoaded && data.statusCode == 200) {
      setName("");
      setOccasion("");
      setIntervalType("");
      setDate("");
      setIsAdding(false);
      console.log(data);
      toast.success(data.message);
      closeModal();
    }

    // console.log(bodyInput);
    // const url = new URL(API_BASE_URL + `/api/Reminder/Create`);
    // try {
    //   const res = await fetch(url, {
    //     method: "POST",
    //     body: JSON.stringify(bodyInput),
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const reminder: IResponseObject = await res.json();
    //   if (reminder.statusCode == 200) {
    //     setName("");
    //     setOccasion("");
    //     setIntervalType("");
    //     setDate("");
    //     setIsAdding(false);
    //     console.log(reminder);
    //     toast.success(reminder.message);
    //     closeModal();
    //     // navigate(0);
    //   } else {
    //     setIsAdding(false);
    //     toast.error(reminder.message);
    //   }
    // } catch (error) {
    //   setIsAdding(false);
    //   console.log(error);
    // }
  };

  const intervals = [
    {
      value: "7",
      text: "Weekly",
    },
    {
      value: "14",
      text: "Bi-weekly",
    },
    {
      value: "30",
      text: "Monthly",
    },
    {
      value: "365",
      text: "Yearly",
    },
  ];

  const occassionLists = ["Birthday", "Anniversary", "Wedding", "Graduation"];

  return (
    <>
      <Toaster richColors position="top-right" />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[380px] max-w-md transform  rounded bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <form autoComplete="off">
                    <span className="absolute right-3 top-3 cursor-pointer">
                      x
                    </span>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Reminder
                    </Dialog.Title>
                    <div className="mt-4">
                      <label htmlFor="">Event Name</label>
                      <input
                        type="text"
                        className="w-full h-10 rounded border px-2"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mt-4 flex flex-col">
                      <label htmlFor="">Occasion</label>
                      <select
                        name=""
                        id=""
                        className="w-full border h-10 rounded"
                        required
                        value={occasion}
                        onChange={(e) => setOccasion(e.target.value)}
                      >
                        <option value="Birthday">Birthday</option>
                        <option value="Anniversary">Anniversary</option>
                        <option value="Wedding">Wedding</option>
                        <option value="Graduation">Graduation</option>
                      </select>
                    </div>
                    <div className="w-full mt-4 flex gap-x-2 ">
                      <div className="mt-2 w-full">
                        <label htmlFor="">Date</label>
                        <input
                          type="date"
                          value={date}
                          className="w-full h-10 rounded border px-2"
                          onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="">
                        <Listbox
                          value={intervalText}
                          onChange={setIntervalText}
                        >
                          <div className="relative mt-1">
                            <Listbox.Label className={""}>
                              To be reminded
                            </Listbox.Label>
                            <Listbox.Button className="relative w-full cursor-default rounded border h-11 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                              <span className="block truncate">
                                {intervalText
                                  ? intervalText
                                  : "Select Interval"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                  className="h-5 w-5 text-brandOrange-200"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {intervals.map((interval: any) => (
                                  <Listbox.Option
                                    key={interval.value}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                                          : "text-gray-900"
                                      }`
                                    }
                                    value={interval.text}
                                    onClick={() =>
                                      setIntervalType(interval.value)
                                    }
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {interval.text}
                                        </span>
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="w-full bg-brandOrange-200 h-10 font-medium text-white rounded mt-4"
                        onClick={addReminder}
                      >
                        {isAdding ? (
                          <ClipLoader
                            color={"white"}
                            loading={isAdding}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          "Add Reminder"
                        )}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
