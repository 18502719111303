import React from "react";

const NextArrow = ({ onClick }: { onClick?: any }) => {
  return (
    <div className="absolute right-0 -top-[80px]" onClick={onClick}>
      <div className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer bg-red-400"></div>
    </div>
  );
};

export default NextArrow;
