
import React from "react";

const FeatureCard = ({ feature }: { feature: any }) => {
  return (
    <div className="lg:w-[245px] rounded-md lg:h-[211px] relative">
      <img
        src={feature.img}
        alt={feature.title}
        className="w-full h-full object-cover"
      />
      <div className="h-8 w-40 rounded-md flex justify-center items-center text-white bg-brandOrange-200 absolute left-4 bottom-4">
        {feature.title}
      </div>
    </div>
  );
};

export default FeatureCard;
