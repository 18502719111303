import { FaCheck } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { ClipLoader } from "react-spinners";
import { PrimaryInput } from "./PrimaryInput";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { apiFetch, apiPost } from "../service/useApi";

export const CreateForm = ({
  showSuccessModal,
}: {
  showSuccessModal: () => void;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  type Inputs = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string | any;
  };

  const benefitList = [
    "Fast checkout with your details already saved",
    "Track your orders and view order history",
    "Receive our latest news and offers.",
    "Remember all the special occasions with our event reminder",
  ];

  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required("Please input First Name"),
      lastName: yup.string().required("Please input Last Name"),
      emailAddress: yup.string().email().required("Please input Email"),
      password: yup
        .string()
        .required("Password is required")
        .min(4, "Password length should be at least 4 characters"),
    })
    .required();

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    resolver: yupResolver(schema), // yup, joi and even your own.
  });

  // checked={/(?=.\W).$/.test(signupInfo?.password)}

  // const rulesList = [
  //   {
  //     rule: "Use 8 or more characters",
  //     test: password.length >= 8,
  //   },
  //   {
  //     rule: "Lowercase character",
  //     test: /?=.*[a-z]/.test(password),
  //   },
  //   {
  //     rule: "One special character",
  //     test: /?=.*\W/.test(password),
  //   },
  //   {
  //     rule: "Number",
  //     test: /?=.*\d/.test(password),
  //   },
  //   {
  //     rule: "Uppercase letter",
  //     test: /?=.*[A-Z]/.test(password),
  //   },
  // ];

  const rulesList = [
    "Use 8 or more characters",
    "Lowercase character",
    "One special character",
    "Number",
    "Uppercase letter",
  ];

  const submitForm = async (data: any) => {
    setLoading(true);
    const { password } = data;
    data.confirmPassword = password;
    data.getPostNotification = 0;
    const {
      data: info,
      isLoaded
    } = await apiPost(`/api/Customer/Create`, "POST", data);

    console.log(info, isLoaded);

    if (isLoaded && info.statusCode == 200) {
      setLoading(false);
      console.log({ info });
      toast.success(`${info.message}`);
      showSuccessModal();
      // navigate("/");
    } else {
      setLoading(false);
      //  const data = await res.json();
      console.log({ info });
      console.log("something went wrong");
      toast.error(`${info.message ? info.message : "something went wrong"}`);
    }

    // const storeConfig = {
    //   tenantId: localStorage.getItem("tenantId"),
    //   storeFrontId: localStorage.getItem("storeFrontId"),
    // };
    // try {
    //   const { password } = data;
    //   data.confirmPassword = password;
    //   data.getPostNotification = 0;
    //   console.log(JSON.stringify(data));
    //   const res = await fetch(
    //     "https://bluemoonapi-uat.bts.com.ng/api/Customer/Create",
    //     {
    //       method: "POST",
    //       headers: {
    //         "content-type": "application/json",
    //       },
    //       body: JSON.stringify(data),
    //     }
    //   );

    //   if (res.ok) {
    //     setLoading(false);
    //     console.log(await res.json());
    //     showSuccessModal();
    //   } else {
    //     setLoading(false);
    //     // console.log(await res.json());
    //     const data = await res.json();
    //     toast.error(`${data.message}`);
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.log("something went wrong");
    //   toast.error("Sorry, something went wrong. Please try again later!");
    // }
  };

  return (
    <div className="flex sm:flex-col lg:flex-row w-full sm:items-center lg:items-start lg:justify-center lg:px-5 lg:gap-x-10">
      <div className="drop-shadow-2xl bg-white mt-2 rounded-xl px-4 py-8 max-w-[568px]">
        <h4 className="font-medium text-2xl mt-10">Create your free account</h4>
        <p>
          Already have an account?
          <span className="text-brandOrange-200 ml-1">
            <Link to={"/login"}>Sign in</Link>
          </span>
        </p>
        <form
          onSubmit={handleSubmit(submitForm)}
          className="mt-5 w-full"
          autoComplete="off"
        >
          <div className="my-4">
            <PrimaryInput
              defaultValue={""}
              name="firstName"
              register={register}
              error={errors.firstName}
              placeholder="First name"
              className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1"
              label="First Name"
            />
          </div>
          <div className="my-4">
            <PrimaryInput
              defaultValue={""}
              name="lastName"
              register={register}
              error={errors.lastName}
              placeholder="Last name"
              className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1"
              label="Last Name"
              type="text"
            />
          </div>
          <div className="my-4">
            <PrimaryInput
              defaultValue={""}
              name="emailAddress"
              register={register}
              error={errors.emailAddress}
              placeholder="Email Address"
              className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1"
              label="Email Address"
              type="email"
            />
          </div>
          <div className="my-4">
            <>
              <label className="text-brandBlack-100" htmlFor="password">
                Password
              </label>
              <div className="relative">
                <input
                  autoComplete="off"
                  id={"password"}
                  placeholder="Password"
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  disabled={false}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-brandBlack-200 rounded-md h-[56px] px-2 w-full focus:border-brandBlue-200 focus:outline-none mt-1 relative"
                  style={{
                    borderColor: `${errors.password?.message ? "red" : ""}`,
                  }}
                />
                <span
                  className="absolute right-5 -translate-y-1/2 top-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FiEyeOff size={24} color="#727272" />
                  ) : (
                    <FiEye size={24} color="#727272" />
                  )}
                </span>
              </div>
              <p className="text-brandRed-100 text-sm mt-1">
                {/* {errors.password?.type} */}
                {errors?.password?.message as unknown as string}
              </p>
            </>
          </div>
          <div className="flex flex-wrap gap-4">
            {rulesList.map((rules, i) => (
              <div
                key={i}
                className={`flex items-center justify-start 
                `}
              >
                <GoDotFill size={20} color="#9D9D9D" />
                <p className="text-brandBlack-400 text-sm">{rules}</p>
              </div>
            ))}
          </div>
          <div className="mt-6">
            <div className="flex items-center">
              <input type="checkbox" name="" id="" className="h-8 w-8 mr-3" />
              <p className="text-sm">
                I want to receive emails about the product, feature updates,
                events, and marketing promotions.
              </p>
            </div>
            <p className="text-sm mt-5">
              By creating an account, you agree to the{" "}
              <span className="text-brandOrange-200 mx-1">Terms of use</span>
              and
              <span className="text-brandOrange-200 mx-1">Privacy</span>
              Policy.
            </p>
          </div>
          <div>
            <button
              type="submit"
              className="my-6 rounded-lg border bg-brandOrange-200 text-white w-full h-11"
            >
              {loading ? (
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Create account"
              )}
            </button>
            <div className="or text-brandBlack-200">OR</div>
            <button className="mt-6 rounded-lg border bg-transparent border-brandBlack-200 text-brandBlack-200 w-full h-11">
              Continue with google
            </button>
          </div>
        </form>
      </div>
      <div className="mt-10 p-4 max-w-[511px]">
        <h4 className="text-2xl font-medium mb-2">Account Benefits</h4>
        <p className="text-sm">
          Creating an account is easy. Just fill in the form or sign up using
          your Google account and enjoy the benefits of having an account which
          includes:
        </p>
        {benefitList.map((benefit, i) => (
          <div key={i} className="flex items-center my-4">
            <FaCheck color="#39B54A" size={24} />
            <p className="ml-4">{benefit}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
