import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/logo-lg.png";
import book1 from "../assets/book-image-1.png";
import { FiLock, FiBell, FiArrowLeft } from "react-icons/fi";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import { numberWithCommas } from "../utils";

const Checkout = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [summary, setSummary] = useState<any>();
  const [addressDetails, setAddressDetails] = useState<any>();

  let { id } = useParams();

  const getSumary = async () => {
    setLoading(true);
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    if (!userId || !accessToken) {
      navigate("/");
    }

    // const orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/Order/GetOrderSummary?orderId=${id}&storefrontId=${storeId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const orderSummary: IResponseObject = await res.json();
      if (orderSummary.statusCode == 200) {
        setLoading(false);
        console.log(orderSummary.data);
        setSummary(orderSummary.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDetails = async () => {
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;

    // const orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/OrderDeliveryAddress/Get?orderId=${id}&storefrontId=${storeId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const notifications: IResponseObject = await res.json();
      if (notifications.statusCode == 200) {
        setLoading(false);
        console.log({ notifications });
        setAddressDetails(notifications.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
    getSumary();
  }, []);

  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required("Please input First name"),
      lastName: yup.string().required("Please input Last name"),
      address: yup.string().required("Please input Address"),
      state: yup.string().required("Please input State"),
      city: yup.string().required("Please input City"),
      country: yup.string().required("Please input Country"),
      zip: yup.string().required("Please input Zip"),
      phoneNumber: yup.string().required("Please input Phone number"),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema), // yup, joi and even your own.
  });

  const submitForm = async (data: any) => {
    console.log(data);

    const userId = localStorage.getItem("userId") as unknown as string;
    setSubmitting(true);
    data.isMyAddress = true;
    data.orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    data.customerId = userId;
    console.log(JSON.stringify(data));
    const res = await fetch(API_BASE_URL + "/api/OrderDeliveryAddress/Add", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      setLoading(false);
      const data = await res.json();
      console.log(data);
      // navigate("/checkout/delivery-details");
    } else {
      setSubmitting(false);
      const data = await res.json();
      console.log(res);
      toast.error(`Sorry, something went wrong, please try again!`);
    }
  };

  if (loading) {
    <Loading />;
  }

  return (
    <div className="overflow-y-hidden h-screen">
      <div className="flex px-20 justify-between items-center bg-white shadow-lg h-16 relative z-30">
        <div className="">
          <Link to={"/"}>
            <span className="">
              <img src={logo} alt="logo-img" width={100} />
            </span>
          </Link>
        </div>

        <div className="flex">
          <p className="mr-4">Secure checkout</p>
          <FiLock size={20} />
        </div>
      </div>
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-3  bg-white h-10 flex justify-center">
          <form
            onSubmit={handleSubmit(submitForm)}
            className="mt-10 w-[560px] "
            autoComplete="off"
          >
            <Link to={"/cart"}>
              <div className="text-brandOrange-200 text-sm underline underline-offset-8 mb-5 flex items-center">
                <FiArrowLeft size={18} className="mr-2" /> Return to basket
              </div>
            </Link>
            <div className="w-full flex justify-between mb-3">
              <p className="font-medium text-2xl">Checkout</p>
              <div className="flex text-brandOrange-200 border px-2 py-1 text-sm rounded-md items-center ">
                Add reminder <FiBell className="ml-2" size={16} />
              </div>
            </div>
            <div className="flex gap-x-5 text-sm text-brandBlack-200 pb-4 border-b mb-3">
              <span className="text-brandOrange-200">Delivery Address</span>
              <span>&gt;</span>
              <span>Delivery Details</span>
              <span>&gt;</span>
              <span>Payment Details</span>
            </div>
            <div className="mb-3 text-lg">
              <p>Delivery address</p>
            </div>
            <div className="flex items-center mb-2">
              <input type="checkbox" name="" id="" />
              <span className="text-sm ml-2">This is my address</span>
            </div>
            <div className="w-full">
              <div className="w-full flex justify-between gap-x-2">
                <div className="relative w-1/2">
                  <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                    First name
                  </span>
                  <input
                    // name="firstName"
                    {...register("firstName")}
                    defaultValue={addressDetails && addressDetails.firstName}
                    type="text"
                    className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                  />
                </div>
                <div className="relative w-1/2">
                  <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                    Last name
                  </span>
                  <input
                    // name="lastName"
                    {...register("lastName")}
                    defaultValue={addressDetails && addressDetails.lastName}
                    type="text"
                    className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                  />
                </div>
              </div>
              <div className="relative w-full mt-3">
                <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                  Country/region
                </span>
                <input
                  // name="country"
                  {...register("country")}
                  defaultValue={addressDetails && addressDetails.country}
                  type="text"
                  className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                />
              </div>
              <div className="relative w-full mt-3">
                <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                  Address
                </span>
                <input
                  // name="address"
                  {...register("address")}
                  defaultValue={addressDetails && addressDetails.address}
                  type="text"
                  className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                />
              </div>
              <div className="w-full flex justify-between gap-x-2 mt-3">
                <div className="relative w-1/3">
                  <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                    City
                  </span>
                  <input
                    // name="city"
                    {...register("city")}
                    defaultValue={addressDetails && addressDetails.city}
                    type="text"
                    className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                  />
                </div>
                <div className="relative w-1/3">
                  <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                    State
                  </span>
                  <input
                    // name="state"
                    {...register("state")}
                    defaultValue={addressDetails && addressDetails.state}
                    type="text"
                    className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                  />
                </div>
                <div className="relative w-1/3">
                  <input
                    placeholder="Postal code (Optional)"
                    // name="zip"
                    {...register("zip")}
                    defaultValue={addressDetails && addressDetails.zip}
                    type="text"
                    className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 text-sm"
                  />
                </div>
              </div>
              <div className="relative w-full mt-3">
                <span className="text-xs absolute top-1 left-4 text-brandBlack-200">
                  Phone number
                </span>
                <input
                  // name="phoneNumber"
                  {...register("phoneNumber")}
                  defaultValue={addressDetails && addressDetails.phoneNumber}
                  type="text"
                  className=" border border-brandBlack-500 rounded-lg h-12 w-full px-4 pt-4 text-sm"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-brandOrange-200 h-12 rounded-lg text-white mt-5"
              >
                {submitting ? (
                  <Loading size={8} color={"#ffffff"} />
                ) : (
                  "Deliver To Address"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="col-span-2 bg-brandBlue-100 pt-16 h-full flex justify-center">
          <div className="w-[410px]">
            <h3 className="text-xl font-medium mb-4">Order Summary</h3>
            <p className="pb-6 border-b border-brandBlack-500 mb-6">1 item</p>
            <div className="flex justify-between border-b pb-6 border-brandBlack-500">
              <div className="flex gap-x-5">
                <img
                  src={summary && summary.orderItemDetails[0].imageUrl}
                  alt=""
                  className="h-36"
                />
                <div className=" flex flex-col justify-between">
                  <div>
                    <p className="font-medium mb-1">
                      {summary && summary.orderItemDetails[0].productName}
                    </p>
                    <p className="text-brandBlack-200 mb-1">
                      {" "}
                      {summary && summary.orderItemDetails[0].categoryName}
                    </p>
                    <p className="mb-1">
                      Quantity:{" "}
                      {summary && summary.orderItemDetails[0].quantity}
                    </p>
                    <p className="mb-1">Size: A4</p>
                  </div>
                  <p className="text-brandOrange-200 text-sm">Preview & Edit</p>
                </div>
              </div>
              <p className="font-medium">
                ₦{summary && summary.orderItemDetails[0].amount}
              </p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Subtotal</p>
              <p className="font-medium">
                {" "}
                {summary &&
                  "₦" + numberWithCommas(summary.totalAmount)}
              </p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Delivery cost</p>
              <p className=" text-brandBlack-200">
                {summary &&
                  "₦" + numberWithCommas(summary.deliveryCost)}
              </p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>VAT (7.5%)</p>
              <p className="font-medium">₦{summary && summary.vat}</p>
            </div>
            <div className="flex justify-between w-full mt-4 border-t border-b py-6 border-brandBlack-500">
              <p className="font-medium text-lg">Total</p>
              <p className="font-medium text-xl">
                {summary &&
                  "₦" + numberWithCommas(summary.totalAmount)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
