import React from "react";
import { DotLoader } from "react-spinners";

const Loading = ({
  size = 350,
  color = "#F89521",
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <DotLoader
        color={color}
        loading={true}
        // cssOverride={override}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
