import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logo-lg.png";
import book1 from "../assets/book-image-1.png";
import { FiLock, FiBell, FiArrowLeft } from "react-icons/fi";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";

const CheckoutDeliveryDetails = () => {
  const navigate = useNavigate();

  const {id} = useParams()

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const [summary, setSummary] = useState<any>();
  const getDetails = async () => {
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    if (!userId || !accessToken) {
      navigate("/");
    }

    // const orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/OrderDeliveryAddress/Get?orderId=${id}&storefrontId=${storeId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const notifications: IResponseObject = await res.json();
      if (notifications.statusCode == 200) {
        setLoading(false);
        console.log(notifications.data);
        setDetails(notifications.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSummary = async () => {
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    if (!userId || !accessToken) {
      navigate("/");
    }

    // const orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/Order/GetOrderSummary?orderId=${id}&storefrontId=${storeId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const orderSummary: IResponseObject = await res.json();
      if (orderSummary.statusCode == 200) {
        setLoading(false);
        console.log(orderSummary.data);
        setSummary(orderSummary.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails();
    getSummary();
  }, []);

  return (
    <div className="overflow-y-hidden h-screen">
      <div className="flex px-20 justify-between items-center bg-white shadow-lg h-16 relative z-30">
        <div className="">
          <Link to={"/"}>
            <span className="">
              <img src={logo} alt="logo-img" width={100} />
            </span>
          </Link>
        </div>

        <div className="flex">
          <p className="mr-4">Secure checkout</p>
          <FiLock size={20} />
        </div>
      </div>
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-3  bg-white h-10 flex justify-center">
          <div className="mt-10 w-[560px] ">
            <div className="w-full flex justify-between mb-3">
              <p className="font-medium text-2xl">Checkout</p>
              <div className="flex text-brandOrange-200 border px-2 py-1 text-sm rounded-md items-center ">
                Add reminder <FiBell className="ml-2" size={16} />
              </div>
            </div>
            <div className="flex gap-x-5 text-sm text-brandBlack-200 pb-4 border-b mb-3">
              <Link to={"/checkout/delivery-address"}>
                <span className="text-brandOrange-200 underline underline-offset-4">
                  Delivery Address
                </span>
              </Link>
              <span>&gt;</span>
              <span className="text-brandOrange-200">Delivery Details</span>
              <span>&gt;</span>
              <span>Payment Details</span>
            </div>
            <div className="mb-3 text-lg">
              <p>Envelope Options:</p>
            </div>
            <div className="w-full h-[70px] rounded-md border flex px-4 py-2 items-center gap-x-1 border-brandOrange-200 bg-brandOrange-800 mb-4">
              <div className="border h-5 w-5 border-brandOrange-200 rounded-full bg-transparent flex justify-center items-center">
                <span className="h-3 w-3 bg-brandOrange-200 rounded-full"></span>
              </div>
              <div></div>
              <div className="flex flex-col">
                <p>Send directly to receipient</p>
                <p className="text-brandBlack-200 text-sm">
                  We will seal it for you and deliver it to your door
                </p>
              </div>
            </div>
            <div className="w-full h-[70px] rounded-md border flex px-4 py-2 items-center gap-x-1 border-brandBlack-500">
              <div className="border h-5 w-5 border-brandBlack-200 rounded-full bg-transparent flex justify-center items-center"></div>
              <div></div>
              <div className="flex flex-col">
                <p>Send directly to receipient</p>
                <p className="text-brandBlack-200 text-sm">
                  We will seal it for you and deliver it to your door
                </p>
              </div>
            </div>
            <div className="mb-3 mt-6 text-lg">
              <p>Delivery Options:</p>
            </div>
            <div className="w-full h-[70px] rounded-md border flex px-4 py-2 items-center justify-between gap-x-2 border-brandOrange-200 bg-brandOrange-800 mb-4">
              <div className="flex items-center gap-x-2 ">
                <div className="border h-5 w-5 border-brandOrange-200 rounded-full bg-transparent flex justify-center items-center">
                  <span className="h-3 w-3 bg-brandOrange-200 rounded-full"></span>
                </div>
                <div className="flex flex-col">
                  <p>Local Delivery</p>
                  <p className="text-brandBlack-200 text-sm">
                    Allow up to 3 working days for delivery
                  </p>
                </div>
              </div>
              <p>₦2,000</p>
            </div>
            <div className="w-full h-[70px] rounded-md border flex px-4 py-2 items-center border-brandBlack-500 justify-between">
              <div className="flex gap-x-2 items-center">
                <div className="border h-5 w-5 border-brandBlack-200 rounded-full bg-transparent flex justify-center items-center"></div>
                <div className="flex flex-col">
                  <p>Internatinal Delivery</p>
                  <p className="text-brandBlack-200 text-sm">
                    Allow up to 10 working days for delivery
                  </p>
                </div>
              </div>
              <p>₦5,000</p>
            </div>
            <button className="text-white bg-brandOrange-200 rounded h-10 w-full mt-6">
              Proceed
            </button>
          </div>
        </div>
        <div className="col-span-2 bg-brandBlue-100 pt-16 h-full flex justify-center">
          <div className="w-[410px]">
            <h3 className="text-xl font-medium mb-4">Order Summary</h3>
            <p className="pb-6 border-b border-brandBlack-500 mb-6">1 item</p>
            <div className="flex justify-between border-b pb-6 border-brandBlack-500">
              <div className="flex gap-x-5">
                <img src={book1} alt="" className="h-36" />
                <div className=" flex flex-col justify-between">
                  <div>
                    <p className="font-medium mb-1">
                      {summary && summary.orderItemDetails[0].productName}
                    </p>
                    <p className="text-brandBlack-200 mb-1">
                      {" "}
                      {summary && summary.orderItemDetails[0].categoryName}
                    </p>
                    <p className="mb-1">
                      Quantity:{" "}
                      {summary && summary.orderItemDetails[0].quantity}
                    </p>
                    <p className="mb-1">Size: A4</p>
                  </div>
                  <p className="text-brandOrange-200 text-sm">Preview & Edit</p>
                </div>
              </div>
              <p className="font-medium">
                ₦{summary && summary.orderItemDetails[0].amount}
              </p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Subtotal</p>
              <p className="font-medium">₦{summary && summary.totalAmount}</p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>Delivery cost</p>
              <p className=" text-brandBlack-200">
                ₦{summary && summary.deliveryCost}
              </p>
            </div>
            <div className="flex justify-between w-full mt-4">
              <p>VAT (7.5%)</p>
              <p className="font-medium">₦{summary && summary.vat}</p>
            </div>
            <div className="flex justify-between w-full mt-4 border-t border-b py-6 border-brandBlack-500">
              <p className="font-medium text-lg">Total</p>
              <p className="font-medium text-xl">
                ₦{summary && summary.totalAmount}
              </p>
            </div>
            <p className="mt-2 text-lg font-medium">
              {details && details.firstName}'s Delivery address
            </p>
            <p>
              {details &&
                details.address + ", " + details.city + ", " + details.state}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDeliveryDetails;
