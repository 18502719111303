import React from "react";
import { IProfile } from "../interfaces";

const ProfileInfo = ({
  profile,
  country,
  state,
}: {
  profile: IProfile;
  country: string;
  state: string;
}) => {
  return (
    <div className="flex gap-x-20 flex-wrap">
      <div className="flex flex-col gap-y-5 text-sm w-72">
        <div>
          <p className="text-brandBlack-200">Title:</p>
          <p>{profile.profile.title ? profile.profile.title : "Mr"}</p>
        </div>
        <div>
          <p className="text-brandBlack-200">Email Address:</p>
          <p>{profile.profile.emailAddress}</p>
        </div>
        <div>
          <p className="text-brandBlack-200">City:</p>
          <p>{profile.profile.city ? profile.profile.city : "---"}</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">First Name:</p>
          <p>{profile.profile.firstName}</p>
        </div>
        <div>
          <p className="text-brandBlack-200">Phone Number:</p>
          <p>
            {profile.profile.phoneNumber ? profile.profile.phoneNumber : "---"}
          </p>
        </div>
        <div>
          <p className="text-brandBlack-200">State:</p>
          <p>{state ? state : "---"}</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm w-72">
        <div>
          <p className="text-brandBlack-200">Last Name:</p>
          <p>{profile.profile.lastName}</p>
        </div>
        <div>
          <p className="text-brandBlack-200">Address:</p>
          <p>{country && state ? `${country}, ${state}` : "---"}</p>
        </div>
        <div>
          <p className="text-brandBlack-200">Country:</p>
          <p>{country ? country : "---"}</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
