import React, { useState } from "react";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";

interface ISlide {
  name: string;
  rating: number;
  reviewCount: number;
  designerName: string;
  imageUrl: string;
  isFavourite: boolean;
}
const TemplateCard = ({ slide }: { slide: ISlide }) => {
  const [prod, setProd] = useState<any>(slide);

  // const favoriteProduct = async () => {
  //   const bodyInput = {
  //     userId,
  //     storefrontId: storeFrontId,
  //     productId: productdetails.id,
  //   };

  //   console.log(bodyInput);
  //   const url = new URL(API_BASE_URL + `/api/Customer/FavoriteProduct`);
  //   try {
  //     const res = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //       body: JSON.stringify(bodyInput),
  //     });
  //     const products: IResponseObject = await res.json();
  //     //  console.log("prod =>", products);
  //     if (products.statusCode == 200) {
  //       //  setProductsList(products.data);
  //       // localStorage.setItem(
  //       //   "categoriesList",
  //       //   JSON.stringify(products.data)
  //       // );
  //       console.log(products);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="sm:w-[145px] lg:w-[245px] rounded-lg lg:h-[406px] sm:h-[250px] flex flex-col shadow-sm items-center justify-between">
      <div className="sm:h-[165px] lg:h-[336px] rounded-lg w-[245px]">
        <img
          src={slide.imageUrl}
          alt="template"
          className="w-full object-cover h-[336px] rounded-lg border"
        />
      </div>
      <div className="sm:h-9 lg:h-[93px] w-full flex flex-col gap-y-1 justify-end px-1">
        <div className="flex justify-between w-full">
          <p>{slide.name}</p>
          <div
            className="cursor-pointer"
            // onClick={async () => favoriteProduct()}
          >
            {slide.isFavourite ? (
              <RiHeartFill color="#F89521" size={22} />
            ) : (
              <RiHeartLine color="#F89521" size={22} />
            )}
          </div>
        </div>
        <div className="w-full flex gap-x-2 items-center">
          {/* <div className="w-4 h-4 rounded-full bg-slate-400"></div>
          <p className="text-sm">{slide.designerName}</p> */}
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
