"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateForm } from "../components/CreateForm";
import { CreateInformation } from "../components/CreateInformation";
import Template from "../components/Template";
import SignupConfirmation from "../components/SignupConfirmation";
import { Toaster } from "sonner";

const Register = () => {
  const [showForm, setShowForm] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const showSuccessModal = () => {
    setShowConfirmationModal(true);
  };

  return (
    <Template>
      <Toaster richColors position="top-right" />
      <SignupConfirmation isOpen={showConfirmationModal} />
      <div>
        <div className="mt-8">
          <div className="w-full h-[48px] bg-transparent grid grid-cols-2 lg:invisible">
            <Link to={"/login"}>
              <div className="col-span-1 h-full border-b-2 border-brandBlack-500  flex justify-center items-center cursor-pointer">
                <p className="text-black">Existing User</p>
              </div>
            </Link>
            <Link to={"/register"}>
              <div className="col-span-1 h-full border-b-2 border-brandOrange-200 bg-[#FFF6EB] flex justify-center items-center cursor-pointer">
                <p className="text-sm text-brandOrange-200 font-medium">
                  New User
                </p>
              </div>
            </Link>
          </div>
          {showForm ? (
            <CreateForm showSuccessModal={showSuccessModal} />
          ) : (
            <CreateInformation setShowForm={setShowForm} showForm={showForm} />
          )}
        </div>
      </div>
    </Template>
  );
};

export default Register;
