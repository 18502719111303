import React, { Fragment, useState } from "react";
import { API_BASE_URL } from "..";
import { IProfile } from "../interfaces";
import { PrimaryInput } from "./PrimaryInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { apiPost } from "../service/useApi";

const EditCardInfo = ({ profile }: { profile: IProfile }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<any>();
  const [cardType, setCardType] = useState<any>();
  console.log(profile);

  const cards = [
    {
      id: 0,
      name: "Visa",
    },
    {
      id: 1,
      name: "Verve",
    },
    {
      id: 2,
      name: "Master Card",
    },
  ];

  const schema = yup.object().shape({
    cardNumber: yup.string().optional(),
    cardType: yup.string().optional(),
    expiryDate: yup.string().optional(),
    cvv: yup.string().optional(),
    holderName: yup.string().optional(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema), // yup, joi and even your own.
    defaultValues: {
      cardNumber: profile.customerCard ? profile.customerCard.cardNumber : "",
      cardType: profile.customerCard ? profile.customerCard.cardType : "",
      expiryDate: profile.customerCard ? profile.customerCard.expiryDate : "",
      cvv: profile.customerCard ? profile.customerCard.cvv : "",
      holderName: profile.customerCard ? profile.customerCard.holderName : "",
    },
  });
  const submitForm = async (data: any) => {
    setLoading(true);
    console.log(cardType);
    data.customerId = localStorage.getItem("userId") as unknown as string;
    data.cardType = cardType;
    data.expiryDate = date;
    const { isLoaded, data: info } = await apiPost(
      "/api/CustomerCard/UpdateCardDetail",
      "PUT",
      data
    );
    if (isLoaded && info.statusCode == 200) {
      setLoading(false);
    }

    // data.storefrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // console.log(JSON.stringify(data));
    // const res = await fetch(
    //   API_BASE_URL + "/api/CustomerCard/UpdateCardDetail",
    //   {
    //     method: "PUT",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // );

    // if (res.ok) {
    //   setLoading(false);
    //   const data = await res.json();
    //   //  toast.success(`${data.username} is logged in successfully!`);
    //   // navigate("/profile");
    //   console.log(data);
    // } else {
    //   setLoading(false);
    //   const data = await res.json();
    //   console.log(res);
    //   //  toast.error(`Sorry, something went wrong, please try again!`);
    // }
  };

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="flex gap-x-20 flex-wrap"
      autoComplete="off"
    >
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">Card Number:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.cardNumber}
            name="cardNumber"
            register={register}
            disabled={false}
            placeholder="Card number"
            defaultValue={
              profile.customerCard ? profile.customerCard.cardNumber : ""
            }
            // value={profile.customerCard.cardNumber}
          />
        </div>
        <div>
          <p className="text-brandBlack-200">CVV:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.cvv}
            name="cvv"
            register={register}
            disabled={false}
            placeholder="CVV"
            defaultValue={profile.customerCard ? profile.customerCard.cvv : ""}
            // value={profile.customerCard.cardNumber}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <Listbox value={cardType} onChange={setCardType}>
            <div className="relative mt-1">
              <Listbox.Label className={""}>Card Type:</Listbox.Label>
              <Listbox.Button className="relative w-64 cursor-default rounded border h-10 mt-1 border-brandBlack-200 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 ">
                <span className="block truncate">
                  {cardType ? cardType : "Select Card Type"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-brandOrange-200"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {cards.map((type: any) => (
                    <Listbox.Option
                      key={type.name}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-brandOrange-800 cursor-pointer text-brandOrange-200"
                            : "text-gray-900"
                        }`
                      }
                      value={type.name}
                      onClick={() => setCardType(type.name)}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {type.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div>
          <p className="text-brandBlack-200">Holder's Name:</p>
          <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.holderName}
            name="holderName"
            register={register}
            disabled={false}
            placeholder="Holder's Name"
            defaultValue={
              profile.customerCard ? profile.customerCard.holderName : ""
            }
            // value={profile.customerCard.holderName}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-5 text-sm  w-72">
        <div>
          <p className="text-brandBlack-200">Expiry Date:</p>
          <input
            type="date"
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          {/* <PrimaryInput
            className="w-64 h-10 mt-1 rounded border outline-none px-2 focus:border-brandOrange-200"
            error={errors.expiryDate}
            name="expiryDate"
            register={register}

            placeholder="Expiry Date"
            disabled={false}
            defaultValue={
              profile.customerCard ? profile.customerCard.expiryDate : ""
            }
            // value={profile.customerCard.expiryDate}
          /> */}
        </div>
      </div>
      <div className="w-full h-20 flex justify-end items-end">
        <button
          type="submit"
          className="w-36 h-10 rounded bg-brandOrange-200 text-white"
        >
          {loading ? "Updating..." : "Update details"}
        </button>
      </div>
    </form>
  );
};

export default EditCardInfo;
