import { createContext, useContext, useState } from "react";

interface IContext {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export const MyContext = createContext<IContext>({} as IContext);

export const SearchProvider = ({ children }: { children: any }) => {
  const [query, setQuery] = useState("");
  return (
    <MyContext.Provider value={{ query, setQuery }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;

