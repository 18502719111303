import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";

export const convertDate = (date: Date) => {
  const converted = date
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
    .replace("at", ",");

  return converted;
};

export const favoriteProduct = async (productId: string) => {
  const storefrontId = localStorage.getItem(
    "storeFrontId"
  ) as unknown as string;
  const userId = localStorage.getItem("userId") as unknown as string;

  const bodyInput = {
    userId,
    storefrontId,
    productId,
  };

  console.log(bodyInput)
  // const url = new URL(API_BASE_URL + `/api/Customer/FavoriteProduct`);
  // try {
  //   const res = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //     },
  //     body: JSON.stringify(bodyInput),
  //   });
  //   const products: IResponseObject = await res.json();
  //   //  console.log("prod =>", products);
  //   if (products.statusCode == 200) {
  //     //  setProductsList(products.data);
  //     // localStorage.setItem(
  //     //   "categoriesList",
  //     //   JSON.stringify(products.data)
  //     // );
  //     console.log(products);
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
};

export function numberWithCommas(x: number | string | any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
