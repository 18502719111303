import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "..";
import { toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { apiFetch } from "../service/useApi";

export interface IReminder {
  createdOn: string;
  date: string;
  description: string;
  intervalType: number;
  name: string;
  reminderId: string;
  status: number;
  type: number;
}

function DeleteModal({
  isOpen,
  setIsOpen,
  reminder,
}: {
  isOpen: boolean;
  setIsOpen: any;
  reminder: IReminder;
}) {
  let [isDeleting, setIsDeleting] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();

  const deleteReminder = async () => {
    const { data, isLoaded } = await apiFetch(
      `/api/Reminder/Delete?ReminderId=${reminder.reminderId}`,
      "DELETE"
    );

    if (isLoaded && data.statusCode == 200) {
      toast.success(data.message);
      setIsDeleting(false);
      closeModal();
    } else {
      toast.error(data.message);
      setIsDeleting(false);
      closeModal();
    }

    // setIsDeleting(true);
    // const userId = localStorage.getItem("userId") as unknown as string;
    // const storeFrontId = localStorage.getItem(
    //   "storeFrontId"
    // ) as unknown as string;
    // const tenantId = localStorage.getItem("tenantId") as unknown as string;
    // const accessToken = localStorage.getItem(
    //   "accessToken"
    // ) as unknown as string;
    // if (!userId || !accessToken) {
    //   navigate("/login");
    // }
    // console.log(userId);
    // const url = new URL(
    //   API_BASE_URL +
    //     `/api/Reminder/Delete?ReminderId=${reminder.reminderId}&TenantId=${tenantId}&StoreFrontId=${storeFrontId}&UserId=${userId}`
    // );
    // try {
    //   const res = await fetch(url, {
    //     method: "DELETE",
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const reminders = await res.json();
    //   if (reminders.statusCode == 200) {
    //     toast.success(reminders.message);
    //     setIsDeleting(false);
    //     closeModal();
    //   } else {
    //     toast.error(reminders.message);
    //     setIsDeleting(false);
    //     closeModal();
    //   }
    // } catch (error) {
    //   setIsDeleting(false);
    //   console.log(error);
    // }

    // /api/Reminder/Delete?ReminderId=f33bd57d-eabc-4ae9-1750-08dc481482f4&TenantId=d75b7ef3-f537-4f0f-a63a-bbee64db5cd2&StoreFrontId=9705cd70-309c-4a88-8aab-6c6144a4b4e2&UserId=8f4fe087-2e73-42b4-cba0-08dc4356c08e
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[407px] max-w-md transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all pt-10">
                  <span
                    className="absolute top-4 right-4 text-lg text-gray-400 cursor-pointer"
                    onClick={closeModal}
                  >
                    x
                  </span>
                  <div className="flex justify-center mb-2">
                    <HiOutlineExclamationTriangle size={30} color="#F89521" />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Delete reminder
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 text-center px-5">
                      Are you sure you want to delete {reminder.name}?
                    </p>
                  </div>

                  <div className="mt-4 flex w-full gap-x-4 justify-between">
                    <button
                      type="button"
                      className="justify-center w-40 rounded border border-brandOrange-200 px-4 py-2 text-sm font-medium text-brandOrange-200  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="justify-center w-40 rounded border border-transparent bg-brandOrange-200 px-4 py-2 text-sm font-medium text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={deleteReminder}
                    >
                      {isDeleting ? (
                        <ClipLoader
                          color={"white"}
                          loading={isDeleting}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : (
                        "Yes, I'm sure"
                      )}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default DeleteModal;
