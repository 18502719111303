import React, { useContext } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { LuUndo2, LuRedo2 } from "react-icons/lu";

import { FiSave, FiShare2 } from "react-icons/fi";
import CustomizationContext from "../../CustomizationContext";
import { numberWithCommas } from "../../utils";

const CustomizationTopBar = ({ amount }: { amount: string }) => {

  console.log(typeof amount)
  const { handleUndo, handleRedo, historyIndex, history } =
    useContext(CustomizationContext);
  return (
    <div className="fixed top-0 right-0 h-20 border-b w-screen bg-white z-20 px-5 flex items-center py-4">
      <div className="flex gap-x-6 h-full">
        <span className="flex gap-x-2 items-center">
          <FaArrowLeft size={16} className="text-brandOrange-200" />
          <p className="underline text-brandOrange-200 underline-offset-4 font-medium">
            Exit design tool
          </p>
        </span>
        <span className="border-l border-r h-full flex items-center gap-x-6 px-4 text-sm  border-brandBlack-200">
          <span
            className={`flex flex-col items-center cursor-pointer ${
              historyIndex > 0 ? "text-black" : "text-[#9D9D9D]"
            }`}
            onClick={handleUndo}
          >
            <LuUndo2 size={22} />
            <p>Undo</p>
          </span>
          <span
            className={`flex flex-col items-center cursor-pointer ${
              historyIndex < history.length - 1
                ? "text-black"
                : "text-[#9D9D9D]"
            }`}
            onClick={handleRedo}
          >
            <LuRedo2 size={22} />
            <p>Redo</p>
          </span>
        </span>
        <span className=" h-full flex items-center gap-x-6 px-4 text-sm">
          <span className="flex flex-col items-center cursor-pointer">
            <FiSave size={22} />
            <p>Save</p>
          </span>
          <span className="flex flex-col items-center cursor-pointer">
            <FiShare2 size={22} />
            <p>Share</p>
          </span>
        </span>
      </div>
      <div className="flex-1 flex gap-x-4 justify-end">
        <div>
          <p className="text-sm text-brandBlack-200">Total</p>
          <p>₦{amount && numberWithCommas(amount as string)}</p>
        </div>
        <button className="px-3 text-brandOrange-200 font-medium border border-brandOrange-200 rounded-md">
          Preview
        </button>
        <button className="px-3 text-white font-medium bg-brandOrange-200 rounded-md">
          Proceed to finishing
        </button>
      </div>
    </div>
  );
};

export default CustomizationTopBar;
