import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { fabric } from "fabric";
import CustomizationContext from "../CustomizationContext";

interface FabricCanvasProps {
  imageUrl?: string;
}

const FabricCanvas: React.FC<FabricCanvasProps> = ({ imageUrl }) => {
  // const canvasRef = useRef(null);
  const {
    canvasRef,
    fileInputRef,
    canvas,
    setCanvas,
    backgroundImage,
    setBackgroundImage,
    isAddingText,
    setIsAddingText,
    previewText,
    setPreviewText,
    text,
    setText,
    fontSize,
    setFontSize,
    fontFamily,
    setFontFamily,
    fill,
    setFill,
    images,
    setImages,
    fileUploadControl,
    setFileUploadControl,
    selectedObject,
    setSelectedObject,
    history,
    setHistory,
    historyIndex,
    setHistoryIndex,
    handleDelete,
    saveDesignToLocalStorage,
    handleRotateImage,
    handleResizeImage,
    handleFillChange,
    handleFontFamilyChange,
    handleFontSizeChange,
    handleTextChange,
    handleBringToFront,
    addTextAndUploadTemplate,
    applyTemplate,
    handleAddFileUploadControl,
    handleAddTextClick,
    handleUploadImage,
    handleObjectCleared,
    handleObjectSelected,
    handleMouseDown,
    handleMouseMove,
    handleObjectModified,
    saveToHistory,
  } = useContext(CustomizationContext);

  useEffect(() => {
    if (!canvasRef.current) return;

    const newCanvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: "white",
    });
    setCanvas(newCanvas);

    const savedDesign = localStorage.getItem("canvasDesign");
    if (savedDesign) {
      newCanvas.loadFromJSON(
        savedDesign,
        newCanvas.renderAll.bind(newCanvas),
        (object: fabric.Object) => {
          if (object.type === "rect") {
            //   object.set({
            //     selectable: true,
            //     hasControls: false,
            //     lockMovementX: true,
            //     lockMovementY: true,
            //     evented: true,
            //     stroke: "black",
            //     strokeWidth: 1,
            //   });
            // } else if (object.type === "textbox") {
            //   object.set({
            //     selectable: false,
            //     hasControls: false,
            //     evented: false,
            //   });
          }
        }
      );
    }

    return () => {
      newCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas) {
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:down", handleMouseDown);
      canvas.on("selection:created", handleObjectSelected);
      canvas.on("selection:updated", handleObjectSelected);
      canvas.on("selection:cleared", handleObjectCleared);
      canvas.on("object:modified", handleObjectModified);

      return () => {
        canvas.off("mouse:move", handleMouseMove);
        canvas.off("mouse:down", handleMouseDown);
        canvas.off("selection:created", handleObjectSelected);
        canvas.off("selection:updated", handleObjectSelected);
        canvas.off("selection:cleared", handleObjectCleared);
        canvas.off("object:modified", handleObjectModified);
      };
    }
  }, [
    canvas,
    handleMouseMove,
    handleMouseDown,
    handleObjectSelected,
    handleObjectCleared,
    handleObjectModified,
  ]);

  useEffect(() => {
    if (canvas && imageUrl) {
      fabric.Image.fromURL(imageUrl, (img: any) => {
        const canvasAspect = canvas.width! / canvas.height!;
        const imgAspect = img.width! / img.height!;

        let scaleX = canvas.width! / img.width!;
        let scaleY = canvas.height! / img.height!;

        if (canvasAspect >= imgAspect) {
          scaleX = scaleY;
        } else {
          scaleY = scaleX;
        }

        img.set({
          scaleX: scaleX,
          scaleY: scaleY,
          originX: "center",
          originY: "center",
          left: canvas.width! / 2,
          top: canvas.height! / 2,
        });
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
      });
    }
  }, [canvas, imageUrl]);

  //   const handleUndo = () => {
  //   if (historyIndex > 0 && canvas) {
  //     const prevState = history[historyIndex - 1];
  //     canvas.loadFromJSON(prevState, () => {
  //       canvas.renderAll();
  //       setHistoryIndex(historyIndex - 1);
  //     });
  //   }
  // };

  // const handleRedo = () => {
  //   if (historyIndex < history.length - 1 && canvas) {
  //     const nextState = history[historyIndex + 1];
  //     canvas.loadFromJSON(nextState, () => {
  //       canvas.renderAll();
  //       setHistoryIndex(historyIndex + 1);
  //     });
  //   }
  // };

  return (
    <div>
      {/* <input type="file" onChange={handleUploadImage} />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadImage}
        style={{ display: "none" }}
      />
      <button onClick={handleAddTextClick}>Add Text</button>
      <div className="flex gap-x-5">
        <button onClick={handleDelete}>Delete</button>
        <button onClick={handleBringToFront}>Bring to Front</button>
      </div>

      <div>
        <button onClick={() => applyTemplate("textTopImageBottom")}>
          Template 1
        </button>

        <button onClick={addTextAndUploadTemplate}>Template 2</button>

        <button onClick={saveDesignToLocalStorage}>Save Design</button>
      </div> */}

      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        style={{ border: "1px solid black" }}
      />
    </div>
  );
};

export default FabricCanvas;
