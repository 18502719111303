import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import EmptyReminder from "../assets/reminder-img.png";
import ReminderrModal from "../components/ReminderModal";
import { IResponseObject } from "../interfaces";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../utils";
import { API_BASE_URL } from "..";
import Loading from "../components/Loading";
import ReminderDeleteModal from "../components/ReminderDeleteModal";
import EditReminderModal from "../components/EditReminderModal";
import DeleteModal, { IReminder } from "../components/DeleteReminderModal";
import { apiFetch } from "../service/useApi";

const Reminders = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState<IReminder>();
  const [toBeEdited, setToBeEdited] = useState<IReminder>();

  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const getReminders = async () => {
      const { isLoaded, data } = await apiFetch(
        `/api/Reminder/GetCustomerReminders`,
        "GET"
      );

      if (isLoaded && data.statusCode == 200) {
        setReminders(data.data);
        setLoading(false);
      }
      // const userId = localStorage.getItem("userId") as unknown as string;
      // const storeFrontId = localStorage.getItem(
      //   "storeFrontId"
      // ) as unknown as string;
      // const tenantId = localStorage.getItem("tenantId") as unknown as string;
      // const accessToken = localStorage.getItem(
      //   "accessToken"
      // ) as unknown as string;
      // if (!userId || !accessToken) {
      //   navigate("/login");
      // }
      // console.log(userId);
      // const url = new URL(
      //   API_BASE_URL +
      //     `/api/Reminder/GetCustomerReminders?TenantId=${tenantId}&StoreFrontId=${storeFrontId}&UserId=${userId}`
      // );
      // try {
      //   const res = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   });
      //   const reminders: IResponseObject = await res.json();
      //   if (reminders.statusCode == 200) {
      //     console.log(reminders);
      //     setReminders(reminders.data);
      //     setLoading(false);
      //   } else {
      //     toast.error(reminders.message);
      //   }
      // } catch (error) {
      //   setLoading(false);
      //   console.log(error);
      // }
    };

    getReminders();
  }, []);

  console.log(reminders);

  if (loading) {
    return <Loading />;
  }
  return (
    <Template>
      <Toaster richColors position="top-right" />
      {openEditModal ? (
        <EditReminderModal
          reminders={reminders}
          isOpen={openEditModal}
          reminder={toBeEdited as IReminder}
          setIsOpen={setOpenEditModal}
        />
      ) : (
        ""
      )}
      {openModal ? (
        <ReminderrModal isOpen={openModal} setIsOpen={setOpenModal} />
      ) : (
        ""
      )}
      {openDelModal ? (
        <DeleteModal
          isOpen={openDelModal}
          setIsOpen={setOpenDelModal}
          reminder={toBeDeleted as IReminder}
        />
      ) : (
        ""
      )}
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"reminders"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">Reminders</p>
          </div>
          <div className="px-6">
            <div className="flex flex-wrap gap-10 p-4 items-center">
              {reminders.length < 1 ? (
                <div className="w-full flex flex-col items-center justify-center ">
                  <img src={EmptyReminder} alt="no-reminder" width={200} />
                  <p className="my-4 font-medium text-2xl">
                    Your reminders list is empty
                  </p>
                  <p>
                    Set a reminder to receive an email ahead of any occasion
                  </p>
                </div>
              ) : (
                <div className="border border-b-0 rounded w-full">
                  {reminders.map((reminder: any) => (
                    <div className="border-b h-14 w-full flex py-4 rounded px-5">
                      <div className=" border-r w-44 flex items-center">
                        {convertDate(new Date(reminder.date))}
                      </div>
                      <div className="flex-1 flex items-center px-4">
                        {reminder.name}
                      </div>
                      <div className="flex text-sm">
                        <p
                          className="mr-3 text-brandOrange-200 cursor-pointer"
                          onClick={() => {
                            setOpenEditModal(true);
                            setToBeEdited(reminder);
                          }}
                        >
                          Edit
                        </p>
                        <p
                          className="ml-3 text-brandRed-100 cursor-pointer"
                          onClick={() => {
                            setOpenDelModal(true);
                            setToBeDeleted(reminder);
                          }}
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="w-full flex justify-center">
                <button
                  className="h-12 w-[325px] rounded bg-brandOrange-200 text-white"
                  onClick={() => setOpenModal(true)}
                >
                  Add Reminder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Reminders;
