import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface ISlide {
  description: string;
  imageUrl: string;
  name: string;
  parentCategoryId?: string;
  slug: string;
  status: number;
}
const CategoryCard = ({ slide }: { slide: ISlide }) => {
  const [prod, setProd] = useState<any>(slide);

  return (
    <div className="sm:w-[145px] lg:w-[288px] rounded-lg lg:h-[360px] sm:h-[250px] flex flex-col shadow-sm items-center justify-between">
      <div className="sm:h-[165px] lg:h-[250px] rounded-lg w-[285px]">
        <img
          src={slide.imageUrl}
          alt="template"
          className="w-full object-cover h-[250px] rounded-lg"
        />
      </div>
      <div className="sm:h-9 lg:h-[93px] w-full flex flex-col gap-y-1 justify-end px-1">
        <h3 className="text-[20px] font-medium">{slide.name}</h3>
        <p className="text-brandBlack-200 text-sm">{slide.description}</p>
        <Link to={`/category/${slide.slug}`}>
          <p className="flex items-center gap-x-2 text-brandOrange-200">
            View all
            <FaArrowRight color="#F89521" />
          </p>
        </Link>
      </div>
    </div>
  );
};

export default CategoryCard;
